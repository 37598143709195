import React, { Component } from "react";

export class MapSizeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderValue: "3"
        }
    }

    componentDidMount() {
        if (this.props.sliderValue) {
            this.setState({ sliderValue: this.props.sliderValue });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.sliderValue !== prevProps.sliderValue) {
            this.setState({ sliderValue: this.props.sliderValue });
        }
    }
    
    render() {
        const {
            sliderValue
        } = this.state;

        return (
            <div className="map-slide-container flex">
                <span id="map-slide-value">Maps per row: </span>
                {/* <input type="range" min="10" max="100" className="slider" id="map-slide" defaultValue={sliderValue} onChange={this.handleMapSizeSlider} step="1" min="1" max="5" /> */}
                <select id="map-slide-select" defaultValue={sliderValue} onChange={this.props.handleMapSizeSlider}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </select>
            </div>
        );
    }
}