import React, { Component } from "react";
import Modal from 'react-modal';
import { RoundSlider } from "./RoundSlider";

export class PlayerPositionOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            circlesArray: [],
            positions: {},
            matchesInfo: [],
            mapNames: [],
            singleMapName: '',
            singleMapPlayer: '',
            showModal: false,
            wholeTeamMaps: false,
            playerOverviewSliderTime: 15,
            circlesObject: {},
            allCirclesObject: {},
            playerNames: [],
            oldPlayerNames: [],
            useOldPlayerNames: false,
            awpCount: {},
            showAwperOnly: false
        }
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChangePlayerOverviewSlider = this.handleChangePlayerOverviewSlider.bind(this);
        this.handleAwperOverviewChange = this.handleAwperOverviewChange.bind(this);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    componentDidMount() {
        this.createCirclesArray();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.allCirclesObject !== this.props.allCirclesObject || this.props.sliderTime != this.state.playerOverviewSliderTime) {
            if (this.props.sliderTime) {
                this.setState({
                    playerOverviewSliderTime: this.props.sliderTime
                }, () => {
                    this.createCirclesArray();
                });
            } else if (this.props.allCirclesObject !== this.state.allCirclesObject) {
                this.createCirclesArray();
            }
        }
    }

    createCirclesArray(sliderTime) {
        var circlesArray = [];
        sliderTime = sliderTime || this.state.playerOverviewSliderTime;
        circlesArray = this.props.allCirclesObject[sliderTime]
        this.setState({
            circlesArray: circlesArray,
            mapNames: this.props.mapNames,
            wholeTeamMaps: this.props.wholeTeamMaps,
            allCirclesObject: this.props.allCirclesObject,
            playerNames: this.props.playerNames,
            oldPlayerNames: this.props.oldPlayerNames,
            useOldPlayerNames: false,
            playerOverviewSliderTime: sliderTime,
            awpCount: this.props.awpCount
        })
    }

    getSingleMapClick = (mapName, playerName) => {
        this.setState({ singleMapName: mapName, singleMapPlayer: playerName, showModal: true }, () => {
            return
        })
    }

    handleCloseModal() {
        this.setState({ showModal: false, singleMapName: '', singleMapPlayer: '', modalType: "" });
    }

    handleUseOldPlayerNames() {
        this.setState({
            useOldPlayerNames: !this.state.useOldPlayerNames
        })
    }

    handleChangePlayerOverviewSlider(event) {
        // this.setState({
        //     playerOverviewSliderTime: event.target.value
        // }, () => {
        this.createCirclesArray(event.target.value);
        // });
    }

    handleAwperOverviewChange(event) {
        this.setState({ showAwperOnly: event.target.checked }, () => {
        });
    }

    render() {
        const {
            circlesArray,
            mapNames,
            singleMapName,
            singleMapPlayer,
            showModal,
            selectedSvg,
            wholeTeamMaps,
            playerOverviewSliderTime,
            playerNames,
            oldPlayerNames,
            useOldPlayerNames,
            awpCount,
            showAwperOnly

        } = this.state;
        const mapInfo = {
            "de_ancient": {
                "xPos": -2953,
                "yPos": 2164,
                "scale": 5,
                "name": "de_ancient"
            },
            "de_anubis": {
                "xPos": -2796,
                "yPos": 3328,
                "scale": 5.22,
                "name": "de_anubis"
            },
            "de_cache": {
                "xPos": -2000,
                "yPos": 3250,
                "scale": 5.5,
                "name": "de_cache"
            },
            "de_cbble": {
                "xPos": -3840,
                "yPos": 3072,
                "scale": 6,
                "name": "de_cbble"
            },
            "de_dust2": {
                "xPos": -2476,
                "yPos": 3239,
                "scale": 4.4,
                "name": "de_dust2"
            },
            "de_inferno": {
                "xPos": -2087,
                "yPos": 3870,
                "scale": 4.9,
                "name": "de_inferno"
            },
            "de_mirage": {
                "xPos": -3230,
                "yPos": 1713,
                "scale": 5,
                "name": "de_mirage"
            },
            "de_nuke": {
                "xPos": -3453,
                "yPos": 2887,
                "scale": 7,
                "name": "de_nuke",
                "lowerBeakpoint": -495,
                "upperXAdjust": 0,
                "upperYAdjust": 100,
                "upperScaleAdjust": 1,
                "lowerXAdjust": -350,
                "lowerYAdjust": -200,
                "lowerScaleAdjust": 1,
            },
            "de_dust2": {
                "xPos": -4831,
                "yPos": 1781,
                "scale": 5.2,
                "name": "de_dust2"
            },
            "de_vertigo": {
                "xPos": -3168,
                "yPos": 1762,
                "scale": 4.0,
                "lowerBeakpoint": 11700,
                "name": "de_vertigo",
                "upperXAdjust": 0,
                "upperYAdjust": 0,
                "upperScaleAdjust": .75,
                "lowerXAdjust": 420,
                "lowerYAdjust": 300,
                "lowerScaleAdjust": 0.85
            }
        }

        var mapImages = {}
        var mapImagesSmall = {}
        for (var i = 0; i < Object.keys(mapInfo).length; i++) {
            mapImages[Object.values(mapInfo)[i].name] = require('./radar_maps/' + Object.values(mapInfo)[i].name + '_radar.png');
            mapImagesSmall[Object.values(mapInfo)[i].name] = require('./radar_maps/' + Object.values(mapInfo)[i].name + '_radar_small.jpg');
        }

        var recentPlayerChange = false;

        if (oldPlayerNames.length > 0 && JSON.stringify(oldPlayerNames) !== JSON.stringify(playerNames)) {
            recentPlayerChange = true;
        }

        var playerNamesToUse = useOldPlayerNames ? oldPlayerNames : playerNames;

        let maxAwps = 0
        let awper = ""
        if (playerNames.length === 5) {
            maxAwps = Math.max(...Object.values(awpCount));
            if (maxAwps > 0) {
                awper = Object.keys(awpCount).filter(playerName => awpCount[playerName] === maxAwps);
            }
        }

        return (
            <React.Fragment>
                <div className="map-container flex-vertical">
                    {(!this.props.sliderTime) ? <RoundSlider sliderValue={playerOverviewSliderTime} sliderName="playerOverviewSlider" onChangeSliderValue={this.handleChangePlayerOverviewSlider} finalSliderValue={this.handleChangePlayerOverviewSlider} /> : null}
                    {(recentPlayerChange) ? <div className="old-roster-button"><button onClick={() => this.handleUseOldPlayerNames()}>{(useOldPlayerNames) ? "(See Newer Roster)" : "(See Older Roster)"}</button></div> : null}

                    <div className="map-container-maps flex">

                        <div className="map-container-map-names flex-vertical">
                            <h1></h1>
                            <div key="Ancient" className="map-names-vertical flex-one">Ancient</div>
                            <div key="Anubis" className="map-names-vertical flex-one">Anubis</div>
                            <div key="Inferno" className="map-names-vertical flex-one">Inferno</div>
                            <div key="Mirage" className="map-names-vertical flex-one">Mirage</div>
                            <div key="Nuke" className="map-names-vertical flex-one">Nuke</div>
                            <div key="Dust2" className="map-names-vertical flex-one">Dust2</div>
                            <div key="Vertigo" className="map-names-vertical flex-one">Vertigo</div>
                        </div>

                        {playerNamesToUse.map((nameObject, nameIndex) => {

                            var name = nameObject.name;
                            var title = (wholeTeamMaps ? <h1 className="overview-name">{name}</h1> : null)
                            var isAwper = awper.includes(name);
                            let showAwperInput = <input type="checkbox" id="awper" class="awp-input-pointer" name="awper" value="awper" onChange={this.handleAwperOverviewChange} checked={(this.state.showAwperOnly) ? "checked" : ""} />
                            let awperCheckHTML = <div className="awp-mark awp-mark-open flex">
                                {showAwperInput}
                                <label htmlFor="awper" class="awp-input-pointer">(Show Only AWP)</label>
                            </div>
                            var awperCheck = (isAwper ? awperCheckHTML : <div className="awp-mark"></div>)
                            var mapHtmlfull = <div key={"map-player-names-" + nameIndex} className="player-overview-container">{awperCheck}{title}
                                {mapNames.map((mapName, index) => {
                                    var mapHtml = <div key={"map-svg-index-" + index} className="flex-one flex-vertical map-svg-overview-container"><svg viewBox="0 0 1024 1024"
                                        onClick={() => this.getSingleMapClick(mapName, name)}
                                        className={`map-svg flex-one ${selectedSvg === index ? 'map-modal' : ''}`} data-playername={name} data-mapname={mapName}
                                    >
                                        <image href={mapImagesSmall[mapName]} alt="map" height="1024" width="1024" />
                                        <g key={`circles-${mapName}-${name}`}>

                                            {(circlesArray && circlesArray[mapName][name]) ? circlesArray[mapName][name].map((circle) => {
                                                {
                                                    var drawOnMap;

                                                    if (circle.isAlive === "true") {
                                                        // Determine if the current condition should proceed based on the AWPer status and weapon
                                                        if (!isAwper || (isAwper && (!showAwperOnly || (showAwperOnly && circle.weapon === "AWP")))) {
                                                            var color = (circle.side === "CT" ? "#12B3DD" : "#FFED00")
                                                            var yaw = Number(circle.eyeYaw);
                                                            if (yaw < 180) {
                                                                yaw = -yaw
                                                            } else {
                                                                yaw = 360 - yaw
                                                            }

                                                            var arrowHeight = 15;
                                                            var arrowWidth = 7.5;
                                                            var playerLookDirection = (
                                                                <polygon
                                                                    points={`0,0 ${arrowWidth},${arrowWidth} 0,${arrowHeight}`}
                                                                    fill="white"
                                                                    transform={`translate(${circle.xPos + 10}, ${circle.yPos - arrowHeight / 2}) rotate(${yaw})`}
                                                                    style={{ transformOrigin: `${-10}px ${arrowHeight / 2}px` }}
                                                                />
                                                            );

                                                            drawOnMap = [<circle key={mapName + "-" + name + "-" + String(circle.xPos) + "-" + String(circle.yPos) + "-" + String(circle.roundNumber)} cx={circle.xPos} cy={circle.yPos} r="10" stroke="white" strokeWidth="0" fill={color} fillOpacity="1" />, playerLookDirection]
                                                        }
                                                    }

                                                    return drawOnMap
                                                }

                                            }) : null}
                                        </g>
                                    </svg>
                                    </div>
                                    return mapHtml
                                })}
                            </div>
                            return mapHtmlfull
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}