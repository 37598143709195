import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { callApi } from './CallApi';


export class TeamGeneralInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamName: '',
            vetos: [],
            picks: [],
            bans: [],
            roster: [],
            // hltvRank: '',
            lastMatches: '',
            numOfMatches: 0,
            typicalBan: '',
            typicalBanPercent: '',
            typicalPick: '',
            typicalPickPercent: '',
            typical2ndBan: '',
            typical2ndBanPercent: '',
            allMatches: {},
            hltvData: {},
        }
    }

    componentDidMount() {
        if (this.props.teamName !== this.state.teamName) {
            if ((this.props.teamName !== undefined) && (this.props.teamName !== '')) {
                this.setState({
                    teamName: this.props.teamName
                }, () => {
                    this.getTeamGenInfo(this.props.teamName)
                })
            }
        }
    }

    componentDidUpdate() {
        if (this.props.teamName !== this.state.teamName) {
            if ((this.props.teamName !== undefined) && (this.props.teamName !== '')) {
                this.getTeamGenInfo(this.props.teamName)
            }
        }
    }

    getTeamGenInfo(teamName) {
        var results = this.props.matchesInfo;
        var allVetos = [];
        var allPicks = [];
        var allBans = [];
        var firstPicks = {};
        var firstBans = {};
        var secondBans = {};
        var allMatches = {};
        var roster = (results[0].team1Name === teamName ? JSON.parse(results[0].playersTeam1) : JSON.parse(results[0].playersTeam2));
        var rosterArray = [];
        var wins = 0;
        // this.props.matchesInfo(results); //Send the results back to the parent component

        for (var i = 0; i < results.length; i++) {
            var teamOne = (results[i].team1Name === teamName) ? true : false;
            // var teamId = (teamOne ? results[i].team1Id : results[i].team2Id);
            var vetos = JSON.parse(results[i].vetos);
            var maps = JSON.parse(results[i].maps);
            var firstBanned = false;
            var secondBanned = false;
            var firstPicked = false;
            wins += (results[i].winnerName.toLowerCase() === teamName.toLowerCase()) ? 1 : 0;
            allVetos.push(vetos)

            //Get veto info
            for (var j = 0; j < vetos.length; j++) {
                if (vetos[j].type !== 'leftover') {
                    if (vetos[j].team?.name.toLowerCase() === teamName.toLowerCase()) {
                        // allVetos.push(vetos[j]);
                        if (vetos[j].type === 'removed') {
                            allBans.push(vetos[j].map);
                            if (firstBanned === false) {
                                (firstBans[vetos[j].map] === undefined) ? firstBans[vetos[j].map] = 1 : firstBans[vetos[j].map] = firstBans[vetos[j].map] + 1;
                                firstBanned = true;
                            }
                            else if (secondBanned === false) {
                                (secondBans[vetos[j].map] === undefined) ? secondBans[vetos[j].map] = 1 : secondBans[vetos[j].map] = secondBans[vetos[j].map] + 1;
                                secondBanned = true;
                            }
                            // (firstBan === '' ? firstBan = vetos[j].map : (secondBan === '' ? secondBan = vetos[j].map : ''));
                        }
                        if (vetos[j].type === 'picked') {
                            allPicks.push(vetos[j].map);
                            if (firstPicked === false) {
                                (firstPicks[vetos[j].map] === undefined) ? firstPicks[vetos[j].map] = 1 : firstPicks[vetos[j].map] = firstPicks[vetos[j].map] + 1;
                                firstPicked = true;
                            }
                            // (firstPick === '' ? firstPick = vetos[j].map : (secondPick === '' ? secondPick = vetos[j].map : ''));
                        }
                    }
                }
            }

            //Get matches played info
            for (var j = 0; j < maps.length; j++) {
                if (!maps[j].result) {
                    //If the map has no result skip it
                    continue;
                }
                //put all the maps played into the allMatches object
                if (allMatches[maps[j].name] === undefined) {
                    allMatches[maps[j].name] = [];
                }

                allMatches[maps[j].name].push({
                    team1: results[i].team1Name,
                    team2: results[i].team2Name,
                    event: results[i].event,
                    date: results[i].date,
                    matchId: results[i].matchId,
                    team1Score: maps[j].result.team1TotalRounds,
                    team2Score: maps[j].result.team2TotalRounds,
                    mapName: maps[j].name,
                })
            }
        }

        //Think these sorts are wrong
        var firstBansArray = Object.entries(firstBans).sort((a, b) => {
            return b[1] - a[1];
        })
        var secondBansArray = Object.entries(secondBans).sort((a, b) => {
            return b[1] - a[1];
        })
        var firstPicksArray = Object.entries(firstPicks).sort((a, b) => {
            return b[1] - a[1];
        })

        if (firstPicksArray.length === 0) {
            firstPicksArray.push(['No Majority', 0])
        }
        //Flush this out to if 3 or more are the same? idk seems insane at that point
        var firstBan = '';
        var secondBan = '';
        var firstPick = '';
        if (firstBansArray.length === 1 || (firstBansArray[0][1] !== firstBansArray[1][1])) {
            firstBan = this.removeDe(firstBansArray[0][0]);
        } else if (firstBansArray.length > 2 && firstBansArray[0][1] === firstBansArray[1][1] && firstBansArray[0][1] === firstBansArray[2][1]) {
            firstBan = "No Majority";
        } else if (firstBansArray[0][1] === firstBansArray[1][1]) {
            firstBan = this.removeDe(firstBansArray[0][0]) + " and " + this.removeDe(firstBansArray[1][0]);
        }

        if (secondBansArray.length === 1 || (secondBansArray[0][1] !== secondBansArray[1][1])) {
            secondBan = this.removeDe(secondBansArray[0][0]);
        } else if (secondBansArray.length > 2 && secondBansArray[0][1] === secondBansArray[1][1] && secondBansArray[0][1] === secondBansArray[2][1]) {
            secondBan = "No Majority";
        } else if (secondBansArray[0][1] === secondBansArray[1][1]) {
            secondBan = this.removeDe(secondBansArray[0][0]) + " and " + this.removeDe(secondBansArray[1][0]);
        }

        if (firstPicksArray.length === 1 || (firstPicksArray[0][1] !== firstPicksArray[1][1])) {
            firstPick = this.removeDe(firstPicksArray[0][0]);
        } else if (firstPicksArray.length > 2 && firstPicksArray[0][1] === firstPicksArray[1][1] && firstPicksArray[0][1] === firstPicksArray[2][1]) {
            firstPick = "No Majority";
        } else if (firstPicksArray[0][1] === firstPicksArray[1][1]) {
            firstPick = this.removeDe(firstPicksArray[0][0]) + " and " + this.removeDe(firstPicksArray[1][0]);
        }


        this.setState({
            teamName: this.props.teamName,
            vetos: allVetos,
            picks: allPicks,
            bans: allBans,
            roster: roster,
            typicalBan: firstBan,
            typicalBanPercent: (firstBansArray[0][1] / results.length * 100).toFixed(2),
            typicalPick: firstPick,
            typicalPickPercent: (firstPicksArray[0][1] / results.length * 100).toFixed(2),
            typical2ndBan: secondBan,
            typical2ndBanPercent: (secondBansArray[0][1] / results.length * 100).toFixed(2),
            lastMatches: wins + ' Wins - ' + (results.length - wins) + ' Losses',
            numOfMatches: results.length,
            allMatches: allMatches
        });

    }

    removeDe(map) {
        var map = map.replace('de_', '');
        return map.charAt(0).toUpperCase() + map.slice(1);
    }

    render() {
        const {
            teamName,
            vetos,
            picks,
            bans,
            roster,
            typicalBan,
            typicalBanPercent,
            typicalPick,
            typicalPickPercent,
            typical2ndBan,
            typical2ndBanPercent,
            lastMatches,
            numOfMatches
        } = this.state;

        let teamInfo;

        if (teamName !== '') {
            teamInfo = <div className="team-general-stats">
                <div className="map-stats-upcoming">(Highly in-depth advanced analytics coming soon)</div>
                <div className="map-stats-header">Overall Stats</div>
                <div className="map-stats"><span className="map-stats-label">Typical Ban:</span>{typicalBan} ({typicalBanPercent}%)</div>
                <div className="map-stats"><span className="map-stats-label">Typical Pick:</span>{typicalPick} ({typicalPickPercent}%)</div>
                <div className="map-stats"><span className="map-stats-label">Typical 2nd Ban:</span>{typical2ndBan} ({typical2ndBanPercent}%)</div>
                {/* <div>HLTV Rank: </div> */}
                <div className="map-stats"><span className="map-stats-label">Last {numOfMatches} Matches:</span>{lastMatches}</div>
                {/* <div>Roster: {roster.map((name) => { return name.name + " " })}</div> */}
            </div>
        }

        return (
            <React.Fragment>
                {teamInfo}
            </React.Fragment>
        );
    }
}