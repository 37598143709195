//my-demo-app/src/Home/Lougout.js
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { useNavigate } from "react-router-dom";

export default function Logout(props) {
  var apiUrl = '';
  const navigate = useNavigate();

  switch (process.env.NODE_ENV) {
    case 'production':
      apiUrl = process.env.REACT_APP_API_HOST_URL
      break;
    case 'development':
      apiUrl = process.env.REACT_APP_API_DEV_URL
    default:
  }

  async function logoutClicked() {
    await signOut();
    localStorage.removeItem("user");
    localStorage.removeItem("userEmail");
    navigate("/");
  }

  async function handleAccount() {
    var user = JSON.parse(localStorage.getItem('user'));
    var stripeCustomerId = user[0].stripeCustomerId //Here this should be the cookie that is set from when they log in and query back end

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ stripeCustomerId: stripeCustomerId, returnUrl: window.location.href })
    };

    // Send data to the backend via POST
    fetch(apiUrl + 'customer-portal', requestOptions)
      .then(response => response.json())
      .then((response) => {
        window.location.href = response.url;

      })
      .catch((error) => {
        console.error(error);
      })
  }

  return (
    <div className="logout-container flex">
      {/* <div className="account-button" onClick={handleAccount}>
        My account
      </div> */}
      <div className="logout-button" onClick={logoutClicked}> 
        Logout
      </div>
    </div>
  );
}