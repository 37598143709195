import React from 'react';
import ReactDOM from 'react-dom';
import 'react-tabs/style/react-tabs.css';
import './index.css';

import Home from './Home';
import Demo from './Demo';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from './LandingPage';
import { LogoutUrl } from './LogoutUrl';
import { PrivacyPolicy } from './PrivacyPolicy';
// Imports from SuperTokens
import SuperTokens, { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import ReactGA from 'react-ga4';

import ThirdPartyEmailPassword, { ThirdPartyEmailPasswordAuth, Google } from "supertokens-auth-react/recipe/thirdpartyemailpassword";

import Session from "supertokens-auth-react/recipe/session";
import { callApi } from './CallApi';
import { TermsOfService } from './TermsOfService';
import { PaymentLandingPage } from './PaymentLandingPage';
import Signup from './Signup';
import { callApiNoCache } from './callApiNoCache';
import {SupportPage} from './SupportPage';

var apiUrl;
var websiteDomain;
var basePath;

ReactGA.initialize('G-50PWPY1T1Q');
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

switch (process.env.NODE_ENV) {
  case 'production':
    apiUrl = process.env.REACT_APP_API_HOST_URL
    websiteDomain = process.env.REACT_APP_HOST_URL
    basePath = process.env.REACT_APP_BASE_PATH
    break;
  case 'development':
    apiUrl = process.env.REACT_APP_API_DEV_URL
    websiteDomain = process.env.REACT_APP_DEV_URL
    basePath = '/auth'
  default:
}

// SuperTokens INIT
SuperTokens.init({
  appInfo: {
    appName: "CSGO Scouting",
    apiDomain: apiUrl,
    websiteDomain: websiteDomain,
    apiBasePath: basePath,
  },
  recipeList: [
    ThirdPartyEmailPassword.init({
      signInAndUpFeature: {
        signUpForm: {
          termsOfServiceLink: "https://csgoscouting.com/terms",
          privacyPolicyLink: "https://csgoscouting.com/privacy"
        },
        providers: [
          Google.init(),
        ]
      },
      onHandleEvent: async (context) => {
        var buildUrl;
        if (context.action === "SESSION_ALREADY_EXISTS") {
          // What would go here? nothing?
        } else if (context.action === "SUCCESS") {
          localStorage.setItem('userEmail', context.user.email);
          if (context.isNewUser) {
            buildUrl = 'users/createUser?email=' + context.user.email + '&loginId=' + context.user.id + '&creationDate=' + context.user.timeJoined + '&lastLogin=' + context.user.timeJoined;
            if (context.user.thirdParty) {
              buildUrl = buildUrl + '&thirdParty=true' + '&thirdPartyId=' + context.user.thirdParty.userId + '&thirdPartyName=' + context.user.thirdParty.id;
            } else {
              buildUrl = buildUrl + '&thirdParty=false';
            }
          } else {
            buildUrl = 'users/updateUserLogin?email=' + context.user.email + '&lastLogin=' + Date.now();
          }

          await callApi(buildUrl)
          var userInformation = await callApiNoCache('users/getUser?email=' + context.user.email);
          localStorage.setItem('user', JSON.stringify(userInformation));
        }
      }
    }),
    Session.init(),
  ],
});

ReactDOM.render(
  <React.StrictMode>
    {/* <Home /> */}
    <Router basename='/'>
      <Routes>
        {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"))}
        <Route path="/" element={<LandingPage path="" />} />
        <Route path="/scouting" element={<ThirdPartyEmailPasswordAuth><Home path="" /></ThirdPartyEmailPasswordAuth>} />
        {/* <Route path="/match/:matchId" element={<Home path="match" />} /> */}
        <Route path="/scouting/round/:matchId/:teamName/:mapName/:round" element={<ThirdPartyEmailPasswordAuth><Home path="round" /></ThirdPartyEmailPasswordAuth>} />
        <Route path="/scouting/utility/:mapName/:teamName/:positionType/:side/:xPos/:yPos/:zPos" element={<ThirdPartyEmailPasswordAuth><Home path="utility" /></ThirdPartyEmailPasswordAuth>} />
        <Route path="/scouting/team/:teamName" element={<ThirdPartyEmailPasswordAuth><Home path="team" /></ThirdPartyEmailPasswordAuth>} />
        <Route path="/scouting/player">
          <Route index element={<Home path="player" />} />
          <Route path=":player1Team/:player1Name" element={<ThirdPartyEmailPasswordAuth><Home path="player" /></ThirdPartyEmailPasswordAuth>} />
          <Route path=":player1Team/:player1Name/:player2Team/:player2Name" element={<ThirdPartyEmailPasswordAuth><Home path="player" /></ThirdPartyEmailPasswordAuth>} />
        </Route>
        <Route path="/demo" element={<Demo />} />
        <Route path="/logout" element={<LogoutUrl />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/pricing" element={<PaymentLandingPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/signup/:priceId" element={<ThirdPartyEmailPasswordAuth><Signup /></ThirdPartyEmailPasswordAuth>} />
      </Routes>
    </Router>

  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
