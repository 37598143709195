import React from "react";
import { SelectTeam } from "./SelectTeam";

export default function Demo() {
    var renderedComponent;
    var teamNameArray = {
        value: process.env.REACT_APP_DEMO_TEAM_NAME,
        label: process.env.REACT_APP_DEMO_TEAM_NAME
    }
    renderedComponent = <SelectTeam path={'demo'} teamName={teamNameArray} />
    return (
        <div className='home'>
            <header className="scouting-container flex-vertical">
                {renderedComponent}
            </header>
        </div>
    );
}