import React, { Component } from "react";

export class RolesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: {},
            mapNames: [],
            playerNames: [],
            oldPlayerNames: [],
        }
    }

    componentDidMount() {
        if (this.props.rolesInfo !== this.state.roles) {
            let roles = this.props.rolesInfo;
            for (const name in roles) {
                // Check if the name exists in the player names array
                const isNameInArray = this.props.playerNames.some(player => player.name === name);
              
                // If the name is not in the namesArray, remove it from the roles
                if (!isNameInArray) {
                  delete roles[name];
                }
              }
            this.setState({ roles: roles, mapNames: this.props.mapNames, playerNames: this.props.playerNames, oldPlayerNames: this.props.oldPlayerNames });
        }
    }

    componentDidUpdate() {
        if ((this.props.rolesInfo !== this.state.roles) && (Object.keys(this.props.rolesInfo).length > 0)) {
            let roles = this.props.rolesInfo;
            for (const name in roles) {
                // Check if the name exists in the player names array
                const isNameInArray = this.props.playerNames.some(player => player.name === name);
              
                // If the name is not in the namesArray, remove it from the roles
                if (!isNameInArray) {
                  delete roles[name];
                }
              }
            this.setState({ roles: roles, mapNames: this.props.mapNames, playerNames: this.props.playerNames, oldPlayerNames: this.props.oldPlayerNames });
        }
    }

    render() {
        const {
            roles,
            mapNames
        } = this.state;


        const columns = [
            { key: 'id', name: 'ID' },
            { key: 'title', name: 'Title' }
        ];


        return (
            <div className="roles-container flex-vertical">
                <div className="roles-table-container flex-vertical">
                    <div className="roles-h1 flex-one-same">CT Side Roles</div>
                    <div className="roles-header-row flex">
                        <div key={"header-blank"} className="roles-header-blank flex-one-same role-cell flex"></div>
                        {mapNames.map((key, index) => {
                            var map = key.replace('de_', '');
                            map = map.charAt(0).toUpperCase() + map.slice(1);
                            return (
                                <div key={"header-" + index} className="flex-one-same role-cell flex">
                                    <div>{map}</div>
                                </div>
                            )
                        })}
                    </div>
                    {(Object.entries(roles).map(([key, value], index) => {
                        return (<div className="role-row flex">
                            <div className="role-title flex-one-same role-cell flex">{key}</div>
                            {(mapNames.map((mapName, mapIndex) => {
                                let mostPopularPlace = value[mapName]?.CT?.mostPopularPlace;
                                let confidenceLevel = value[mapName]?.CT?.confidenceLevel;
                                if (confidenceLevel < 10) {
                                    mostPopularPlace = mostPopularPlace + " / " + value[mapName]?.CT?.secondMostPopularPlace;
                                }
                                return (
                                    <div className="role-value flex-one-same role-cell flex">{mostPopularPlace}</div>
                                )
                            }))}
                        </div>)
                    }))}
                </div>
                <div className="roles-table-container flex-vertical">
                <div className="roles-h1 flex-one-same">T Side Roles</div>
                    <div className="roles-header-row flex">
                        <div key={"header-blank"} className="roles-header-blank flex-one-same role-cell flex"></div>
                        {mapNames.map((key, index) => {
                            var map = key.replace('de_', '');
                            map = map.charAt(0).toUpperCase() + map.slice(1);
                            return (
                                <div key={"header-" + index} className="flex-one-same role-cell flex">
                                    <div>{map}</div>
                                </div>
                            )
                        })}
                    </div>
                    {(Object.entries(roles).map(([key, value], index) => {
                        return (<div className="role-row flex">
                            <div className="role-title flex-one-same role-cell flex">{key}</div>
                            {(mapNames.map((mapName, mapIndex) => {
                                let mostPopularPlace = value[mapName]?.TERRORIST?.mostPopularPlace;
                                let confidenceLevel = value[mapName]?.TERRORIST?.confidenceLevel;
                                if (confidenceLevel < 10) {
                                    mostPopularPlace = mostPopularPlace + " / " + value[mapName]?.TERRORIST?.secondMostPopularPlace;
                                }
                                return (
                                    <div className="role-value flex-one-same role-cell flex">{value[mapName]?.TERRORIST?.mostPopularPlace}</div>
                                )
                            }))}
                        </div>)
                    }))}
                </div>
            </div>
        );
    }
}