import React, { Component } from "react";
import { PlayerPositionInfo } from "./PlayerPositionInfo";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { RoundSlider } from "./RoundSlider";

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideBomb: false,
            hideSmokeGrenades: false,
            hideMolotovGrenades: false,
            hideFlashbangGrenades: false,
            showUtilityNames: false,
            showOpponent: true,
            sliderTime: 15,
            matchesInfo: [],
            mapName: '',
            teamStats: {},
            tabIndex: 0,
            teamName: '',
            allPositions: {},
            currentPositions: {},
            allCirclePositions: {},
            circlesFilters: {
                allRounds: true,
                ecoRounds: false,
                antiEcoRounds: false,
                forceBuyRounds: false,
                fullBuyRounds: false,
                pistolRounds: false,
                afterPistolWinRounds: false,
                afterPistolLossRounds: false,
                firstFullBuyRounds: false,
                awpRounds: false,
                fullBuyNoAwpRounds: false,
                customEconomy: false,
            },
            showOpponent: true,
            showUtilityNames: false,
            sliderValue: 3,
            matchIds: [],
        }
        this.handleChangeSlider = this.handleChangeSlider.bind(this);
        this.handleFilterButtonChange = this.handleFilterButtonChange.bind(this);
        this.handleUtilityNamesChange = this.handleUtilityNamesChange.bind(this);
        this.handleOpponentChange = this.handleOpponentChange.bind(this);
        this.handleMapSizeSlider = this.handleMapSizeSlider.bind(this);

        
    }

    componentDidMount() {
        this.setState({
            allCirclePositions: this.createCirclesArray(JSON.parse(JSON.stringify(this.props.allPositions))),
            mapName: this.props.mapName,
            teamName: this.props.teamName,
            matchIds: this.props.matchIds,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.allPositions !== this.props.allPositions || prevProps.teamName !== this.props.teamName || this.props.matchIds !== this.state.matchIds) {
            this.setState({
                allCirclePositions: this.createCirclesArray(JSON.parse(JSON.stringify(this.props.allPositions))),
                mapName: this.props.mapName,
                teamName: this.props.teamName,
                matchIds: this.props.matchIds,
            });
        }
    }

    handleChangeSlider = (event) => {
        this.setState({
            [event.target.getAttribute('data-state')]: event.target.value
        });
    }

    handleChangeSliderFinal = (event) => {

    }

    createCirclesArray = (allCirclePositions) => {
        // var allCirclePositions = this.state.allPositions ? this.state.allPositions : {};
        Object.keys(allCirclePositions).forEach((second) => {
            var positions = allCirclePositions[second];
            var circlesArray = []
            var circlesArrayAll = [];

            var currentRound = 0;
            var currentMatchId = 0;
            var currentTeamName = '';
            var skipRound = 0;
            var skipMatchId = 0;
            for (var i = 0; i < positions.length; i++) {
                if (skipRound !== positions[i].roundNumber || skipMatchId !== positions[i].matchId) {
                    // if (positions[i].playerTeam === this.state.teamName.value || this.state.showOpponent) {
                    var xPos;
                    var yPos;
                    if (i === 0) {
                        currentMatchId = positions[i].matchId
                        currentRound = positions[i].roundNumber
                        currentTeamName = positions[i].playerTeam
                    }
                    var side = positions[i].side;
                    if (side === "TERRORIST") {
                        side = 'T';
                    }

                    if (positions[i].matchId === currentMatchId && positions[i].roundNumber === currentRound) {
                        xPos = positions[i].xPos;
                        yPos = positions[i].yPos;

                        circlesArray.push(positions[i])
                    } else {
                        if (circlesArray.length > 0) {
                            var teamName = this.props.teamName.value
                            circlesArray.sort(function (a, b) {
                                if (a.positionType === "Player" && a.playerTeam === teamName) {
                                    return -1;
                                }
                                return 0;
                            });
                            circlesArrayAll.push(JSON.parse(JSON.stringify(circlesArray)))
                        }

                        circlesArray.length = 0
                        xPos = positions[i].xPos;
                        yPos = positions[i].yPos;
                        currentRound = positions[i].roundNumber;
                        currentMatchId = positions[i].matchId;
                        circlesArray.push(positions[i])
                    }

                    // if (positions[i].playerTeam === this.state.teamName.value && side !== this.state.side) {
                    //     circlesArray.length = 0;
                    //     skipRound = positions[i].roundNumber;
                    //     skipMatchId = positions[i].matchId;
                    // }
                    // }
                }
            }

            if (circlesArray.length > 0) {
                circlesArray.sort(function (a, b) {
                    return ((a.buy != undefined && a.buy !== "") ? -1 : 0);
                });
                circlesArrayAll.push(JSON.parse(JSON.stringify(circlesArray)))
            }

            allCirclePositions[second] = circlesArrayAll;
        });
        return allCirclePositions;
    }

    handleFilterButtonChange(event) {
        var filter = event.target.getAttribute('data-state')
        var updatedCircleFilters = JSON.parse(JSON.stringify(this.state.circlesFilters));
        updatedCircleFilters[filter] = !updatedCircleFilters[filter]
        updatedCircleFilters.customEconomy = false;

        if (filter === "allRounds") {
            // this.state.circlesFilters.ecoRounds = false;
            Object.keys(updatedCircleFilters).forEach((key) => {
                updatedCircleFilters[key] = false;
            });
            updatedCircleFilters.allRounds = true;
        } else {
            updatedCircleFilters.allRounds = false;
        }

        var allFalse = true;
        Object.keys(updatedCircleFilters).forEach((key) => {
            if (updatedCircleFilters[key]) {
                allFalse = false;
            }
        });

        if (allFalse) {
            updatedCircleFilters.allRounds = true;
        }

        this.setState({ circlesFilters: updatedCircleFilters })
    }

    handleUtilityNamesChange(event) {
        this.setState({ showUtilityNames: event.target.checked }, () => {
        });
    }

    handleOpponentChange(event) {
        this.setState({ showOpponent: event.target.checked }, () => {
        });
    }

    handleMapSizeSlider(event) {
        this.setState({ sliderValue: event.target.value }, () => {
            // var maps = document.getElementsByClassName('map-svg-container');
            // var mapSize = Math.abs(this.state.sliderValue - 6);
            // var adjustment = 0;
            // // 10% actually equals 6 maps not 5, so adjust it to be 5
            // (this.state.sliderValue == 5) ? adjustment = 5 : adjustment = 0;

            // for (var i = 0; i < maps.length; i++) {
            //     maps[i].style.minWidth = (mapSize * 10 + adjustment) + '%';
            //     maps[i].style.maxWidth = (mapSize * 10 + adjustment) + '%';
            // }
        });
    }

    resetEconomyFilters = () => {
        var updatedCircleFilters = JSON.parse(JSON.stringify(this.state.circlesFilters));
        updatedCircleFilters.ecoRounds = false;
        updatedCircleFilters.forceBuyRounds = false;
        updatedCircleFilters.fullBuyRounds = false;
        updatedCircleFilters.customEconomy = true;
        updatedCircleFilters.allRounds = false;
        this.setState({ circlesFilters: updatedCircleFilters })
    }


    render() {
        const {
            showUtilityNames,
            showOpponent,
            matchesInfo,
            hideBomb,
            hideSmokeGrenades,
            hideMolotovGrenades,
            hideFlashbangGrenades,
            mapName,
            teamStats,
            tabIndex,
            pistolSliderValue,
            teamName,
            allCirclePositions,
            sliderValue,
        } = this.state;

        var allPositionsHTML;
        var mapStatsHTML;

        var allPositionsHTML = Object.keys(allCirclePositions).length > 0 ? <PlayerPositionInfo positions={allCirclePositions} mapName={mapName} second={pistolSliderValue} buy={"All"} side={"CT"} singlePlayer={false} teamName={teamName.value} showOpponent={showOpponent} showUtilityNames={showUtilityNames} hideBomb={hideBomb} matchesInfo={matchesInfo} hideSmokeGrenades={hideSmokeGrenades} hideMolotovGrenades={hideMolotovGrenades} hideFlashbangGrenades={hideFlashbangGrenades} circlesFilters={this.state.circlesFilters} handleFilterButtonChange={this.handleFilterButtonChange} handleUtilityNamesChange={this.handleUtilityNamesChange} handleOpponentChange={this.handleOpponentChange} sliderValue={sliderValue} handleMapSizeSlider={this.handleMapSizeSlider} resetEconomyFilters={this.resetEconomyFilters} /> : null;
        var allPositionsTHTML = Object.keys(allCirclePositions).length > 0 ? <PlayerPositionInfo positions={allCirclePositions} mapName={mapName} second={pistolSliderValue} buy={"All"} side={"T"} singlePlayer={false} teamName={teamName.value} showOpponent={showOpponent} showUtilityNames={showUtilityNames} hideBomb={hideBomb} matchesInfo={matchesInfo} hideSmokeGrenades={hideSmokeGrenades} hideMolotovGrenades={hideMolotovGrenades} hideFlashbangGrenades={hideFlashbangGrenades} circlesFilters={this.state.circlesFilters} handleFilterButtonChange={this.handleFilterButtonChange} handleUtilityNamesChange={this.handleUtilityNamesChange} handleOpponentChange={this.handleOpponentChange} sliderValue={sliderValue} handleMapSizeSlider={this.handleMapSizeSlider} resetEconomyFilters={this.resetEconomyFilters} /> : null;

        if (teamStats !== undefined && teamStats[mapName]) {
            var bombPlantSiteLean = 0;
            var AorB = "A";
            if (teamStats[mapName].bombPlantsA > teamStats[mapName].bombPlantsB) {
                bombPlantSiteLean = teamStats[mapName].bombPlantsA / teamStats[mapName].bombPlantsTotal * 100
                AorB = "A"
            } else if (teamStats[mapName].bombPlantsA === teamStats[mapName].bombPlantsB) {
                bombPlantSiteLean = 0.5 * 100
                AorB = "A or B"
            } else {
                bombPlantSiteLean = teamStats[mapName].bombPlantsB / teamStats[mapName].bombPlantsTotal * 100
                AorB = "B"
            }

            mapStatsHTML = <div>
                <div>Time to Bomb Plant = {teamStats[mapName].timeToPlant.toFixed(1)}</div>
                <div>Successful Bomb Plant Lean = {bombPlantSiteLean.toFixed(1)}% {AorB} site</div>
            </div>
        }

        var classNameLink = mapName;

        return (
            <React.Fragment>
                <div className="map-position-info-container">
                    <Tabs forceRenderTabPanel={true} >
                        <TabList className="map-tab-list flex">
                            <Tab>CT Side</Tab>
                            <Tab>T Side</Tab>
                        </TabList>
                        <TabPanel>
                            {allPositionsHTML}
                        </TabPanel>
                        <TabPanel>
                            {allPositionsTHTML}         
                        </TabPanel>
                    </Tabs>
                </div>
            </React.Fragment>
        );
    }
}