import React, { Component } from "react";
import { callApi } from './CallApi';
import { TeamGeneralInfo } from './TeamGeneralInfo';
import { PlayerPositionInfo } from './PlayerPositionInfo';
import { RoundSlider } from './RoundSlider';
import { MapSizeSlider } from './MapSizeSlider';
import { MapContainer } from './MapContainer';
import { SelectMatches } from './SelectMatches';
import { PlayerPositionOverview } from "./PlayerPositionOverview";
import { RolesTable } from "./RolesTable";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Logout from './Logout';
import { adjustCoord } from "./AdjustCoord";
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export class ScoutingReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            players: [],
            teamName: {},
            positions: [],
            pistolPositions: {},
            ecoPositions: {},
            forceBuyPositions: {},
            fullBuyPositions: {},
            awpPositions: {},
            fullBuyNoAwpPositions: {},
            firstFullBuyPositions: {},
            afterPistolWinPositions: {},
            afterPistolLossPositions: {},
            singlePlayer: false,
            hideSmokeGrenades: false,
            hideMolotovGrenades: false,
            hideFlashbangGrenades: false,
            hideBomb: false,
            showUtilityNames: false,
            showOpponent: true,
            sliderTime: 15,
            oldSliderTime: 15,
            maxPistolResults: 6,
            maxNonPistolResults: 3,
            maxPistolResultsOld: 6,
            maxNonPistolResultsOld: 3,
            matchesInfo: [],
            specificSitutation: false,
            mapNames: ["de_ancient", "de_anubis", "de_inferno", "de_mirage", "de_nuke", "de_dust2", "de_vertigo"],
            specificMapName: "",
            allMapsRetrieved: false,
            mapStats: {},
            overallStats: {},
            collapseArrow: "<",
            settingsItemsCollapse: '',
            settingsItemsContainerCollapse: '',
            positionContainerCollapse: '',
            loadingBarPercentage: 0,
            refreshButton: false,
            selectedMatches: {},
            oldSelectedMatches: {},
            updateMap: {},
            map1html: '', map2html: '', map3html: '', map4html: '', map5html: '', map6html: '', map7html: '',
            playerOverviewHTML: '',
            allPositionsRecieved: {},
            allOpponentPositionsRecieved: {},
            allSecondsPositions: {},
            allOpponentSecondsPositions: {},
            allRoundsInfo: {},
            allOverviewCircles: {},
            roundSliderTime: 15,
            playerNames: [],
            oldPlayerNames: [],
            teamStats: {},
            overallStats: {},
            rolesInfo: {},
            awpCount: {}
        }

        this.handleMolotovChange = this.handleMolotovChange.bind(this);
        this.handleSmokeChange = this.handleSmokeChange.bind(this);
        this.handleFlashbangChange = this.handleFlashbangChange.bind(this);
        this.handleBombChange = this.handleBombChange.bind(this);
        this.handleUtilityNamesChange = this.handleUtilityNamesChange.bind(this);
        // this.handlePistolRoundsChange = this.handlePistolRoundsChange.bind(this);
        // this.handleNonPistolRoundsChange = this.handleNonPistolRoundsChange.bind(this);
        this.handleRoundChangeOnBlur = this.handleRoundChangeOnBlur.bind(this);
        // this.handleMatchesInfo = this.handleMatchesInfo.bind(this);
        this.handleCollapseArrowClick = this.handleCollapseArrowClick.bind(this);
        this.handleMatchSelectChange = this.handleMatchSelectChange.bind(this);
        this.allPositionsIntoSeconds = this.allPositionsIntoSeconds.bind(this);
        this.handleOpponentChange = this.handleOpponentChange.bind(this);
    }

    componentDidMount() {
        if (this.props.path) {
            if (this.props.path === "round") {
                var matchId = this.props.params.matchId;
                var round = this.props.params.round;
                var teamName = this.props.params.teamName;
                var mapName = this.props.params.mapName;
                callApi('position/generalInfo?mapName=' + mapName + '&matchId=' + matchId + '&round=' + round + '&allSeconds=true')
                    .then(results => {
                        var mapName = this.props.params.mapName;
                        var matchIds = []
                        for (var i = 0; i < results.length; i++) {
                            //loop through and add the matchId from results into matchIds if it doesn't exist
                            if (!matchIds.includes(results[i].matchId)) {
                                matchIds.push(results[i].matchId)
                            }
                        }

                        //Call the round api here to get roundInfo
                        var roundsUrl = 'hltv/info?matchId=' + matchIds + '&mapName=' + mapName
                        callApi(roundsUrl).then(matchResults => {
                            for (var i = 0; i < results.length; i++) {
                                for (var j = 0; j < matchResults.length; j++) {
                                    var maps = JSON.parse(matchResults[j].maps);
                                    var totalRounds = 0;
                                    //loop through maps and find map by name
                                    for (var k = 0; k < maps.length; k++) {
                                        if (maps[k].name === mapName) {
                                            totalRounds = maps[k].result.team1TotalRounds + maps[k].result.team2TotalRounds
                                            break;
                                        }
                                    }
                                    // var map = maps[mapName]
                                    // var totalRounds = map
                                    if (results[i].matchId === matchResults[j].matchId) {
                                        results[i].opponentTeamName = (matchResults[j].team1Name === this.props.params.teamName ? matchResults[j].team2Name : matchResults[j].team1Name)
                                        results[i].totalRounds = totalRounds
                                    }
                                }
                            }
                            var positions = this.createRoundCirclesArray(results);
                            this.setState({
                                positions: positions,
                                teamName: this.props.params.teamName,
                                specificSitutation: true,
                                specificMapName: mapName
                            });
                        })
                    })
            }

            if (this.props.path === "utility") {
                var callApiUrl = 'position/generalInfo?teamName=' + this.props.params.teamName +
                    '&mapName=' + this.props.params.mapName + '&utility=true&x=' + this.props.params.xPos + '&y=' + this.props.params.yPos + '&z=' + this.props.params.zPos +
                    '&side=' + ((this.props.params.side) === 'T' ? 'TERRORIST' : this.props.params.side) + '&utilityType=' + this.props.params.positionType + '&expired=false'
                callApi(callApiUrl)
                    .then(results => {
                        // var positions = results;
                        // if (positions.length > 0) {
                        //     positions.sort(function (a, b) {
                        //         return (a.positionType == 'Player' ? -1 : 0);
                        //     });
                        // }
                        var mapName = this.props.params.mapName;
                        var matchIds = []
                        for (var i = 0; i < results.length; i++) {
                            //loop through and add the matchId from results into matchIds if it doesn't exist
                            if (!matchIds.includes(results[i].matchId)) {
                                matchIds.push(results[i].matchId)
                            }
                        }

                        //Call the round api here to get roundInfo
                        var roundsUrl = 'match/info?matchId=' + matchIds + '&mapName=' + mapName
                        callApi(roundsUrl).then(matchResults => {
                            for (var i = 0; i < results.length; i++) {
                                for (var j = 0; j < matchResults.length; j++) {
                                    if (results[i].matchId === matchResults[j].match_id) {
                                        results[i].opponentTeamName = (matchResults[j].team_1_name === this.props.params.teamName ? matchResults[j].team_2_name : matchResults[j].team_1_name)
                                    }
                                }
                            }

                            var positions = this.createUtilityCirclesArray(results);
                            this.setState({
                                positions: positions,
                                teamName: this.props.params.teamName,
                                specificSitutation: true,
                                specificMapName: this.props.params.mapName
                            });
                        })

                    })
            }
        }
    }

    componentDidUpdate() {
        if (this.props.teamName !== this.state.teamName && this.state.specificSitutation !== true) {
            if ((this.props.teamName !== null) && (this.props.teamName !== '')) {
                this.setState({
                    pistolPositions: {},
                    ecoPositions: {},
                    forceBuyPositions: {},
                    fullBuyPositions: {},
                    awpPositions: {},
                    fullBuyNoAwpPositions: {},
                    firstFullBuyPositions: {},
                    afterPistolWinPositions: {},
                    afterPistolLossPositions: {},
                    allMapsRetrieved: false,
                    teamName: this.props.teamName,
                    loadingBarPercentage: 0,
                    refreshButton: false,
                    selectedMatches: {},
                    oldSelectedMatches: {},
                    matchesInfo: [],
                    allMatches: [],
                    playerNames: [],
                    oldPlayerNames: [],
                }, () => {
                    this.displayPlayers(this.props.teamName.value)
                    this.displayPositions(this.props.teamName.value)
                });

            }
        }

    }

    displayPlayers(teamName) {
        callApi('team/players?teamName=' + teamName)
            .then(results => {
                this.setState({
                    players: results,
                    teamName: this.props.teamName
                });
            })
    }

    displayRefreshButton() {
        if (!this.state.allMapsRetrieved) {
            this.setState({
                refreshButton: true
            })
        }
    }

    handleMatchSelectChange(matchIds, mapName) {
        var selectedMatches = this.state.selectedMatches;
        if (matchIds !== selectedMatches[mapName]) {
            selectedMatches[mapName] = matchIds;
            this.setState({
                selectedMatches: selectedMatches,
                allMapsRetrieved: false,
                loadingBarPercentage: 0,
            }, () => {
                this.displayPositions(this.props.teamName.value)
            })
        }
    }

    async displayPositions(teamName) {
        //For loop with map names
        var mapsUpdated = 0;
        var teamStats = [];
        var overallStats = [];
        var fetchHltvData = [];
        var selectedMatches = {};
        var updateMap = {}

        if (Object.keys(this.state.selectedMatches).length === 0) {
            fetchHltvData = await callApi('hltv/info?teamName=' + teamName + '')
            selectedMatches = {};
            //loop through mapnames and add it to selectedmatches
            for (var i = 0; i < this.state.mapNames.length; i++) {
                selectedMatches[this.state.mapNames[i]] = [];
            }
            //loop through fetchHltvData and add to selectedMatches based on mapName
            for (var i = 0; i < fetchHltvData.length; i++) {
                var maps = JSON.parse(fetchHltvData[i].maps);
                //loop through maps and add to selectedMatches
                for (var j = 0; j < maps.length; j++) {
                    if (!maps[j].result) {
                        //If the map has no result skip it
                        continue;
                    }

                    if ((selectedMatches[maps[j].name]) && (selectedMatches[maps[j].name].length < 3)) {
                        selectedMatches[maps[j].name].push(fetchHltvData[i].matchId)
                    }
                }
            }
        } else {
            selectedMatches = this.state.selectedMatches;
        }

        for (var i = 0; i < this.state.mapNames.length; i++) {
            var fullApiURL = 'position/generalInfo?teamName=' + teamName + '&mapName=' + this.state.mapNames[i] + '&matchId=' + selectedMatches[this.state.mapNames[i]];
            var fullOpponentApiURL = 'position/generalInfo?notTeamName=' + teamName + '&mapName=' + this.state.mapNames[i] + '&matchId=' + selectedMatches[this.state.mapNames[i]];
            var fullRoundsApiURL = 'match/rounds?matchId=' + selectedMatches[this.state.mapNames[i]] + '&mapName=' + this.state.mapNames[i];
            var rolesApiURL = 'player/roles?teamName=' + teamName + "&roundTime=20"

            //Set timeout to display refresh button after 15 seconds if all maps have not been retrieved
            setTimeout(() => {
                this.displayRefreshButton();
            }, 25000);

            var fetchApiData = []
            var fetchOppoentApiData = []
            var fetchRoundsData = []
            var fetchRolesData = []

            //Only call the api if the matches don't exist or have changed after first load
            //Stringify is used to compare the arrays
            if ((this.state.sliderTime !== this.state.oldSliderTime) || (JSON.stringify(selectedMatches[this.state.mapNames[i]]) !== JSON.stringify(this.state.oldSelectedMatches[this.state.mapNames[i]])) || (Object.keys(this.state.allSecondsPositions).length === 0)) {
                updateMap[this.state.mapNames[i]] = true;
                fetchApiData = callApi(fullApiURL)
                fetchOppoentApiData = callApi(fullOpponentApiURL)
                fetchRoundsData = callApi(fullRoundsApiURL)

                //If first time through the for loop and first time loading data for that team, call team stats
                if (i === 0 && Object.keys(this.state.oldSelectedMatches).length === 0) {
                    //call team stats only first time
                    teamStats = callApi('stats/info?teamName=' + teamName);
                    overallStats = callApi('stats/topStats?teamName=overall');
                    fetchHltvData = callApi('hltv/info?teamName=' + teamName);
                    fetchRolesData = callApi(rolesApiURL)
                } else if (Object.keys(this.state.oldSelectedMatches).length > 0) {
                    teamStats = Promise.resolve(this.state.teamStats)
                    overallStats = callApi('stats/topStats?teamName=overall');
                    fetchHltvData = Promise.resolve(this.state.matchesInfo)
                    fetchRolesData = Promise.resolve(this.state.rolesInfo)
                }
            } else {
                updateMap[this.state.mapNames[i]] = false;
                teamStats = Promise.resolve(this.state.teamStats)
                fetchHltvData = Promise.resolve(this.state.matchesInfo)
                fetchApiData = Promise.resolve(this.state.allPositionsRecieved)
                fetchOppoentApiData = Promise.resolve(this.state.allOpponentPositionsRecieved)
                fetchRoundsData = Promise.resolve(this.state.allRoundsInfo)
                fetchRolesData = Promise.resolve(this.state.rolesInfo)
            }

            var currentNumber = i + 1;

            Promise.all([fetchApiData, fetchRoundsData, teamStats, fetchHltvData, this.state.mapNames[i], currentNumber, fetchOppoentApiData, overallStats, fetchRolesData]).then((values) => {
                var currentMapName = values[4];
                var currentHltvData = values[3]
                var currentTeamStats = values[2];
                var roundsInfo = values[1];
                var allPositions = values[0];
                var allOpponentPositions = values[6];
                var allSecondsPositions = this.state.allSecondsPositions;
                var loadingBarPercentage = (values[5] / 7 * 100 < this.state.loadingBarPercentage ? this.state.loadingBarPercentage : Math.round(values[5] / 8 * 100))
                var playerNames = [];
                var oldPlayerNames = [];
                var currentOverallStats = values[7];
                var currentRolesInfo = values[8];

                allPositions = allPositions.concat(allOpponentPositions);

                //Create a Sort allPositions by matchId then by round then by second then by teamName
                allPositions.sort(function (a, b) {
                    return a.matchId - b.matchId || a.roundNumber - b.roundNumber || a.roundTime - b.roundTime;
                });



                if (!allSecondsPositions[currentMapName]) {
                    allSecondsPositions[currentMapName] = {}
                }

                var allRoundsInfo = this.state.allRoundsInfo;
                if (!allRoundsInfo[currentMapName]) {
                    allRoundsInfo[currentMapName] = {}
                }

                if (currentHltvData[0].team1Name === teamName) {
                    playerNames = JSON.parse(currentHltvData[0].playersTeam1);
                } else {
                    playerNames = JSON.parse(currentHltvData[0].playersTeam2);
                }

                if (currentHltvData[10] && currentHltvData[10].team1Name === teamName) {
                    oldPlayerNames = JSON.parse(currentHltvData[10].playersTeam1);
                } else if (currentHltvData[10]) {
                    oldPlayerNames = JSON.parse(currentHltvData[10].playersTeam2);
                }

                playerNames.sort(function (a, b) {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }
                    return 0;
                })

                oldPlayerNames.sort(function (a, b) {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }
                    return 0;
                })

                // if (oldPlayerNames.length > 0 && JSON.stringify(oldPlayerNames) === JSON.stringify(playerNames)) {
                //     oldPlayerNames.length = 0;
                // }

                if (updateMap[currentMapName]) {
                    Object.getOwnPropertyNames(allSecondsPositions[currentMapName]).forEach(function (prop) {
                        delete allSecondsPositions[currentMapName][prop];
                    });
                    Object.getOwnPropertyNames(allRoundsInfo[currentMapName]).forEach(function (prop) {
                        delete allRoundsInfo[currentMapName][prop];
                    });

                    var previousSecond = 0;
                    var currentArray = [];
                    var previousArray = [];
                    var currentUtility = [];
                    for (var j = 0; j < allPositions.length; j++) {
                        var currentPosition = allPositions[j];
                        currentPosition.roundTime = Math.ceil(currentPosition.roundTime / 5) * 5;
                        var currentSecond = currentPosition.roundTime;
                        var round = roundsInfo.find(function (round) {
                            return round.matchId === currentPosition.matchId && round.round === currentPosition.roundNumber;
                        })

                        if (previousSecond > currentSecond) {
                            currentUtility.length = 0; //reset utility at end of round
                            if (previousSecond < 155) {
                                for (var k = previousSecond + 5; k <= 155; k += 5) {
                                    //if the object doesn't exist, create it
                                    if (!allSecondsPositions[currentMapName][k]) {
                                        allSecondsPositions[currentMapName][k] = []
                                    }
                                    if (currentArray.length < 10) {
                                        currentArray = JSON.parse(JSON.stringify(previousArray));
                                    }
                                    for (var l = 0; l < currentArray.length; l++) {
                                        var currentPositionObject = JSON.parse(JSON.stringify(currentArray[l]));
                                        currentPositionObject.roundTime = k;
                                        allSecondsPositions[currentMapName][k].push(currentPositionObject);
                                    }
                                }
                            }
                        }
                        if (!allSecondsPositions[currentMapName][currentSecond]) {
                            allSecondsPositions[currentMapName][currentSecond] = []
                        }
                        if (previousSecond !== currentSecond) {
                            if (currentArray.length >= 10) {
                                previousArray = JSON.parse(JSON.stringify(currentArray));
                            }
                            currentArray.length = 0;
                            for (var k = 0; k < currentUtility.length; k++) {
                                if (allSecondsPositions[currentMapName][previousSecond].length > 0) {
                                    allSecondsPositions[currentMapName][previousSecond].push(JSON.parse(JSON.stringify(currentUtility[k])));
                                    // currentArray.push(JSON.parse(JSON.stringify(currentUtility[k])));
                                }
                                if (currentUtility[k].positionType === "Flashbang") {
                                    currentUtility.splice(currentUtility.findIndex(({ entityId }) => entityId == currentUtility[k].entityId), 1);
                                    k = k - 1;
                                }
                            }
                        }

                        //Add in round info
                        currentPosition.teamBuy = round.team1Name === currentPosition.playerTeam ? round.team1Buy : round.team2Buy;
                        currentPosition.opponentBuy = round.team1Name === currentPosition.playerTeam ? round.team2Buy : round.team1Buy;
                        currentPosition.teamMoneySpent = round.team1Name === currentPosition.playerTeam ? round.team1MoneySpent : round.team2MoneySpent;
                        currentPosition.opponentMoneySpent = round.team1Name === currentPosition.playerTeam ? round.team2MoneySpent : round.team1MoneySpent;
                        currentPosition.roundInfo = round;

                        if (currentPosition.side === "TERRORIST") {
                            currentPosition.side = 'T';
                        }

                        if (allPositions[j].positionType !== "Player" && (allPositions[j].expired === "false" || allPositions[j].positionType === "Flashbang")) {
                            if (currentUtility.findIndex(({ entityId }) => entityId == currentPosition.entityId) < 0) { //Sometimes utility in the positions twice? don't add twice if so
                                currentUtility.push(JSON.parse(JSON.stringify(currentPosition)))
                            }
                        } else if (allPositions[j].positionType !== "Player" && allPositions[j].expired === "true") {
                            currentUtility.splice(currentUtility.findIndex(({ entityId }) => entityId == currentPosition.entityId), 1);
                        }


                        if (allPositions[j].positionType === "Player") {
                            currentArray.push(currentPosition);
                            allSecondsPositions[currentMapName][currentSecond].push(currentPosition);
                            previousSecond = currentSecond;
                        }

                    }

                    if (previousSecond < 155) {
                        for (var k = previousSecond + 5; k <= 155; k += 5) {
                            //if the object doesn't exist, create it
                            if (!allSecondsPositions[currentMapName][k]) {
                                allSecondsPositions[currentMapName][k] = []
                            }
                            if (currentArray.length < 10) {
                                currentArray = JSON.parse(JSON.stringify(previousArray));
                            }
                            for (var l = 0; l < currentArray.length; l++) {
                                var currentPositionObject = JSON.parse(JSON.stringify(currentArray[l]));
                                currentPositionObject.roundTime = k;
                                allSecondsPositions[currentMapName][k].push(currentPositionObject);
                            }
                        }
                    }

                    for (var j = 0; j < roundsInfo.length; j++) {
                        var currentRound = roundsInfo[j];
                        var currentRoundNumber = currentRound.round;
                        var currentRoundMatchId = currentRound.matchId;
                        if (!allRoundsInfo[currentMapName][currentRoundMatchId]) {
                            allRoundsInfo[currentMapName][currentRoundMatchId] = {}
                        }
                        allRoundsInfo[currentMapName][currentRoundMatchId][currentRoundNumber] = currentRound;
                    }
                }

                // }
                this.setState({
                    allPositionsRecieved: values[0],
                    allOpponentPositionsRecieved: values[6],
                    allSecondsPositions: allSecondsPositions,
                    allRoundsInfo: allRoundsInfo,
                    teamStats: currentTeamStats,
                    overallStats: currentOverallStats,
                    updateMap: updateMap,
                    loadingBarPercentage: loadingBarPercentage,
                    matchesInfo: currentHltvData,
                    selectedMatches: selectedMatches,
                    oldSelectedMatches: JSON.parse(JSON.stringify(selectedMatches)),
                    refreshButton: false,
                    playerNames: playerNames,
                    oldPlayerNames: oldPlayerNames,
                    rolesInfo: currentRolesInfo,
                }, () => {
                    mapsUpdated++;
                    if (mapsUpdated === this.state.mapNames.length) {
                        this.allPositionsIntoSeconds();
                    }
                });

            })
        }
    }

    allPositionsIntoSeconds() {
        var allRounds = this.state.allRoundsInfo;
        var sliderTime = this.state.sliderTime;
        var allPositions = this.state.allSecondsPositions;
        var mapNames = this.state.mapNames;
        var teamName = this.state.teamName;
        var currentAllRounds = this.state.allRoundsPositions;
        var currentPistolPositions = this.state.pistolPositions;
        var currentEcoPositions = this.state.ecoPositions;
        var currentForceBuyPositions = this.state.forceBuyPositions;
        var currentFullBuyPositions = this.state.fullBuyPositions;
        var currentAwpPositions = this.state.awpPositions;
        var currentFullBuyNoAwpPositions = this.state.fullBuyNoAwpPositions;
        var currentFirstFullBuyPositions = this.state.firstFullBuyPositions;
        var currentAfterPistolWinPositions = this.state.afterPistolWinPositions;
        var currentAfterPistolLossPositions = this.state.afterPistolLossPositions;
        var updateMap = this.state.updateMap;

        for (var i = 0; i < mapNames.length; i++) {
            var currentMapName = mapNames[i];

            if (updateMap[currentMapName]) {
                var allPositionsMap = allPositions[currentMapName];
                var allRoundsMap = allRounds[currentMapName];
                var containsFirstFullBuy = false;
                var firstFullBuyRoundNumber = 0;
                var firstFullBuyMatchId = 0;
                var firstFullBuySide = '';
                if (!currentPistolPositions[currentMapName]) {
                    currentPistolPositions[currentMapName] = [];
                    currentEcoPositions[currentMapName] = [];
                    currentForceBuyPositions[currentMapName] = [];
                    currentFullBuyPositions[currentMapName] = [];
                    currentAwpPositions[currentMapName] = [];
                    currentFullBuyNoAwpPositions[currentMapName] = [];
                    currentFirstFullBuyPositions[currentMapName] = [];
                    currentAfterPistolWinPositions[currentMapName] = [];
                    currentAfterPistolLossPositions[currentMapName] = [];
                } else {
                    currentPistolPositions[currentMapName].length = 0;
                    currentEcoPositions[currentMapName].length = 0;
                    currentForceBuyPositions[currentMapName].length = 0;
                    currentFullBuyPositions[currentMapName].length = 0;
                    currentAwpPositions[currentMapName].length = 0;
                    currentFullBuyNoAwpPositions[currentMapName].length = 0;
                    currentFirstFullBuyPositions[currentMapName].length = 0;
                    currentAfterPistolWinPositions[currentMapName].length = 0;
                    currentAfterPistolLossPositions[currentMapName].length = 0;
                }

                if (allPositionsMap) {
                    Object.keys(allPositionsMap).forEach((key) => {
                        for (var j = 0; j < allPositionsMap[key].length; j++) {
                            var currentRoundInfo = {};
                            var currentRoundNumber = allPositionsMap[key][j].roundNumber;
                            var currentPosition = allPositionsMap[key][j];
                            var currentMapsRounds = allRoundsMap[currentPosition.matchId]
                            var currentSecond = currentPosition.roundTime;

                            currentRoundInfo = currentMapsRounds[currentRoundNumber];
                            var currentTeamSide = (currentRoundInfo.team1Name === teamName.value) ? currentRoundInfo.team1Side : currentRoundInfo.team2Side;
                            if (!currentPistolPositions[currentMapName][currentSecond]) {
                                currentPistolPositions[currentMapName][currentSecond] = [];
                                currentEcoPositions[currentMapName][currentSecond] = [];
                                currentForceBuyPositions[currentMapName][currentSecond] = [];
                                currentFullBuyPositions[currentMapName][currentSecond] = [];
                                currentAwpPositions[currentMapName][currentSecond] = [];
                                currentAfterPistolWinPositions[currentMapName][currentSecond] = [];
                                currentAfterPistolLossPositions[currentMapName][currentSecond] = [];
                                currentFullBuyNoAwpPositions[currentMapName][currentSecond] = [];
                                currentFirstFullBuyPositions[currentMapName][currentSecond] = [];
                            }

                            currentPosition.xPos_old = currentPosition.xPos;
                            currentPosition.yPos_old = currentPosition.yPos;

                            currentPosition.xPos = adjustCoord(currentPosition.xPos, currentPosition.zPos, currentMapName, true)
                            currentPosition.yPos = adjustCoord(currentPosition.yPos, currentPosition.zPos, currentMapName, false)

                            var containsAwpTeam1 = false;
                            var containsAwpTeam2 = false;
                            Object.values(JSON.parse(currentRoundInfo.team1Weapons)).forEach((weapon) => {
                                if (weapon === "AWP") {
                                    containsAwpTeam1 = true;
                                }
                            })
                            Object.values(JSON.parse(currentRoundInfo.team2Weapons)).forEach((weapon) => {
                                if (weapon === "AWP") {
                                    containsAwpTeam2 = true;
                                }
                            })

                            if (currentRoundInfo && currentRoundInfo.team1Name === teamName.value) { //Have to see if it exists for now until re-sraping done
                                if (currentRoundInfo.team1Buy === "Pistol") {
                                    currentPistolPositions[currentMapName][currentSecond].push(currentPosition);
                                    continue;
                                }

                                if (currentRoundInfo.round == 2 || currentRoundInfo.round == 17) {
                                    if (currentRoundInfo.team1Score > currentRoundInfo.team2Score) {
                                        currentAfterPistolWinPositions[currentMapName][currentSecond].push(currentPosition);
                                    } else {
                                        currentAfterPistolLossPositions[currentMapName][currentSecond].push(currentPosition);
                                    }
                                    continue;
                                }

                                if (containsAwpTeam1) {
                                    currentAwpPositions[currentMapName][currentSecond].push(currentPosition);
                                }

                                if (currentRoundInfo.team1Buy === "Eco") {
                                    currentEcoPositions[currentMapName][currentSecond].push(currentPosition);
                                    continue;
                                }

                                if (currentRoundInfo.team1Buy === "Force Buy") {
                                    currentForceBuyPositions[currentMapName][currentSecond].push(currentPosition);
                                    continue;
                                }

                                if (currentRoundInfo.team1Buy === "Full Buy") {
                                    currentFullBuyPositions[currentMapName][currentSecond].push(currentPosition);

                                    if (currentRoundInfo.team2Buy === "Full Buy") {
                                        if ((currentRoundInfo.matchId !== firstFullBuyMatchId || currentTeamSide !== firstFullBuySide) && currentRoundInfo.round < 31) {
                                            firstFullBuyRoundNumber = currentRoundInfo.round;
                                            firstFullBuyMatchId = currentRoundInfo.matchId;
                                            firstFullBuySide = currentRoundInfo.team1Side;
                                        }

                                        if (currentRoundInfo.round == firstFullBuyRoundNumber) {
                                            currentFirstFullBuyPositions[currentMapName][currentSecond].push(currentPosition);
                                        }
                                    }

                                    if (!containsAwpTeam1) {
                                        currentFullBuyNoAwpPositions[currentMapName][currentSecond].push(currentPosition);
                                    }
                                    continue;
                                }

                            } else if (currentRoundInfo) {//Have to see if it exists for now until re-sraping done
                                if (currentRoundInfo.team2Buy === "Pistol") {
                                    currentPistolPositions[currentMapName][currentSecond].push(currentPosition);
                                    continue;
                                }

                                if (currentRoundInfo.round == 2 || currentRoundInfo.round == 17) {
                                    if (currentRoundInfo.team1Score < currentRoundInfo.team2Score) {
                                        currentAfterPistolWinPositions[currentMapName][currentSecond].push(currentPosition);
                                    } else {
                                        currentAfterPistolLossPositions[currentMapName][currentSecond].push(currentPosition);
                                    }
                                    continue;
                                }

                                if (containsAwpTeam2) {
                                    currentAwpPositions[currentMapName][currentSecond].push(currentPosition);
                                }

                                if (currentRoundInfo.team2Buy === "Eco") {
                                    currentEcoPositions[currentMapName][currentSecond].push(currentPosition);
                                    continue;
                                }

                                if (currentRoundInfo.team2Buy === "Force Buy") {
                                    currentForceBuyPositions[currentMapName][currentSecond].push(currentPosition);
                                    continue;
                                }

                                if (currentRoundInfo.team2Buy === "Full Buy") {
                                    currentFullBuyPositions[currentMapName][currentSecond].push(currentPosition);

                                    if (currentRoundInfo.team1Buy === "Full Buy") {
                                        if ((currentRoundInfo.matchId !== firstFullBuyMatchId || currentTeamSide !== firstFullBuySide) && currentRoundInfo.round < 31) {
                                            firstFullBuyRoundNumber = currentRoundInfo.round;
                                            firstFullBuyMatchId = currentRoundInfo.matchId;
                                            firstFullBuySide = currentRoundInfo.team2Side;
                                        }

                                        if (currentRoundInfo.round == firstFullBuyRoundNumber) {
                                            currentFirstFullBuyPositions[currentMapName][currentSecond].push(currentPosition);
                                        }
                                    }

                                    if (!containsAwpTeam2) {
                                        currentFullBuyNoAwpPositions[currentMapName][currentSecond].push(currentPosition);
                                    }
                                    continue;
                                }
                            }
                        }
                    });
                }
            }
        }

        this.setState({
            pistolPositions: this.sortPositions(currentPistolPositions),
            ecoPositions: this.sortPositions(currentEcoPositions),
            forceBuyPositions: this.sortPositions(currentForceBuyPositions),
            fullBuyPositions: this.sortPositions(currentFullBuyPositions),
            currentFirstFullBuyPositions: this.sortPositions(currentFirstFullBuyPositions),
            awpPositions: this.sortPositions(currentAwpPositions),
            noAwpPositions: this.sortPositions(currentFullBuyNoAwpPositions),
            firstFullBuyPositions: this.sortPositions(currentFirstFullBuyPositions),
            afterPistolWinPositions: this.sortPositions(currentAfterPistolWinPositions),
            afterPistolLossPositions: this.sortPositions(currentAfterPistolLossPositions),
        }, () => {
            this.createOverviewCirclesArray();
        })
    }

    sortPositions(positions) {
        var sortedPositions = {};
        for (var mapName in positions) {
            sortedPositions[mapName] = [];
            for (var second in positions[mapName]) {
                sortedPositions[mapName][second] = positions[mapName][second].sort((a, b) => {
                    return a.matchId - b.matchId || a.roundNumber - b.roundNumber || a.playerTeam - b.playerTeam;
                });
            }
        }
        return sortedPositions;
    }

    updateRoundSvg() {
        var map1html = (!this.state.updateMap[this.state.mapNames[0]]) ? this.state.map1html : <MapContainer mapName={this.state.mapNames[0]} teamName={this.state.teamName} allPositions={this.state.allSecondsPositions[this.state.mapNames[0]]} matchIds={this.state.selectedMatches[this.state.mapNames[0]]} pistolPositions={this.state.pistolPositions[this.state.mapNames[0]]} ecoPositions={this.state.ecoPositions[this.state.mapNames[0]]} forceBuyPositions={this.state.forceBuyPositions[this.state.mapNames[0]]} fullBuyPositions={this.state.fullBuyPositions[this.state.mapNames[0]]} awpPositions={this.state.awpPositions[this.state.mapNames[0]]} noAwpPositions={this.state.noAwpPositions[this.state.mapNames[0]]} firstFullBuyPositions={this.state.firstFullBuyPositions[this.state.mapNames[0]]} afterPistolWinPositions={this.state.afterPistolWinPositions[this.state.mapNames[0]]} afterPistolLossPositions={this.state.afterPistolLossPositions[this.state.mapNames[0]]} showUtilityNames={this.state.showUtilityNames} showOpponent={this.state.showOpponent} hideBomb={this.state.hideBomb} sliderTime={this.state.sliderTime} hideSmokeGrenades={this.state.hideSmokeGrenades} hideMolotovGrenades={this.state.hideMolotovGrenades} hideFlashbangGrenades={this.state.hideFlashbangGrenades} teamStats={this.state.teamStats} />;
        var map2html = (!this.state.updateMap[this.state.mapNames[1]]) ? this.state.map2html : <MapContainer mapName={this.state.mapNames[1]} teamName={this.state.teamName} allPositions={this.state.allSecondsPositions[this.state.mapNames[1]]} matchIds={this.state.selectedMatches[this.state.mapNames[1]]} pistolPositions={this.state.pistolPositions[this.state.mapNames[1]]} ecoPositions={this.state.ecoPositions[this.state.mapNames[1]]} forceBuyPositions={this.state.forceBuyPositions[this.state.mapNames[1]]} fullBuyPositions={this.state.fullBuyPositions[this.state.mapNames[1]]} awpPositions={this.state.awpPositions[this.state.mapNames[1]]} noAwpPositions={this.state.noAwpPositions[this.state.mapNames[1]]} firstFullBuyPositions={this.state.firstFullBuyPositions[this.state.mapNames[1]]} afterPistolWinPositions={this.state.afterPistolWinPositions[this.state.mapNames[1]]} afterPistolLossPositions={this.state.afterPistolLossPositions[this.state.mapNames[1]]} showUtilityNames={this.state.showUtilityNames} showOpponent={this.state.showOpponent} hideBomb={this.state.hideBomb} sliderTime={this.state.sliderTime} hideSmokeGrenades={this.state.hideSmokeGrenades} hideMolotovGrenades={this.state.hideMolotovGrenades} hideFlashbangGrenades={this.state.hideFlashbangGrenades} teamStats={this.state.teamStats} />;
        var map3html = (!this.state.updateMap[this.state.mapNames[2]]) ? this.state.map3html : <MapContainer mapName={this.state.mapNames[2]} teamName={this.state.teamName} allPositions={this.state.allSecondsPositions[this.state.mapNames[2]]} matchIds={this.state.selectedMatches[this.state.mapNames[2]]} pistolPositions={this.state.pistolPositions[this.state.mapNames[2]]} ecoPositions={this.state.ecoPositions[this.state.mapNames[2]]} forceBuyPositions={this.state.forceBuyPositions[this.state.mapNames[2]]} fullBuyPositions={this.state.fullBuyPositions[this.state.mapNames[2]]} awpPositions={this.state.awpPositions[this.state.mapNames[2]]} noAwpPositions={this.state.noAwpPositions[this.state.mapNames[2]]} firstFullBuyPositions={this.state.firstFullBuyPositions[this.state.mapNames[2]]} afterPistolWinPositions={this.state.afterPistolWinPositions[this.state.mapNames[2]]} afterPistolLossPositions={this.state.afterPistolLossPositions[this.state.mapNames[2]]} showUtilityNames={this.state.showUtilityNames} showOpponent={this.state.showOpponent} hideBomb={this.state.hideBomb} sliderTime={this.state.sliderTime} hideSmokeGrenades={this.state.hideSmokeGrenades} hideMolotovGrenades={this.state.hideMolotovGrenades} hideFlashbangGrenades={this.state.hideFlashbangGrenades} teamStats={this.state.teamStats} />;
        var map4html = (!this.state.updateMap[this.state.mapNames[3]]) ? this.state.map4html : <MapContainer mapName={this.state.mapNames[3]} teamName={this.state.teamName} allPositions={this.state.allSecondsPositions[this.state.mapNames[3]]} matchIds={this.state.selectedMatches[this.state.mapNames[3]]} pistolPositions={this.state.pistolPositions[this.state.mapNames[3]]} ecoPositions={this.state.ecoPositions[this.state.mapNames[3]]} forceBuyPositions={this.state.forceBuyPositions[this.state.mapNames[3]]} fullBuyPositions={this.state.fullBuyPositions[this.state.mapNames[3]]} awpPositions={this.state.awpPositions[this.state.mapNames[3]]} noAwpPositions={this.state.noAwpPositions[this.state.mapNames[3]]} firstFullBuyPositions={this.state.firstFullBuyPositions[this.state.mapNames[3]]} afterPistolWinPositions={this.state.afterPistolWinPositions[this.state.mapNames[3]]} afterPistolLossPositions={this.state.afterPistolLossPositions[this.state.mapNames[3]]} showUtilityNames={this.state.showUtilityNames} showOpponent={this.state.showOpponent} hideBomb={this.state.hideBomb} sliderTime={this.state.sliderTime} hideSmokeGrenades={this.state.hideSmokeGrenades} hideMolotovGrenades={this.state.hideMolotovGrenades} hideFlashbangGrenades={this.state.hideFlashbangGrenades} teamStats={this.state.teamStats} />;
        var map5html = (!this.state.updateMap[this.state.mapNames[4]]) ? this.state.map5html : <MapContainer mapName={this.state.mapNames[4]} teamName={this.state.teamName} allPositions={this.state.allSecondsPositions[this.state.mapNames[4]]} matchIds={this.state.selectedMatches[this.state.mapNames[4]]} pistolPositions={this.state.pistolPositions[this.state.mapNames[4]]} ecoPositions={this.state.ecoPositions[this.state.mapNames[4]]} forceBuyPositions={this.state.forceBuyPositions[this.state.mapNames[4]]} fullBuyPositions={this.state.fullBuyPositions[this.state.mapNames[4]]} awpPositions={this.state.awpPositions[this.state.mapNames[4]]} noAwpPositions={this.state.noAwpPositions[this.state.mapNames[4]]} firstFullBuyPositions={this.state.firstFullBuyPositions[this.state.mapNames[4]]} afterPistolWinPositions={this.state.afterPistolWinPositions[this.state.mapNames[4]]} afterPistolLossPositions={this.state.afterPistolLossPositions[this.state.mapNames[4]]} showUtilityNames={this.state.showUtilityNames} showOpponent={this.state.showOpponent} hideBomb={this.state.hideBomb} sliderTime={this.state.sliderTime} hideSmokeGrenades={this.state.hideSmokeGrenades} hideMolotovGrenades={this.state.hideMolotovGrenades} hideFlashbangGrenades={this.state.hideFlashbangGrenades} teamStats={this.state.teamStats} />;
        var map6html = (!this.state.updateMap[this.state.mapNames[5]]) ? this.state.map6html : <MapContainer mapName={this.state.mapNames[5]} teamName={this.state.teamName} allPositions={this.state.allSecondsPositions[this.state.mapNames[5]]} matchIds={this.state.selectedMatches[this.state.mapNames[5]]} pistolPositions={this.state.pistolPositions[this.state.mapNames[5]]} ecoPositions={this.state.ecoPositions[this.state.mapNames[5]]} forceBuyPositions={this.state.forceBuyPositions[this.state.mapNames[5]]} fullBuyPositions={this.state.fullBuyPositions[this.state.mapNames[5]]} awpPositions={this.state.awpPositions[this.state.mapNames[5]]} noAwpPositions={this.state.noAwpPositions[this.state.mapNames[5]]} firstFullBuyPositions={this.state.firstFullBuyPositions[this.state.mapNames[5]]} afterPistolWinPositions={this.state.afterPistolWinPositions[this.state.mapNames[5]]} afterPistolLossPositions={this.state.afterPistolLossPositions[this.state.mapNames[5]]} showUtilityNames={this.state.showUtilityNames} showOpponent={this.state.showOpponent} hideBomb={this.state.hideBomb} sliderTime={this.state.sliderTime} hideSmokeGrenades={this.state.hideSmokeGrenades} hideMolotovGrenades={this.state.hideMolotovGrenades} hideFlashbangGrenades={this.state.hideFlashbangGrenades} teamStats={this.state.teamStats} />;
        var map7html = (!this.state.updateMap[this.state.mapNames[6]]) ? this.state.map7html : <MapContainer mapName={this.state.mapNames[6]} teamName={this.state.teamName} allPositions={this.state.allSecondsPositions[this.state.mapNames[6]]} matchIds={this.state.selectedMatches[this.state.mapNames[6]]} pistolPositions={this.state.pistolPositions[this.state.mapNames[6]]} ecoPositions={this.state.ecoPositions[this.state.mapNames[6]]} forceBuyPositions={this.state.forceBuyPositions[this.state.mapNames[6]]} fullBuyPositions={this.state.fullBuyPositions[this.state.mapNames[6]]} awpPositions={this.state.awpPositions[this.state.mapNames[6]]} noAwpPositions={this.state.noAwpPositions[this.state.mapNames[6]]} firstFullBuyPositions={this.state.firstFullBuyPositions[this.state.mapNames[6]]} afterPistolWinPositions={this.state.afterPistolWinPositions[this.state.mapNames[6]]} afterPistolLossPositions={this.state.afterPistolLossPositions[this.state.mapNames[6]]} showUtilityNames={this.state.showUtilityNames} showOpponent={this.state.showOpponent} hideBomb={this.state.hideBomb} sliderTime={this.state.sliderTime} hideSmokeGrenades={this.state.hideSmokeGrenades} hideMolotovGrenades={this.state.hideMolotovGrenades} hideFlashbangGrenades={this.state.hideFlashbangGrenades} teamStats={this.state.teamStats} />;
        var playerOverviewHTML = <PlayerPositionOverview positions={this.state.fullBuyPositions} mapNames={this.state.mapNames} wholeTeamMaps={true} allCirclesObject={this.state.allOverviewCircles} playerNames={this.state.playerNames} oldPlayerNames={this.state.oldPlayerNames} awpCount={this.state.awpCount}/>
        this.setState({
            allMapsRetrieved: true,
            map1html: map1html,
            map2html: map2html,
            map3html: map3html,
            map4html: map4html,
            map5html: map5html,
            map6html: map6html,
            map7html: map7html,
            playerOverviewHTML: playerOverviewHTML,
        });
    }

    handleSmokeChange(event) {
        this.setState({ hideSmokeGrenades: !event.target.checked }, () => {
            this.updateRoundSvg();
        });

    }
    handleMolotovChange(event) {
        this.setState({ hideMolotovGrenades: !event.target.checked }, () => {
            this.updateRoundSvg();
        });
    }

    handleFlashbangChange(event) {
        this.setState({ hideFlashbangGrenades: !event.target.checked }, () => {
            this.updateRoundSvg();
        });
    }

    handleBombChange(event) {
        this.setState({ hideBomb: !event.target.checked }, () => {
            this.updateRoundSvg();
        });
    }

    handleUtilityNamesChange(event) {
        this.setState({ showUtilityNames: event.target.checked }, () => {
            if (this.props.path !== "round" && this.props.path !== 'utility') {
                this.updateRoundSvg();
            }
        });
    }

    handleOpponentChange(event) {
        this.setState({ showOpponent: event.target.checked, allMapsRetrieved: false }, () => {
            this.updateRoundSvg();
        });
    }

    handleRoundChangeOnBlur(event) {
        if ((this.state.maxPistolResults !== this.state.maxPistolResultsOld) || (this.state.maxNonPistolResults !== this.state.maxNonPistolResultsOld)) {
            var teamName = this.state.teamName;
            this.setState({ maxPistolResultsOld: this.state.maxPistolResults, maxNonPistolResultsOld: this.state.maxNonPistolResults, allMapsRetrieved: false, teamName: {}, loadingBarPercentage: 0, refreshButton: false }, () => {
                if ("value" in this.state.teamName) {
                    this.displayPositions(teamName.value);
                }
            });
        }
    }

    createOverviewCirclesArray() {
        var positions = this.state.fullBuyPositions;
        var allOverviewCircles = {};
        var awpCount = {};

        for (var i = 0; i < Object.keys(positions).length; i++) {
            var currentMapName = Object.keys(positions)[i];
            for (var k = 0; k < Object.keys(positions[currentMapName]).length; k++) {
                var currentPosition = [];
                var circlesObject = {};

                var currentSecond = Object.keys(positions[currentMapName])[k];
                if (!allOverviewCircles[currentSecond]) {
                    allOverviewCircles[currentSecond] = {};
                }
                if (!allOverviewCircles[currentSecond][currentMapName]) {
                    this.state.mapNames.map((mapName) => {
                        allOverviewCircles[currentSecond][mapName] = {};
                    })
                }

                if (Object.values(positions)[i] && Object.values(positions)[i][currentSecond]) {
                    currentPosition = Object.values(positions)[i][currentSecond];
                } else {
                    currentPosition = []
                }

                for (var j = 0; j < currentPosition.length; j++) {
                    if (this.state.playerNames.some(player => player.name === currentPosition[j].playerName)) {
                        if (currentPosition[j].side === "TERRORIST") {
                            currentPosition[j].side = 'T';
                        }
                        if (!circlesObject[currentPosition[j].playerName]) {
                            circlesObject[currentPosition[j].playerName] = [];
                        }
                        
                        if (!(currentPosition[j].playerName in awpCount)) {
                            awpCount[currentPosition[j].playerName] = 0;
                        }

                        if (currentPosition[j].positionType === "Player") {
                            circlesObject[currentPosition[j].playerName].push(currentPosition[j])
                            if (currentPosition[j].weapon === "AWP") {
                                awpCount[currentPosition[j].playerName] += 1; // Increment AWP count
                            }
                        }
                    }
                }

                allOverviewCircles[currentSecond][currentMapName] = JSON.parse(JSON.stringify(circlesObject));

            }
        }

        this.setState({
            allOverviewCircles: allOverviewCircles,
            awpCount: awpCount
        }, () => {
            this.updateRoundSvg();
        });
    }

    scrollToMap(mapName) {
        var element = document.getElementById(mapName);

        if (element) {
            element.scrollIntoView();
        }
    }

    handleCollapseArrowClick() {
        if (this.state.collapseArrow === "<") {
            this.setState({
                collapseArrow: ">",
                settingsItemsCollapse: "settings-container-collapsed",
                settingsItemsContainerCollapse: "settings-items-container-collapsed",
            }, () => {
                document.getElementsByClassName('home')[0].classList.add("home-collapsed")
            });
        } else {
            this.setState({
                collapseArrow: "<",
                settingsItemsCollapse: "",
                settingsItemsContainerCollapse: "",
            }, () => {
                document.getElementsByClassName('home')[0].classList.remove("home-collapsed")
            });
        }
    }

    createRoundCirclesArray = (positions) => {
        var roundCirclesArray = []
        var roundCirclesArrayAll = {};

        if (positions.length > 0) {
            var side = positions[0].side;
            var currentSecond = 0;
            var currentUtility = [];
            if (side === "TERRORIST") {
                side = 'T';
            }

            for (var i = 0; i < positions.length; i++) {
                if (Math.round(Number(positions[i].roundTime)) === currentSecond) {
                    positions[i].xPos_old = positions[i].xPos;
                    positions[i].yPos_old = positions[i].yPos;
                    positions[i].xPos = adjustCoord(positions[i].xPos, positions[i].zPos, positions[i].mapName, true)
                    positions[i].yPos = adjustCoord(positions[i].yPos, positions[i].zPos, positions[i].mapName, false)

                    if (positions[i].positionType !== "Player" && (positions[i].expired === "false" || positions[i].positionType === "Flashbang")) {
                        currentUtility.push(positions[i])
                    } else if (positions[i].positionType !== "Player" && positions[i].expired === "true") {
                        currentUtility.splice(currentUtility.findIndex(({ entityId }) => entityId == positions[i].entityId), 1);
                    }

                    //If the number isn't an integer that means it was proc'd with utility, only grab utility (wont keep it indefinitely though)
                    if (Number.isInteger(Number(positions[i].roundTime)) && positions[i].positionType === "Player") {
                        roundCirclesArray.push(positions[i])
                    }
                } else {
                    if (roundCirclesArray.length > 0) {
                        roundCirclesArray.sort(function (a, b) {
                            return (a.buy != undefined ? -1 : 0);
                        });
                        for (var j = 0; j < currentUtility.length; j++) {
                            roundCirclesArray.push(currentUtility[j])
                        }

                        roundCirclesArrayAll[currentSecond] = JSON.parse(JSON.stringify(roundCirclesArray))
                        //Remove all flashbangs after a single add
                        for (var j = 0; j < currentUtility.length; j++) {
                            if (currentUtility[j].positionType === "Flashbang") {
                                currentUtility.splice(currentUtility.findIndex(({ entityId }) => entityId == currentUtility[j].entityId), 1);
                            }
                        }
                    }

                    roundCirclesArray.length = 0
                    currentSecond = Math.round(Number(positions[i].roundTime));
                    positions[i].xPos_old = positions[i].xPos;
                    positions[i].yPos_old = positions[i].yPos;
                    positions[i].xPos = adjustCoord(positions[i].xPos, positions[i].zPos, positions[i].mapName, true)
                    positions[i].yPos = adjustCoord(positions[i].yPos, positions[i].zPos, positions[i].mapName, false)


                    if (positions[i].positionType !== "Player" && (positions[i].expired === "false" || positions[i].positionType === "Flashbang")) {
                        currentUtility.push(positions[i])
                    } else if (positions[i].positionType !== "Player" && positions[i].expired === "true") {
                        currentUtility.splice(currentUtility.findIndex(({ entityId }) => entityId == positions[i].entityId), 1);
                    }

                    if (Number.isInteger(Number(positions[i].roundTime)) && positions[i].positionType === "Player") {
                        roundCirclesArray.push(positions[i])
                    }
                }
            }

            if (roundCirclesArray.length > 0) {
                roundCirclesArray.sort(function (a, b) {
                    return (a.buy != undefined ? -1 : 0);
                });

                roundCirclesArrayAll[currentSecond] = JSON.parse(JSON.stringify(roundCirclesArray))
            }
        }

        return roundCirclesArrayAll;
    }

    createUtilityCirclesArray = (positions) => {
        var roundCirclesArray = []
        var roundCirclesArrayAll = [];

        if (positions.length > 0) {
            var currentRound;
            var side = positions[0].side;
            var currentSecond = 0;
            var currentUtility = [];
            if (side === "TERRORIST") {
                side = 'T';
            }

            for (var i = 0; i < positions.length; i++) {
                if (Math.ceil(Number(positions[i].roundTime)) === currentSecond && positions[i].roundNumber === currentRound) {
                    positions[i].xPos_old = positions[i].xPos;
                    positions[i].yPos_old = positions[i].yPos;
                    positions[i].xPos = adjustCoord(positions[i].xPos, positions[i].zPos, positions[i].mapName, true)
                    positions[i].yPos = adjustCoord(positions[i].yPos, positions[i].zPos, positions[i].mapName, false)

                    roundCirclesArray.push(positions[i])
                } else {
                    if (roundCirclesArray.length > 0) {
                        roundCirclesArray.sort(function (a, b) {
                            return (a.positionType == 'Player' ? -1 : 0);
                        });
                        for (var j = 0; j < currentUtility.length; j++) {
                            roundCirclesArray.push(currentUtility[j])
                        }
                        roundCirclesArrayAll.push(JSON.parse(JSON.stringify(roundCirclesArray)))
                    }

                    roundCirclesArray.length = 0
                    currentSecond = Math.round(Number(positions[i].roundTime));
                    currentRound = positions[i].roundNumber;

                    positions[i].xPos_old = positions[i].xPos;
                    positions[i].yPos_old = positions[i].yPos;
                    positions[i].xPos = adjustCoord(positions[i].xPos, positions[i].zPos, positions[i].mapName, true)
                    positions[i].yPos = adjustCoord(positions[i].yPos, positions[i].zPos, positions[i].mapName, false)

                    roundCirclesArray.push(positions[i])

                }
            }

            if (roundCirclesArray.length > 0) {
                roundCirclesArray.sort(function (a, b) {
                    return (a.buy != undefined ? -1 : 0);
                });
                roundCirclesArrayAll.push(JSON.parse(JSON.stringify(roundCirclesArray)))
            }
        }

        return roundCirclesArrayAll;
    }

    handleRoundTimeSlider(event) {
        this.setState({
            roundSliderTime: event.target.value
        });
    }

    render() {
        const {
            players,
            teamName,
            positions,
            hideMolotovGrenades,
            hideSmokeGrenades,
            hideFlashbangGrenades,
            showUtilityNames,
            showOpponent,
            hideBomb,
            sliderTime,
            matchesInfo,
            specificSitutation,
            mapNames,
            specificMapName,
            allMapsRetrieved,
            teamStats,
            collapseArrow,
            settingsItemsCollapse,
            settingsItemsContainerCollapse,
            positionContainerCollapse,
            loadingBarPercentage,
            refreshButton,
            selectedMatches,
            map1html, map2html, map3html, map4html, map5html, map6html, map7html,
            playerOverviewHTML,
            roundSliderTime,
            overallStats,
        } = this.state;

        var teamGeneralInfo;
        var hideLoadingRing = false;
        var mapShortNames = [];
        var mapStats = [];
        var allMatches = {};
        var teamOverallStats = {};

        var logo = require('./images/csgo-scouting-logo-250px.png');
        var utilityRoundTitle;

        switch (this.props.path) {
            case "round":
                break;
            case "utility":
                utilityRoundTitle = this.props.params.teamName + " - " + this.props.params.side + " " +
                    this.props.params.positionType + " - " + this.props.params.mapName.charAt(3).toUpperCase() +
                    this.props.params.mapName.slice(4) + " (X: " + Math.trunc(this.props.params.xPos) + " Y: " + Math.trunc(this.props.params.yPos) + " Z: " + Math.trunc(this.props.params.zPos) + ")";
                break;
            default:
                break;
        }

        if (teamName.value === undefined) {
            hideLoadingRing = true
        } else {
            hideLoadingRing = false
        }

        var loadingBarPercentString = String(loadingBarPercentage) + "%";
        var loadingBar = <div className="loading-bar-container"><div className="loading-bar-text">{loadingBarPercentString}</div>
            <div className="loading-bar-bar" style={{ "width": loadingBarPercentString }}></div>
        </div>

        var refreshButtonHtml = "";
        if (refreshButton) {
            refreshButtonHtml = <div className="refresh-container"><div>Taking longer than usual... </div><button className="btn btn-primary refresh-button" onClick={() => window.location.reload(true)}>Try Refreshing</button></div>
        } else {
            refreshButtonHtml = "";
        }

        var loadingRing = <div className="lds-ring-container"><div className="lds-ring">
            <div></div><div></div><div></div><div></div>
        </div>{loadingBar}{refreshButtonHtml}</div>

        if (allMapsRetrieved) {
            teamGeneralInfo = <TeamGeneralInfo teamName={teamName.value} matchesInfo={matchesInfo} />

            //Put all the maps from hltv into an object so they can select the matches they want
            for (var i = 0; i < matchesInfo.length; i++) {
                var maps = JSON.parse(matchesInfo[i].maps);
                for (var j = 0; j < maps.length; j++) {
                    if (!maps[j].result) {
                        //If the map has no result skip it
                        continue;
                    }
                    //put all the maps played into the allMatches object
                    if (allMatches[maps[j].name] === undefined) {
                        allMatches[maps[j].name] = [];
                    }

                    allMatches[maps[j].name].push({
                        team1: matchesInfo[i].team1Name,
                        team2: matchesInfo[i].team2Name,
                        event: matchesInfo[i].event,
                        date: matchesInfo[i].date,
                        matchId: matchesInfo[i].matchId,
                        team1Score: maps[j].result.team1TotalRounds,
                        team2Score: maps[j].result.team2TotalRounds,
                        mapName: maps[j].name,
                    })
                }
            }
            //Build out each of the map stats into their own section
            for (var i = 0; i < mapNames.length; i++) {
                var bombPlantSiteLean = 0;
                var AorB = "A";
                var mapObject = {};

                if (teamStats[mapNames[i]]) {
                    if (teamStats[mapNames[i]].bombPlantsA > teamStats[mapNames[i]].bombPlantsB) {
                        bombPlantSiteLean = teamStats[mapNames[i]].bombPlantsA / teamStats[mapNames[i]].bombPlantsTotal * 100
                        AorB = "A"
                    } else if (teamStats[mapNames[i]].bombPlantsA === teamStats[mapNames[i]].bombPlantsB) {
                        bombPlantSiteLean = 0.5 * 100
                        AorB = "A or B"
                    } else {
                        bombPlantSiteLean = teamStats[mapNames[i]].bombPlantsB / teamStats[mapNames[i]].bombPlantsTotal * 100
                        AorB = "B"
                    }

                    mapObject.mapName = mapNames[i].charAt(3).toUpperCase() + mapNames[i].slice(4);
                    mapObject.bombPlant = teamStats[mapNames[i]].timeToPlant.toFixed(1);
                    mapObject.bombPlantLean = bombPlantSiteLean.toFixed(1);
                    mapObject.timeToPlantA = teamStats[mapNames[i]].timeToPlantA.toFixed(1);
                    mapObject.timeToPlantB = teamStats[mapNames[i]].timeToPlantB.toFixed(1);
                    mapObject.numOfMatches = teamStats[mapNames[i]].numOfMatches;
                    mapObject.pistolWinPercent = Number(teamStats[mapNames[i]].pistolWinPercent * 100).toFixed(1);
                    mapObject.pistolCTWinPercent = Number(teamStats[mapNames[i]].pistolCTWinPercent * 100).toFixed(1);
                    mapObject.pistolTWinPercent = Number(teamStats[mapNames[i]].pistolTWinPercent * 100).toFixed(1);
                    mapObject.winVsEcoPercent = Number(teamStats[mapNames[i]].winVsEcoPercent * 100).toFixed(1);
                    mapObject.winVsEcoCTPercent = Number(teamStats[mapNames[i]].winVsEcoCTPercent * 100).toFixed(1);
                    mapObject.winVsEcoTPercent = Number(teamStats[mapNames[i]].winVsEcoTPercent * 100).toFixed(1);
                    mapObject.fullBuyWinPercent = Number(teamStats[mapNames[i]].fullBuyWinPercent * 100).toFixed(1);
                    mapObject.fullBuyCTWinPercent = Number(teamStats[mapNames[i]].fullBuyCTWinPercent * 100).toFixed(1);
                    mapObject.fullBuyTWinPercent = Number(teamStats[mapNames[i]].fullBuyTWinPercent * 100).toFixed(1);
                    mapObject.winEcoVsFullBuyPercent = Number(teamStats[mapNames[i]].winEcoVsFullBuyPercent * 100).toFixed(1);
                    mapObject.winEcoVsFullBuyCTPercent = Number(teamStats[mapNames[i]].winEcoVsFullBuyCTPercent * 100).toFixed(1);
                    mapObject.winEcoVsFullBuyTPercent = Number(teamStats[mapNames[i]].winEcoVsFullBuyTPercent * 100).toFixed(1);
                    mapObject.winVsForceBuyPercent = Number(teamStats[mapNames[i]].winVsForceBuyPercent * 100).toFixed(1);
                    mapObject.winVsForceBuyCTPercent = Number(teamStats[mapNames[i]].winVsForceBuyCTPercent * 100).toFixed(1);
                    mapObject.winVsForceBuyTPercent = Number(teamStats[mapNames[i]].winVsForceBuyTPercent * 100).toFixed(1);
                    mapObject.winForceBuyVsFullBuyPercent = Number(teamStats[mapNames[i]].winForceBuyVsFullBuyPercent * 100).toFixed(1);
                    mapObject.winForceBuyVsFullBuyCTPercent = Number(teamStats[mapNames[i]].winForceBuyVsFullBuyCTPercent * 100).toFixed(1);
                    mapObject.winForceBuyVsFullBuyTPercent = Number(teamStats[mapNames[i]].winForceBuyVsFullBuyTPercent * 100).toFixed(1);
                    mapObject.AorB = AorB;
                    mapStats.push(mapObject)
                } else {
                    mapObject.mapName = mapNames[i].charAt(3).toUpperCase() + mapNames[i].slice(4);
                    mapObject.bombPlant = 0;
                    mapObject.bombPlantLean = 50;
                    mapObject.timeToPlantA = 0;
                    mapObject.timeToPlantB = 0;
                    mapObject.numOfMatches = 0;
                    mapObject.pistolWinPercent = 0;
                    mapObject.pistolCTWinPercent = 0;
                    mapObject.pistolTWinPercent = 0;
                    mapObject.winVsEcoPercent = 0;
                    mapObject.winVsEcoCTPercent = 0;
                    mapObject.winVsEcoTPercent = 0;
                    mapObject.fullBuyWinPercent = 0;
                    mapObject.fullBuyCTWinPercent = 0;
                    mapObject.fullBuyTWinPercent = 0;
                    mapObject.winEcoVsFullBuyPercent = 0;
                    mapObject.winEcoVsFullBuyCTPercent = 0;
                    mapObject.winEcoVsFullBuyTPercent = 0;
                    mapObject.winVsForceBuyPercent = 0;
                    mapObject.winVsForceBuyCTPercent = 0;
                    mapObject.winVsForceBuyTPercent = 0;
                    mapObject.winForceBuyVsFullBuyPercent = 0;
                    mapObject.winForceBuyVsFullBuyCTPercent = 0;
                    mapObject.winForceBuyVsFullBuyTPercent = 0;
                    mapObject.AorB = "A or B";
                    mapStats.push(mapObject)
                }
            }

            teamOverallStats.mapName = "Overall";
            teamOverallStats.pistolWinPercent = Number(teamStats['overall'].pistolWinPercent * 100).toFixed(1);
            teamOverallStats.pistolCTWinPercent = Number(teamStats['overall'].pistolCTWinPercent * 100).toFixed(1);
            teamOverallStats.pistolTWinPercent = Number(teamStats['overall'].pistolTWinPercent * 100).toFixed(1);
            teamOverallStats.winVsEcoPercent = Number(teamStats['overall'].winVsEcoPercent * 100).toFixed(1);
            teamOverallStats.winVsEcoCTPercent = Number(teamStats['overall'].winVsEcoCTPercent * 100).toFixed(1);
            teamOverallStats.winVsEcoTPercent = Number(teamStats['overall'].winVsEcoTPercent * 100).toFixed(1);
            teamOverallStats.fullBuyWinPercent = Number(teamStats['overall'].fullBuyWinPercent * 100).toFixed(1);
            teamOverallStats.fullBuyCTWinPercent = Number(teamStats['overall'].fullBuyCTWinPercent * 100).toFixed(1);
            teamOverallStats.fullBuyTWinPercent = Number(teamStats['overall'].fullBuyTWinPercent * 100).toFixed(1);
            teamOverallStats.winEcoVsFullBuyPercent = Number(teamStats['overall'].winEcoVsFullBuyPercent * 100).toFixed(1);
            teamOverallStats.winEcoVsFullBuyCTPercent = Number(teamStats['overall'].winEcoVsFullBuyCTPercent * 100).toFixed(1);
            teamOverallStats.winEcoVsFullBuyTPercent = Number(teamStats['overall'].winEcoVsFullBuyTPercent * 100).toFixed(1);
            teamOverallStats.winVsForceBuyPercent = Number(teamStats['overall'].winVsForceBuyPercent * 100).toFixed(1);
            teamOverallStats.winVsForceBuyCTPercent = Number(teamStats['overall'].winVsForceBuyCTPercent * 100).toFixed(1);
            teamOverallStats.winVsForceBuyTPercent = Number(teamStats['overall'].winVsForceBuyTPercent * 100).toFixed(1);
            teamOverallStats.winForceBuyVsFullBuyPercent = Number(teamStats['overall'].winForceBuyVsFullBuyPercent * 100).toFixed(1);
            teamOverallStats.winForceBuyVsFullBuyCTPercent = Number(teamStats['overall'].winForceBuyVsFullBuyCTPercent * 100).toFixed(1);
            teamOverallStats.winForceBuyVsFullBuyTPercent = Number(teamStats['overall'].winForceBuyVsFullBuyTPercent * 100).toFixed(1);

            hideLoadingRing = true;
        } else {
            if (teamName.value === undefined) {
                hideLoadingRing = true
            } else {
                hideLoadingRing = false
            }
        }

        mapNames.map((value, index) => {
            var map = value.replace('de_', '');
            map = map.charAt(0).toUpperCase() + map.slice(1);
            mapShortNames.push(map);
        })

        if (teamOverallStats.pistolWinPercent !== undefined) {
            // console.log('here')
        }

        var overallRadarData = [
            {
                subject: "Win vs Pistol",
                A: (overallStats.overall?.pistolWinPercent * 100).toFixed(1),
                B: teamOverallStats.pistolWinPercent,
                fullMark: 100
            }, {
                subject: "Win vs Eco",
                A: (overallStats.overall?.winVsEcoPercent * 100).toFixed(1),
                B: teamOverallStats.winVsEcoPercent,
                fullMark: 100
            }, {
                subject: "Win vs Force",
                A: (overallStats.overall?.winVsForceBuyPercent * 100).toFixed(1),
                B: teamOverallStats.winVsForceBuyPercent,
                fullMark: 100
            }, {
                subject: "Win vs Full",
                A: (overallStats.overall?.fullBuyWinPercent * 100).toFixed(1),
                B: teamOverallStats.fullBuyWinPercent,
                fullMark: 100
            }, {
                subject: "Eco vs Full",
                A: (overallStats.overall?.winEcoVsFullBuyPercent * 100).toFixed(1),
                B: teamOverallStats.winEcoVsFullBuyPercent,
                fullMark: 100
            }, {
                subject: "Force vs Full",
                A: (overallStats.overall?.winForceBuyVsFullBuyPercent * 100).toFixed(1),
                B: teamOverallStats.winForceBuyVsFullBuyPercent,
                fullMark: 100
            },
        ]

        //for each map in mapStats, turn this into the above radar data format
        var winPistolData = [{
            name: "Overall",
            "Top Teams": (overallStats.overall?.pistolWinPercent * 100).toFixed(1),
            [teamName.value]: teamOverallStats.pistolWinPercent,
        }];
        var winVsEcoData = [{
            name: "Overall",
            "Top Teams": (overallStats.overall?.winVsEcoPercent * 100).toFixed(1),
            [teamName.value]: teamOverallStats.winVsEcoPercent,
        }];
        var winVsForceData = [{
            name: "Overall",
            "Top Teams": (overallStats.overall?.winVsForceBuyPercent * 100).toFixed(1),
            [teamName.value]: teamOverallStats.winVsForceBuyPercent,
        }];
        var winVsFullData = [{
            name: "Overall",
            "Top Teams": (overallStats.overall?.fullBuyWinPercent * 100).toFixed(1),
            [teamName.value]: teamOverallStats.fullBuyWinPercent,
        }];
        var winEcoVsFullData = [{
            name: "Overall",
            "Top Teams": (overallStats.overall?.winEcoVsFullBuyPercent * 100).toFixed(1),
            [teamName.value]: teamOverallStats.winEcoVsFullBuyPercent,
        }];
        var winForceVsFullData = [{
            name: "Overall",
            "Top Teams": (overallStats.overall?.winForceBuyVsFullBuyPercent * 100).toFixed(1),
            [teamName.value]: teamOverallStats.winForceBuyVsFullBuyPercent,
        }];
        mapStats.map((value, index) => {
            winPistolData.push({
                name: value.mapName,
                "Top Teams": (overallStats.overall?.pistolWinPercent * 100).toFixed(1),
                [teamName.value]: value.pistolWinPercent,
            })
            winVsEcoData.push({
                name: value.mapName,
                "Top Teams": (overallStats.overall?.winVsEcoPercent * 100).toFixed(1),
                [teamName.value]: value.winVsEcoPercent,
            })
            winVsForceData.push({
                name: value.mapName,
                "Top Teams": (overallStats.overall?.winVsForceBuyPercent * 100).toFixed(1),
                [teamName.value]: value.winVsForceBuyPercent,
            })
            winVsFullData.push({
                name: value.mapName,
                "Top Teams": (overallStats.overall?.fullBuyWinPercent * 100).toFixed(1),
                [teamName.value]: value.fullBuyWinPercent,
            })
            winEcoVsFullData.push({
                name: value.mapName,
                "Top Teams": (overallStats.overall?.winEcoVsFullBuyPercent * 100).toFixed(1),
                [teamName.value]: value.winEcoVsFullBuyPercent,
            })
            winForceVsFullData.push({
                name: value.mapName,
                "Top Teams": (overallStats.overall?.winForceBuyVsFullBuyPercent * 100).toFixed(1),
                [teamName.value]: value.winForceBuyVsFullBuyPercent,
            })
        })

        var winVsData = [
            {
                name: "Pistol Win %",
                data: winPistolData,
            }, {
                name: "Win vs Eco %",
                data: winVsEcoData,
            }, {
                name: "Win vs Force %",
                data: winVsForceData,
            }, {
                name: "Win vs Full Buy %",
                data: winVsFullData,
            }, {
                name: "Eco vs Full Buy %",
                data: winEcoVsFullData,
            }, {
                name: "Force vs Full Buy %",
                data: winForceVsFullData,
            }
        ];

        return (
            <React.Fragment>
                <div className={"settings-container flex-vertical " + settingsItemsCollapse}>
                    <div className="settings-collapse-arrow" onClick={this.handleCollapseArrowClick}>{collapseArrow}</div>
                    <div className={"settings-items-container flex-vertical " + settingsItemsContainerCollapse}>
                        <div className="nav-title flex">
                            <a href="/scouting" className="setting-logo">
                                <img src={logo} alt="logo" />
                            </a>
                        </div>
                        <hr className="settings-hr settings-hr-first" />
                        <div className="settings-select-container">
                            <hr className="settings-hr" />
                            <MapSizeSlider />
                        </div>
                        <Logout />
                    </div>
                </div>
                {!this.props.path ?
                    <div className={"position-container flex-one " + positionContainerCollapse}>

                        {hideLoadingRing ? '' : loadingRing}
                        <Tabs forceRenderTabPanel={true} >
                            <TabList className="map-tab-list flex">
                                <Tab>Overview</Tab>
                                <Tab>{mapShortNames[0]}</Tab>
                                <Tab>{mapShortNames[1]}</Tab>
                                <Tab>{mapShortNames[2]}</Tab>
                                <Tab>{mapShortNames[3]}</Tab>
                                <Tab>{mapShortNames[4]}</Tab>
                                <Tab>{mapShortNames[5]}</Tab>
                                <Tab>{mapShortNames[6]}</Tab>
                                <Tab>Stats</Tab>
                                <Tab>Roles</Tab>
                            </TabList>
                            <TabPanel>
                                {playerOverviewHTML}
                            </TabPanel>
                            <TabPanel>
                                {<SelectMatches mapName={mapNames[0]} matchesInfo={allMatches[mapNames[0]]} teamName={teamName} selectedMatches={selectedMatches[mapNames[0]]} handleMatchSelectChange={this.handleMatchSelectChange} />}
                                {map1html}
                            </TabPanel>
                            <TabPanel>
                                {<SelectMatches mapName={mapNames[1]} matchesInfo={allMatches[mapNames[1]]} teamName={teamName} selectedMatches={selectedMatches[mapNames[1]]} handleMatchSelectChange={this.handleMatchSelectChange} />}
                                {map2html}
                            </TabPanel>
                            <TabPanel>
                                {<SelectMatches mapName={mapNames[2]} matchesInfo={allMatches[mapNames[2]]} teamName={teamName} selectedMatches={selectedMatches[mapNames[2]]} handleMatchSelectChange={this.handleMatchSelectChange} />}
                                {map3html}
                            </TabPanel>
                            <TabPanel>
                                {<SelectMatches mapName={mapNames[3]} matchesInfo={allMatches[mapNames[3]]} teamName={teamName} selectedMatches={selectedMatches[mapNames[3]]} handleMatchSelectChange={this.handleMatchSelectChange} />}
                                {map4html}
                            </TabPanel>
                            <TabPanel>
                                {<SelectMatches mapName={mapNames[4]} matchesInfo={allMatches[mapNames[4]]} teamName={teamName} selectedMatches={selectedMatches[mapNames[4]]} handleMatchSelectChange={this.handleMatchSelectChange} />}
                                {map5html}
                            </TabPanel>
                            <TabPanel>
                                {<SelectMatches mapName={mapNames[5]} matchesInfo={allMatches[mapNames[5]]} teamName={teamName} selectedMatches={selectedMatches[mapNames[5]]} handleMatchSelectChange={this.handleMatchSelectChange} />}
                                {map6html}
                            </TabPanel>
                            <TabPanel>
                                {<SelectMatches mapName={mapNames[6]} matchesInfo={allMatches[mapNames[6]]} teamName={teamName} selectedMatches={selectedMatches[mapNames[6]]} handleMatchSelectChange={this.handleMatchSelectChange} />}
                                {map7html}
                            </TabPanel>
                            <TabPanel>
                                <div className="team-stats-container flex-vertical">
                                    {teamGeneralInfo}
                                    <div className="stats-graphs-container flex">
                                        {winVsData.map((vsData, index) => (
                                            <div key={"map-stats-" + index} className="map-stats-container">
                                                <div className="map-stats-header">{vsData.name}</div>
                                                <ResponsiveContainer width="95%" height={400}>
                                                    <BarChart
                                                        width={600}
                                                        height={300}
                                                        data={vsData.data}
                                                        margin={{
                                                            top: 0,
                                                            right: 15,
                                                            left: 0,
                                                            bottom: 50,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="name" textAnchor="end" fontSize={12} />
                                                        <YAxis domain={[0, 100]} />
                                                        <Tooltip />
                                                        <Legend />
                                                        <Bar dataKey={teamName.value} fill="#82ca9d" />
                                                        <Bar dataKey="Top Teams" fill="#8884d8" />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        ))}

                                    </div>

                                </div>

                            </TabPanel>
                            <TabPanel>
                                <RolesTable rolesInfo={this.state.rolesInfo} mapNames={this.state.mapNames} playerNames={this.state.playerNames} oldPlayerNames={this.state.oldPlayerNames} />
                            </TabPanel>
                        </Tabs>
                    </div>
                    : <div className={"utility-round-container" + (this.props.path === 'round' ? " round-specific-container" : '')}>
                        <PlayerPositionInfo positions={(this.props.path === 'round') ? positions : positions} teamName={teamName} mapName={specificMapName} second={roundSliderTime} singlePlayer={false} showUtilityNames={showUtilityNames} hideBomb={hideBomb} matchesInfo={matchesInfo} path={this.props.path} teamStats={teamStats} hideSmokeGrenades={this.state.hideSmokeGrenades} hideMolotovGrenades={this.state.hideMolotovGrenades} hideFlashbangGrenades={this.state.hideFlashbangGrenades} handleUtilityNamesChange={this.handleUtilityNamesChange} />
                    </div>
                }
            </React.Fragment>
        );
    }
}