import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";

export class LogoutUrl extends Component {
    
  componentDidMount() {
    this.logout();
  }

  async logout() {
    await signOut();
    localStorage.removeItem("user");
    localStorage.removeItem("userEmail");
    window.location.href = "/";
    }

  render() {
    return (<div></div>);
  }
}