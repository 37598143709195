const callApiNoCache = async (url) => {
    var apiUrl = '';

    switch (process.env.NODE_ENV) {
        case 'production':
            apiUrl = process.env.REACT_APP_AWS_PROD_URL_NO_CACHE;
            break;
        case 'development':
            apiUrl = process.env.REACT_APP_AWS_DEV_URL_NO_CACHE;
        default:
    }

    const response = await fetch(apiUrl + url);
    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body;
};

export { callApiNoCache };