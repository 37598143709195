import logo from './logo.svg';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

function Signup(props) {
    const { priceId } = useParams();
    var apiUrl = '';
    var returnUrl = window.location.protocol + '//' + window.location.host + '/scouting';
    var email = '';
    if (localStorage.getItem('userEmail')) {
        email = localStorage.getItem('userEmail')
    }

    switch (process.env.NODE_ENV) {
        case 'production':
          apiUrl = process.env.REACT_APP_API_HOST_URL
          break;
        case 'development':
          apiUrl = process.env.REACT_APP_API_DEV_URL
        default:
      }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ priceId: priceId, returnUrl: returnUrl, email: email })
    };

    // Send data to the backend via POST
    fetch(apiUrl + 'create-checkout-session', requestOptions)
        .then(response => response.json())
        .then((response) => {
            localStorage.removeItem('user');
            window.location.href = response.url;

        })
        .catch((error) => {
            console.error(error);
        })

    return (
        <div className="App"></div>
    );
}

export default Signup;

