import React, { Component } from "react";
// import './SupportPage.css';
import patreonLogo from './images/patreon.png';
import steamLogo from './images/steam.svg';
import paypalLogo from './images/paypal.png';
import buymeacoffeeLogo from './images/buymeacoffee.svg';

export class SupportPage extends Component {
    render() {
        var logo = require('./images/csgo-scouting-logo-250px.png');

        return (
            <div className="flex-vertical landing-page-container">
                <div className="flex-vertical landing-main-container">
                    <div className="flex landing-nav-container">
                        <a href="/scouting" className="setting-logo-home landing-nav-item">
                            <img src={logo} alt="logo" />
                        </a>
                        <div className="flex landing-nav-item nav-login"><a href="/scouting">Contact</a></div>
                        <div className="flex landing-nav-item nav-signup"><a href="/scouting">Sign up</a></div>
                    </div>
                    <h1 className="support-page-title">Thank you for considering supporting this CSGO Scouting application!</h1>
                    <p className="support-page-subtitle"> It has taken me over 1,000 hours of programming time and cost me a few thousand dollars already and continues to cost ~$100 a month to run. Any support is greatly appreciated. Please consider supporting us through one of the following links:</p>
                    <div className="support-page-links-container flex">
                        <div className="flex-one">
                            <a href="https://www.patreon.com/C9WIN" target="_blank" className="support-page-link flex">
                                <img src={patreonLogo} alt="Patreon" className="support-page-logo" />
                                <span className="support-page-link-text"></span>
                            </a>
                        </div>
                        <div className="flex-one">
                            <a href="https://steamcommunity.com/tradeoffer/new/?partner=7035242&token=YwM-IfZN" target="_blank" className="support-page-link flex">
                                <img src={steamLogo} alt="Steam" className="support-page-logo" />
                                <span className="support-page-link-text"></span>
                            </a>
                        </div>
                        <div className="flex-one">
                            <a href="https://www.paypal.com/donate/?business=RXCNH23TYZL7A&no_recurring=0&currency_code=USD" target="_blank" className="support-page-link flex">
                                <img src={paypalLogo} alt="PayPal" className="support-page-logo" />
                                <span className="support-page-link-text"></span>
                            </a>
                        </div>
                        <div className="flex-one">
                            <a href="https://www.buymeacoffee.com/c9win" target="_blank" className="support-page-link flex">
                                <img src={buymeacoffeeLogo} alt="Buy Me a Coffee" className="support-page-logo" />
                                <span className="support-page-link-text"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}