import React, { Component } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import ReactGA from 'react-ga4';

export class PaymentLandingPage extends Component {

    constructor(props) {
        super(props);

        let footers = [
            {
                title: 'Contact',
                description: [{ link: 'https://twitter.com/cloud9_win', desc: '@Cloud9_Win' }, { link: 'mailto:clou9win@gmail.com', desc: 'cloud9win@gmail.com' }],
            },
            {
                title: 'Legal',
                description: [{ link: 'https://twitter.com/cloud9_win', desc: 'Privacy policy' }, { link: 'mailto:clou9win@gmail.com', desc: 'Terms of use' }],
            },
        ];

        this.state = {
            label: "Yearly Price",
            tiersYearly: [],
            tiersMonthly: [],
            tiers: [],
            footers: footers,
            priceId: '',
            apiUrl: '',

        }

        this.handlePricingChange = this.handlePricingChange.bind(this);
        this.customerCheckoutClick = this.customerCheckoutClick.bind(this);
    }

    componentDidMount() {
        var priceId;
        var apiUrl;
        var smallMonthlyPrice;
        var mediumMonthlyPrice;
        var largeMonthlyPrice;
        var smallYearlyPrice;
        var mediumYearlyPrice;
        var largeYearlyPrice;
        switch (process.env.NODE_ENV) {
            case 'production':
                apiUrl = process.env.REACT_APP_API_HOST_URL
                smallMonthlyPrice = process.env.REACT_APP_STRIPE_PROD_SMALL_MONTHLY_PRICE_ID
                mediumMonthlyPrice = process.env.REACT_APP_STRIPE_PROD_MEDIUM_MONTHLY_PRICE_ID
                largeMonthlyPrice = process.env.REACT_APP_STRIPE_PROD_LARGE_MONTHLY_PRICE_ID
                smallYearlyPrice = process.env.REACT_APP_STRIPE_PROD_SMALL_YEARLY_PRICE_ID
                mediumYearlyPrice = process.env.REACT_APP_STRIPE_PROD_MEDIUM_YEARLY_PRICE_ID
                largeYearlyPrice = process.env.REACT_APP_STRIPE_PROD_LARGE_YEARLY_PRICE_ID
                break;
            case 'development':
                apiUrl = process.env.REACT_APP_API_DEV_URL
                smallMonthlyPrice = process.env.REACT_APP_STRIPE_DEV_SMALL_MONTHLY_PRICE_ID
                mediumMonthlyPrice = process.env.REACT_APP_STRIPE_DEV_MEDIUM_MONTHLY_PRICE_ID
                largeMonthlyPrice = process.env.REACT_APP_STRIPE_DEV_LARGE_MONTHLY_PRICE_ID
                smallYearlyPrice = process.env.REACT_APP_STRIPE_DEV_SMALL_YEARLY_PRICE_ID
                mediumYearlyPrice = process.env.REACT_APP_STRIPE_DEV_MEDIUM_YEARLY_PRICE_ID
                largeYearlyPrice = process.env.REACT_APP_STRIPE_DEV_LARGE_YEARLY_PRICE_ID
            default:
        }

        let tiersYearly = [
            {
                title: 'Small Creator',
                price: '4',
                description: [
                    '~10 reports a month',
                    'Less than 1,000 followers',
                    'Positional data for top 100 teams',
                    'Utility aggregation',
                    'Side by side player comparison',
                ],
                buttonText: 'Get started',
                buttonVariant: 'contained',
                priceId: smallYearlyPrice,
            },
            {
                title: 'Media, Creator, Analyst',
                subheader: 'Most popular',
                price: '9',
                description: [
                    '~25 reports a month',
                    'Positional data for top 100 teams',
                    'Utility aggregation',
                    'Side by side player comparison',
                    'Updated data daily',
                ],
                buttonText: 'Get started',
                buttonVariant: 'contained',
                priceId: mediumYearlyPrice,
            },
            {
                title: 'Pro Coach/Scout/Player',
                price: '19',
                description: [
                    'Unlimited reports',
                    'Positional data for top 100 teams',
                    'Utility aggregation',
                    'Side by side player comparison',
                    'Updated data daily',
                ],
                buttonText: 'Get started',
                buttonVariant: 'contained',
                priceId: largeYearlyPrice,
            }
        ]
        let tiersMonthly = [
            {
                title: 'Small Creator',
                price: '6',
                description: [
                    '~10 reports a month',
                    'Less than 1,000 followers',
                    'Positional data for top 100 teams',
                    'Utility aggregation',
                    'Side by side player comparison',
                ],
                buttonText: 'Get started',
                buttonVariant: 'contained',
                priceId: smallMonthlyPrice,
            },
            {
                title: 'Media, Creator, Analyst',
                subheader: 'Most popular',
                price: '12',
                description: [
                    '~25 reports a month',
                    'Positional data for top 100 teams',
                    'Utility aggregation',
                    'Side by side player comparison',
                    'Updated data daily',
                ],
                buttonText: 'Get started',
                buttonVariant: 'contained',
                priceId: mediumMonthlyPrice,
            },
            {
                title: 'Pro Coach/Scout/Player',
                price: '25',
                description: [
                    'Unlimited reports',
                    'Positional data for top 100 teams',
                    'Utility aggregation',
                    'Side by side player comparison',
                    'Updated data daily',
                ],
                buttonText: 'Get started',
                buttonVariant: 'contained',
                priceId: largeMonthlyPrice,
            }
        ];

        this.setState({
            // priceId: priceId, 
            apiUrl: apiUrl,
            tiersMonthly: tiersMonthly,
            tiersYearly: tiersYearly,
            tiers: tiersYearly
        })
    }

    customerCheckoutClick(priceId) {
        var email = '';
        ReactGA.initialize('G-50PWPY1T1Q');
        ReactGA.event({
            category: 'Checkout',
            action: 'Clicked Checkout Button',
            label: 'Checkout Price ID: ' + priceId
        });
        if (localStorage.getItem('userEmail')) {
            email = localStorage.getItem('userEmail')
        } else {
            window.location.href = '/signup/' + priceId;
            return
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ priceId: priceId, returnUrl: window.location.href, email: email })
        };

        // Send data to the backend via POST
        fetch(this.state.apiUrl + 'create-checkout-session', requestOptions)
            .then(response => response.json())
            .then((response) => {
                localStorage.removeItem('user');
                window.location.href = response.url;

            })
            .catch((error) => {
                console.error(error);
            })
    }

    Copyright(props) {
        return (
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="https://mui.com/">
                    Your Website
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }

    handlePricingChange(res) {
        if (res.target.checked) {
            this.setState({ tiers: this.state.tiersYearly })
        } else {
            this.setState({ tiers: this.state.tiersMonthly })
        }
    }

    async logoutClicked() {
        await signOut();
        localStorage.removeItem("user");
        localStorage.removeItem("userEmail");
        window.location.href = "/";
    }

    render() {
        const { label, tiers, footers } = this.state;
        var logoutButton = '';
        var logo = require('./images/csgo-scouting-logo-250px.png');
        if (localStorage.getItem('user')) {
            logoutButton = <div className="logout-button" onClick={this.logoutClicked}>
                Logout
            </div>
        }
        return (
            <React.Fragment>
                <div className="pricing-container">
                    <div className="pricing-header flex">
                        <a href="/" className="pricing-logo">
                            <img src={logo} alt="logo" />
                        </a>
                        <div className="pricing-logout-container">
                            {logoutButton}
                        </div>
                    </div>
                    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                    <CssBaseline />
                    {/* Hero unit */}
                    <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            gutterBottom
                        >
                            Pricing
                        </Typography>
                        <Typography variant="h5" align="center" component="p">
                            Thank you for your interest in CSGO Scouting. Please select a plan below to get started.
                        </Typography>
                    </Container>
                    {/* End hero unit */}
                    <Container maxWidth="lg" component="main" alignItems="center" noWrap>
                        <FormGroup alignItems="center" style={{ justifyContent: 'center' }}>
                            <FormControlLabel className="pricing-slider" style={{ justifyContent: 'center' }} control={<Switch defaultChecked />} label={label} onChange={this.handlePricingChange} />
                        </FormGroup>
                        <Grid container spacing={5} alignItems="center" style={{ justifyContent: 'center' }} wrap="nowrap">
                            {tiers.map((tier) => (
                                // Enterprise card is full width at sm breakpoint
                                <Grid
                                    item
                                    key={tier.title}
                                    xs={12}
                                    sm={tier.title === 'Enterprise' ? 12 : 6}
                                    md={5}
                                    alignItems="center"
                                    align="center"
                                    justify="center"
                                >
                                    <Card alignItems="center" align="center">
                                        <CardHeader
                                            title={tier.title}
                                            subheader={tier.subheader}
                                            titleTypographyProps={{ align: 'center' }}
                                            //   action={tier.title === 'Pro' ? <StarIcon /> : null}
                                            subheaderTypographyProps={{
                                                align: 'center',
                                            }}
                                            alignItems="center"
                                            classes={"pricing-h1"}
                                            sx={{
                                                // backgroundColor: (theme) =>
                                                //     theme.palette.mode === 'light'
                                                //         ? theme.palette.grey[200]
                                                //         : theme.palette.grey[700],
                                                // alignSelf: 'center',
                                            }}
                                        />
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'baseline',
                                                    mb: 2,
                                                    justifySelf: 'center',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                }}
                                            >
                                                <Typography component="h2" variant="h3" color="text.primary">
                                                    ${tier.price}
                                                </Typography>
                                                <Typography variant="h6" color="text.secondary">
                                                    /mo
                                                </Typography>
                                            </Box>
                                            <ul>
                                                {tier.description.map((line) => (
                                                    <Typography
                                                        component="li"
                                                        variant="subtitle1"
                                                        align="center"
                                                        key={line}
                                                    >
                                                        {line}
                                                    </Typography>
                                                ))}
                                            </ul>
                                        </CardContent>
                                        <CardActions>
                                            <Button fullWidth variant={tier.buttonVariant} onClick={() => { this.customerCheckoutClick(tier.priceId) }}>
                                                {tier.buttonText}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                    {/* Footer */}
                    <Container
                        maxWidth="md"
                        component="footer"
                        sx={{
                            borderTop: (theme) => `1px solid white`,
                            mt: 8,
                            py: [3, 6],
                        }}
                    >
                        <Grid container spacing={4} justifyContent="space-evenly">
                            {footers.map((footer) => (
                                <Grid item xs={6} sm={3} key={footer.title}>
                                    <Typography variant="h6" gutterBottom>
                                        {footer.title}
                                    </Typography>
                                    <ul>
                                        {footer.description.map((item) => (
                                            <li key={item}>
                                                <Link href={item.link} variant="subtitle1">
                                                    {item.desc}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                            ))}
                        </Grid>
                        {/* <Copyright sx={{ mt: 5 }} /> */}
                    </Container>
                </div>
                {/* End footer */}
            </React.Fragment>
        );
    }
}