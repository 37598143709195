import React, { Component } from "react";

export class RoundSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderValue: "15",
            finalSliderValue: "15",
            stepSize: (this.props.stepSize) ? this.props.stepSize : "5",
            max: (this.props.max) ? this.props.max : "155",
            min: (this.props.min) ? this.props.min : "5",
            customClass: "",
        }

        this.handleRoundTimeSlider = this.handleRoundTimeSlider.bind(this);
    }

    componentDidMount() {
        if (this.props.sliderValue !== this.state.sliderValue) {
            var stepSize = (this.props.stepSize) ? this.props.stepSize : "5";
            var max = (this.props.max) ? this.props.max : "155";
            this.setState({ sliderValue: this.props.sliderValue, sliderName: this.props.sliderName, stepSize: stepSize, max: max, customClass: this.props.customClass });
        }
    }

    componentDidUpdate() {
        if (this.props.sliderValue !== this.state.sliderValue) {
            var stepSize = (this.props.stepSize) ? this.props.stepSize : "5";
            var max = (this.props.max) ? this.props.max : "155";
            this.setState({ sliderValue: this.props.sliderValue, sliderName: this.props.sliderName, stepSize: stepSize, max: max, customClass: this.props.customClass });
        }
    }

    handleRoundTimeSlider(event) {
        this.setState({ sliderValue: event.target.value }, () => {
            this.props.onChangeSliderValue(event)
        });
    }

    render() {
        const {
            sliderValue,
            finalSliderValue,
            stepSize,
            max,
            min,
            customClass
        } = this.state;

        return (
            <div className={"time-slide-container flex-vertical " + customClass}>
                <span id="time-slide-value" className="slider-label">Seconds into round: {sliderValue}</span>
                <input type="range" min={min} max={max} className="slider" id="time-slide" data-state={this.props.sliderName} defaultValue={sliderValue} value={sliderValue} onChange={this.handleRoundTimeSlider} onMouseUp={this.props.finalSliderValue} step={stepSize} />
            </div>
        );
    }
}