import React, { Component } from "react";
import Select from 'react-select';
import { callApi } from './CallApi';
import { ScoutingReport } from './ScoutingReport';
import Logout from './Logout';
import ReactToPrint from 'react-to-print';

export class SelectTeam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teamSelected: null,
            teamPlaceholder: '',
            showPlayers: false,
            players: [],
            teamList: [],
            demo: false
        }

    }

    componentDidMount() {
        if (this.props.path && this.props.path === "demo") {
            this.setState({
                teamSelected: this.props.teamName,
                teamPlaceholder: '',
                demo: true,
                teamList: [
                    { teamName: 'Only ' + process.env.REACT_APP_DEMO_TEAM_NAME + ' in Demo' },
                ],
            });
        } else {
            callApi('team/list')
                .then(results => {
                    results.sort(function (a, b) {
                        if (a.teamName < b.teamName) { return -1; }
                        if (a.teamName > b.teamName) { return 1; }
                        return 0;
                    })
                    if (this.props.path) {
                        if (this.props.path === "team") {
                            this.setState({
                                teamList: results,
                                teamSelected: this.props.teamName,
                                teamPlaceholder: ''
                            });
                        }
                    } else {
                        this.setState({
                            teamList: results,
                            teamSelected: '',
                            teamPlaceholder: ''
                        });
                    }
                })
        }
    }

    handleTeamChange = (teamSelected) => {
        this.setState({ teamSelected }, () => {
            var nextURL;
            switch (process.env.NODE_ENV) {
                case 'production':
                    nextURL = process.env.REACT_APP_HOST_URL + 'scouting/team/' + teamSelected.value;
                    break;
                case 'development':
                    nextURL = process.env.REACT_APP_DEV_URL + 'scouting/team/' + teamSelected.value;
                default:
            }
            window.history.pushState(null, null, nextURL);
            callApi('users/userReportRun?email=' + localStorage.getItem('userEmail'))
        })
    }

    render() {

        const {
            teamSelected,
            teamList,
            teamPlaceholder,
            showPlayers,
            players,
            demo
        } = this.state;

        var teamSelect = [];
        if (teamList.length > 0) {
            for (var i = 0; i < teamList.length; i++) {
                teamSelect.push({ value: teamList[i].teamName, label: teamList[i].teamName })
            }
        }

        var rosterHTML = '';
        if (players.length > 0) {
            rosterHTML = <div className="roster-container flex">
                {players.map((value, index) => {
                    return <div key={index} className="player-name">{value.name}</div>
                })}
            </div>
        }

        var navButton = '';
        var bugButton = '';
        var supportButton = '';
        if (demo) {
            navButton = <a href="/scouting">Sign Up</a>
            bugButton = '';
        } else {
            navButton = <a href="/scouting/player/">Compare Players</a>
            supportButton = <a href="/support">Support the Project</a>
            bugButton = <a href="https://twitter.com/messages/compose?recipient_id=1214934971468537857&text=Bug%20Report%20for%20CSGO%20Scouting%20App: %0A%0ASteps to Reproduce: %0A%0AImage?: "
                class="twitter-dm-button" target="_blank" data-screen-name="@cloud9_win">
                Bug Report</a>
        }

        var logo = require('./images/csgo-scouting-logo-250px.png');

        return (
            <React.Fragment>
                <div className="flex select-team-container">
                    <div className="nav-title flex">
                        <a href="/scouting" className="setting-logo">
                            <img src={logo} alt="logo" />
                        </a>
                    </div>
                    {/* <div className="select-team">Select Team:</div> */}
                    <span className="select-team-label">Team: </span> <Select
                        value={teamSelected}
                        onChange={this.handleTeamChange}
                        options={teamSelect}
                        className='react-select-team-container'
                        classNamePrefix='react-select-team'
                        placeholder="Select Team"
                    />
                    <div className="nav-item nav-item-first">
                        {navButton}

                    </div>
                    <div className="nav-item">
                        {bugButton}
                    </div>
                    <div className="nav-item">
                        {supportButton}
                    </div>
                    <div className="flex">
                    {(!demo) ? <Logout /> : <a href="/" class="logout-button back-home-button">Back to Homepage</a>}
                    </div>
                </div>

                <ScoutingReport
                    teamName={teamSelected} ref={(response) => (this.componentRef = response)}
                />

            </React.Fragment>
        );
    }
}