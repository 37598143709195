import React, { Component } from "react";
import Select, { components } from 'react-select';

export class SelectMatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMatches: [],
            matchesInfo: [],
            mapName: '',
            teamName: '',
            handleMatchSelectChange: '',
            maxAllowedMatchesSelected: 3
        }
    }

    componentDidMount() {
        if (this.props.matchesInfo) {
            var selectedMatches = [];
            for (var i = 0; i < this.props.selectedMatches.length; i++) {
                for (var j = 0; j < this.props.matchesInfo.length; j++) {
                    if (this.props.selectedMatches[i] === this.props.matchesInfo[j].matchId) {
                        var label = '';
                        if (this.props.matchesInfo[j].team1 === this.props.teamName) {
                            label = this.props.matchesInfo[j].team1 + ' vs ' + this.props.matchesInfo[j].team2 + ' (' + this.props.matchesInfo[j].event + ')';
                        } else {
                            label = this.props.matchesInfo[j].team2 + ' vs ' + this.props.matchesInfo[j].team1 + ' (' + this.props.matchesInfo[j].event + ')';
                        }
                        selectedMatches.push({ value: this.props.matchesInfo[j].matchId, label: label });
                    }
                }
            }
            this.setState({
                mapName: this.props.mapName,
                matchesInfo: this.props.matchesInfo,
                handleMatchSelectChange: this.props.handleMatchSelectChange,
                selectedMatches: selectedMatches,
                teamName: this.props.teamName,
            });
        }
    }

    componentDidUpdate() {
        if (this.props.matchesInfo && (this.props.mapName !== this.state.mapName || this.props.matchesInfo !== this.state.matchesInfo)) {
            var selectedMatches = [];
            for (var i = 0; i < this.props.selectedMatches.length; i++) {
                for (var j = 0; j < this.props.matchesInfo.length; j++) {
                    if (this.props.selectedMatches[i] === this.props.matchesInfo[j].matchId) {
                        var label = '';
                        if (this.props.matchesInfo[j].team1 === this.props.teamName) {
                            label = this.props.matchesInfo[j].team1 + ' vs ' + this.props.matchesInfo[j].team2 + ' (' + this.props.matchesInfo[j].event + ')';
                        } else {
                            label = this.props.matchesInfo[j].team2 + ' vs ' + this.props.matchesInfo[j].team1 + ' (' + this.props.matchesInfo[j].event + ')';
                        }
                        selectedMatches.push({ value: this.props.matchesInfo[j].matchId, label: label });
                    }
                }
            }
            this.setState({
                mapName: this.props.mapName,
                matchesInfo: this.props.matchesInfo,
                handleMatchSelectChange: this.props.handleMatchSelectChange,
                selectedMatches: selectedMatches,
                teamName: this.props.teamName,
            });
        } else {
            if (this.state.teamName.value !== this.props.teamName.value) {
                this.setState({
                    teamName: this.props.teamName,
                    matchesInfo: [],
                    selectedMatches: [],
                });
            }
        }
    }

    handleMatchSelectChange = (selectedMatches) => {
        this.setState({ selectedMatches }, () => {
        });
    }

    handleMatchBlur = () => {
        //send this back to the props to check and update data
        var matchIds = [];
        for (var i = 0; i < this.state.selectedMatches.length; i++) {
            matchIds.push(this.state.selectedMatches[i].value);
        }
        this.props.handleMatchSelectChange(matchIds, this.state.mapName);
    }

    handleDisabledOptions = (option) => {
        if (this.state.selectedMatches.length >= this.state.maxAllowedMatchesSelected) {
            //if option is not in the selected matches, then disable it
            for (var i = 0; i < this.state.selectedMatches.length; i++) {
                if (this.state.selectedMatches[i].value === option.value) {
                    return false;
                }
            }
            return true
        }
    }


    render() {
        const {
            selectedMatches,
            matchesInfo,
            teamName
        } = this.state;

        var matchesSelect = [];
        if (matchesInfo) {
            var label = '';
            matchesInfo.forEach(match => {
                if (match.team1 === teamName) {
                    label = match.team1 + ' vs ' + match.team2 + ' (' + new Date(Number(match.date)).toLocaleDateString() + ' - ' + match.event + ')';
                } else {
                    label = match.team2 + ' vs ' + match.team1 + ' (' + new Date(Number(match.date)).toLocaleDateString() + ' - ' + match.event + ')';
                }
                matchesSelect.push({ value: match.matchId, label: label });
            });
        }

        const Option = (props) => {
            return (
                <div>
                    <components.Option {...props}>
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                        />{" "}
                        <label>{props.label}</label>
                    </components.Option>
                </div>
            );
        };

        return (
            <React.Fragment>
                <div className="flex select-matches-container">
                    <div className="select-team">Matches used:</div>
                    <Select
                        value={selectedMatches}
                        isMulti
                        onChange={this.handleMatchSelectChange}
                        onBlur={this.handleMatchBlur}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={matchesSelect}
                        className='react-select-matches-container'
                        classNamePrefix='react-select-matches'
                        placeholder="Select Matches"
                        isOptionDisabled={this.handleDisabledOptions}
                        components={{
                            Option
                        }}
                    />
                    <div className="select-team">(3 max)</div>
                </div>

            </React.Fragment>
        );
    }
}

export default SelectMatches;