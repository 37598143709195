import React, { Component } from "react";
import { callApi } from './CallApi';
import Modal from 'react-modal';
import deathImage from './weapon_files/death_x.png';
import awpImage from './weapon_files/weapon_awp.png';
import molotovImage from './weapon_files/molotov_flame.png';
import flashbangImage from './weapon_files/grenade_flash.png';
import bombImage from './weapon_files/weapon_c4_black.png';
import smokeImage from './weapon_files/grenade_smoke.png';
import { adjustCoord } from "./AdjustCoord";
import { RoundSlider } from "./RoundSlider";
import { MapSizeSlider } from "./MapSizeSlider";
import Logout from './Logout';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

export class PlayerPositionInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            circlesArray: [],
            round: 0,
            second: 0,
            matchId: 0,
            positions: {},
            mapName: '',
            buy: '',
            selectedSvg: '',
            singlePlayer: false,
            side: '',
            // showUtilityNames: false,
            // showOpponent: true,
            hideBomb: false,
            hideSmokeGrenades: false,
            hideMolotovGrenades: false,
            hideFlashbangGrenades: false,
            matchesInfo: [],
            showModal: false,
            utilityCirclesArray: [],
            roundCirclesArray: [],
            roundCirclesArrayAll: {},
            specificSitutation: false,
            modalType: '',
            utilityCircleSelected: {},
            expandRoundSliderTime: "15",
            teamName: '',
            render: false,
            timeSliderValue: 15,
            roundSliderValue: 15,
            roundMaxSecond: 0,
            circlesFilters: {},
            playRound: false,
            minEconomySelected: 0,
            maxEconomySelected: 50000,
            customEconomy: false,
        }

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChangeSlider = this.handleChangeSlider.bind(this);
        this.handleChangeRoundSlider = this.handleChangeRoundSlider.bind(this);
        // this.handleUtilityNamesChange = this.handleUtilityNamesChange.bind(this);
        // this.handleOpponentChange = this.handleOpponentChange.bind(this);
        // this.handleFilterButtonChange = this.handleFilterButtonChange.bind(this);
        this.createRoundCirclesArray = this.createRoundCirclesArray.bind(this);
        this.openNextPrevRound = this.openNextPrevRound.bind(this);
    }

    roundInterval = null;

    componentWillMount() {
        Modal.setAppElement('body');
    }

    componentDidMount() {
        switch (this.props.path) {
            case "round":
                this.setState({
                    second: this.props.second,
                    positions: (this.props.positions) ? this.props.positions : [],
                    mapName: this.props.mapName,
                    buy: this.props.buy,
                    singlePlayer: this.props.singlePlayer,
                    side: 'Both',
                    //                     showUtilityNames: this.props.showUtilityNames,
                    // showOpponent: this.props.showOpponent,
                    hideBomb: this.props.hideBomb,
                    hideSmokeGrenades: this.props.hideSmokeGrenades,
                    hideMolotovGrenades: this.props.hideMolotovGrenades,
                    hideFlashbangGrenades: this.props.hideFlashbangGrenades,
                    specificSitutation: (this.props.path ? true : false),
                    teamName: this.props.teamName,
                    circlesArray: this.createRoundCirclesArray(this.props.positions),
                    roundMaxSecond: this.findRoundMaxSecondValue(this.props.positions),
                })
                break;
            case "utility":
                this.setState({
                    second: this.props.second,
                    positions: (this.props.positions) ? this.props.positions : [],
                    mapName: this.props.mapName,
                    buy: this.props.buy,
                    singlePlayer: this.props.singlePlayer,
                    side: "Both",
                    hideBomb: this.props.hideBomb,
                    hideSmokeGrenades: this.props.hideSmokeGrenades,
                    hideMolotovGrenades: this.props.hideMolotovGrenades,
                    hideFlashbangGrenades: this.props.hideFlashbangGrenades,
                    specificSitutation: (this.props.path ? true : false),
                    teamName: this.props.teamName,
                    circlesArray: this.createUtilityCirclesArray(this.props.positions)
                })
                break;
            default:
                this.setState({
                    second: this.props.second,
                    positions: (this.props.positions) ? this.props.positions : [],
                    mapName: this.props.mapName,
                    buy: this.props.buy,
                    singlePlayer: this.props.singlePlayer,
                    side: this.props.side,
                    hideBomb: this.props.hideBomb,
                    hideSmokeGrenades: this.props.hideSmokeGrenades,
                    hideMolotovGrenades: this.props.hideMolotovGrenades,
                    hideFlashbangGrenades: this.props.hideFlashbangGrenades,
                    specificSitutation: (this.props.path ? true : false),
                    teamName: this.props.teamName,
                    render: false,
                    circlesArray: this.createFilterCirclesArray(this.props.positions[this.state.timeSliderValue]),
                    circlesFilters: JSON.parse(JSON.stringify(this.props.circlesFilters))

                })
                break;
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.matchId !== this.props.matchId) ||
            (prevProps.positions !== this.props.positions) ||
            (prevProps.showUtilityNames !== this.props.showUtilityNames) ||
            (prevProps.hideBomb !== this.props.hideBomb) ||
            (prevProps.second !== this.props.second) ||
            (prevProps.hideSmokeGrenades !== this.props.hideSmokeGrenades) ||
            (prevProps.hideMolotovGrenades !== this.props.hideMolotovGrenades) ||
            (prevProps.circlesFilters !== this.props.circlesFilters) ||
            (prevProps.showOpponent !== this.props.showOpponent) ||
            (prevProps.showUtilityNames !== this.props.showUtilityNames) ||
            (prevProps.hideFlashbangGrenades !== this.props.hideFlashbangGrenades)) {
            switch (this.props.path) {
                case "round":
                    this.setState({
                        second: this.props.second,
                        positions: (this.props.positions) ? this.props.positions : [],
                        mapName: this.props.mapName,
                        buy: this.props.buy,
                        singlePlayer: this.props.singlePlayer,
                        side: 'Both',
                        hideBomb: this.props.hideBomb,
                        hideSmokeGrenades: this.props.hideSmokeGrenades,
                        hideMolotovGrenades: this.props.hideMolotovGrenades,
                        hideFlashbangGrenades: this.props.hideFlashbangGrenades,
                        specificSitutation: (this.props.path ? true : false),
                        teamName: this.props.teamName,
                        circlesArray: this.createRoundCirclesArray(this.props.positions),
                        render: false,
                        roundMaxSecond: this.findRoundMaxSecondValue(this.props.positions)
                    })
                    break;
                case "utility":
                    this.setState({
                        second: this.props.second,
                        positions: (this.props.positions) ? this.props.positions : [],
                        mapName: this.props.mapName,
                        buy: this.props.buy,
                        singlePlayer: this.props.singlePlayer,
                        side: "Both",
                        hideBomb: this.props.hideBomb,
                        hideSmokeGrenades: this.props.hideSmokeGrenades,
                        hideMolotovGrenades: this.props.hideMolotovGrenades,
                        hideFlashbangGrenades: this.props.hideFlashbangGrenades,
                        specificSitutation: (this.props.path ? true : false),
                        teamName: this.props.teamName,
                        render: true,
                        circlesArray: this.createUtilityCirclesArray(this.props.positions)
                    })
                    break;
                default:
                    this.setState({
                        second: this.props.second,
                        positions: (this.props.positions) ? this.props.positions : [],
                        mapName: this.props.mapName,
                        buy: this.props.buy,
                        singlePlayer: this.props.singlePlayer,
                        side: this.props.side,
                        hideBomb: this.props.hideBomb,
                        hideSmokeGrenades: this.props.hideSmokeGrenades,
                        hideMolotovGrenades: this.props.hideMolotovGrenades,
                        hideFlashbangGrenades: this.props.hideFlashbangGrenades,
                        specificSitutation: (this.props.path ? true : false),
                        teamName: this.props.teamName,
                        render: false,
                        // circlesArray: this.createFilterCirclesArray(this.props.positions[this.state.timeSliderValue]),
                        // circlesFilters: JSON.parse(JSON.stringify(this.props.circlesFilters))
                        circlesArray: this.createFilterCirclesArray(this.props.positions[this.state.timeSliderValue]),
                        circlesFilters: JSON.parse(JSON.stringify(this.props.circlesFilters)),
                    })
                    break;
            }
            // })
        }
    }

    createFilterCirclesArray = (circlesArray) => {
        let minEco = Infinity; // Start with the highest possible value
        let maxEco = 0; // Start with the lowest possible value

        if (this.props.circlesFilters.fullBuyRounds) {
            minEco = Math.min(minEco, 20000);
            maxEco = Math.max(maxEco, 50000);
        }

        if (this.props.circlesFilters.forceBuyRounds) {
            minEco = Math.min(minEco, 10000);
            maxEco = Math.max(maxEco, 20000);
        }

        if (this.props.circlesFilters.ecoRounds) {
            minEco = Math.min(minEco, 0);
            maxEco = Math.max(maxEco, 10000);
        }

        if (this.props.circlesFilters.pistolRounds) {
            minEco = Math.min(minEco, 0);
            maxEco = Math.max(maxEco, 5000);
        }

        if (this.props.circlesFilters.customEconomy) {
            minEco = this.state.minEconomySelected;
            maxEco = this.state.maxEconomySelected;
        }

        // Ensure minEco and maxEco have valid values after all filters are processed
        if (minEco === Infinity) minEco = 0; // Reset to default if no filter was set
        if (maxEco === 0) maxEco = 50000;

        if (this.props.circlesFilters.allRounds) {
            this.setState({ minEconomySelected: minEco, maxEconomySelected: maxEco })
            return circlesArray;
        }

        var allRounds = [];
        var firstFullBuyRoundNumber = "";
        var firstFullBuyMatchId = "";
        var firstFullBuySide = "";

        for (var i = 0; i < circlesArray.length; i++) {
            var round = circlesArray[i];
            var currentRoundInfo = round[0].roundInfo;

            if (this.props.circlesFilters.pistolRounds && round[0].teamBuy === "Pistol") {
                allRounds.push(round);
                continue;
            }

            if (this.props.circlesFilters.ecoRounds && round[0].teamMoneySpent < 10000 && round[0].teamBuy !== "Pistol") {
                allRounds.push(round);
                continue;
            }

            if (this.props.circlesFilters.forceBuyRounds && round[0].teamMoneySpent < 20000 && round[0].teamBuy !== "Pistol") {
                allRounds.push(round);
                continue;
            }

            if (this.props.circlesFilters.fullBuyRounds && round[0].teamMoneySpent >= 20000) {
                allRounds.push(round);
                continue;
            }

            if (this.props.circlesFilters.customEconomy && round[0].teamBuy !== "Pistol") {
                if (round[0].teamMoneySpent >= this.state.minEconomySelected && round[0].teamMoneySpent <= this.state.maxEconomySelected) {
                    allRounds.push(round);
                    continue;
                }
            }

            if (this.props.circlesFilters.afterPistolWinRounds && round[0].roundNumber == 2) {
                var firstHalfScore = currentRoundInfo.team1Name === this.state.teamName ? Number(currentRoundInfo.team1ScoreFirstHalf) : Number(currentRoundInfo.team2ScoreFirstHalf);
                var firstHalfOpponentScore = currentRoundInfo.team1Name === this.state.teamName ? Number(currentRoundInfo.team2ScoreFirstHalf) : Number(currentRoundInfo.team1ScoreFirstHalf);
                if (firstHalfScore > firstHalfOpponentScore) {
                    allRounds.push(round);
                    continue;
                }
            } else if (this.props.circlesFilters.afterPistolWinRounds && round[0].roundNumber == 14) {
                var secondHalfScore = currentRoundInfo.team1Name === this.state.teamName ? Number(currentRoundInfo.team1ScoreSecondHalf) : Number(currentRoundInfo.team2ScoreSecondHalf);
                var secondHalfOpponentScore = currentRoundInfo.team1Name === this.state.teamName ? Number(currentRoundInfo.team2ScoreSecondHalf) : Number(currentRoundInfo.team1ScoreSecondHalf);
                if (secondHalfScore > secondHalfOpponentScore) {
                    allRounds.push(round);
                    continue;
                }
            }

            if (this.props.circlesFilters.afterPistolLossRounds && round[0].roundNumber == 2) {
                var firstHalfScore = currentRoundInfo.team1Name === this.state.teamName ? Number(currentRoundInfo.team1ScoreFirstHalf) : Number(currentRoundInfo.team2ScoreFirstHalf);
                var firstHalfOpponentScore = currentRoundInfo.team1Name === this.state.teamName ? Number(currentRoundInfo.team2ScoreFirstHalf) : Number(currentRoundInfo.team1ScoreFirstHalf);
                if (firstHalfScore < firstHalfOpponentScore) {
                    allRounds.push(round);
                    continue;
                }
            } else if (this.props.circlesFilters.afterPistolLossRounds && round[0].roundNumber == 14) {
                var secondHalfScore = currentRoundInfo.team1Name === this.state.teamName ? Number(currentRoundInfo.team1ScoreSecondHalf) : Number(currentRoundInfo.team2ScoreSecondHalf);
                var secondHalfOpponentScore = currentRoundInfo.team1Name === this.state.teamName ? Number(currentRoundInfo.team2ScoreSecondHalf) : Number(currentRoundInfo.team1ScoreSecondHalf);
                if (secondHalfScore < secondHalfOpponentScore) {
                    allRounds.push(round);
                    continue;
                }
            }

            if (this.props.circlesFilters.firstFullBuyRounds) {
                var currentTeamSide = currentRoundInfo.team1Name === this.state.teamName ? "T" : "CT";

                if ((currentRoundInfo.team1Buy == "Full Buy" && currentRoundInfo.team2Buy == "Full Buy") && (currentRoundInfo.matchId !== firstFullBuyMatchId || currentTeamSide !== firstFullBuySide) && currentRoundInfo.round < 31) {
                    firstFullBuyRoundNumber = currentRoundInfo.round;
                    firstFullBuyMatchId = currentRoundInfo.matchId;
                    firstFullBuySide = currentTeamSide;
                    allRounds.push(round);
                    continue;
                }
            }

            if (this.props.circlesFilters.awpRounds) {
                var currentTeamSide = currentRoundInfo.team1Name === this.state.teamName ? "T" : "CT";

                for (var player of round) {
                    if (player.primaryWeapon === "AWP" && player.side === currentTeamSide) {
                        allRounds.push(round);
                        continue;
                    }
                }
            }

            if (this.props.circlesFilters.fullBuyNoAwpRounds) {
                var currentTeamSide = currentRoundInfo.team1Name === this.state.teamName ? "T" : "CT";
                var currentTeamBuy = currentRoundInfo.team1Name === this.state.teamName ? currentRoundInfo.team1Buy : currentRoundInfo.team2Buy;
                var hasAwp = false;
                for (var player of round) {
                    if (player.primaryWeapon === "AWP" && player.side === currentTeamSide) {
                        hasAwp = true;
                        continue;
                    }
                }
                if (currentTeamBuy === "Full Buy" && !hasAwp) {
                    allRounds.push(round);
                    continue;
                }
            }
        }

        this.setState({ minEconomySelected: minEco, maxEconomySelected: maxEco })
        return allRounds;
    }

    expandSvg = (index) => {
        if (this.state.selectedSvg === index) {
            this.setState({ selectedSvg: '' })
        } else {
            this.setState({ selectedSvg: index })
        }
    }

    getUtilityClick = (event) => {
        if (this.props.path) {
            this.openNewTabUtility(event)
            return;
        }

        var circleData = JSON.parse(event.target.getAttribute('data-circle'))
        var callApiUrl = 'position/generalInfo?teamName=' + circleData.playerTeam +
            '&mapName=' + circleData.mapName + '&utility=true&x=' + circleData.xPos_old + '&y=' + circleData.yPos_old + '&z=' + circleData.zPos +
            '&side=' + ((circleData.side) === 'T' ? 'TERRORIST' : circleData.side) + '&utilityType=' + circleData.positionType + '&expired=false'
        this.setState({ showModal: true, modalType: "utility", utilityCircleSelected: circleData }, () => {
            callApi(callApiUrl)
                .then(results => {
                    this.createUtilityCirclesArray(results, circleData.positionType)
                })
        })
    }

    getRoundClick = (event) => {
        if (this.props.path) {
            this.openNewTabRound(event)
            return;
        }

        var teamName = event.target.getAttribute('data-teamname')
        var matchId = event.target.getAttribute('data-matchid')
        var round = event.target.getAttribute('data-round')

        this.setState({ showModal: true, modalType: "round" }, () => {
            callApi('position/generalInfo?mapName=' + this.state.mapName + '&matchId=' + matchId + '&round=' + round + '&allSeconds=true')
                .then(results => {
                    var positions = this.createRoundCirclesArray(results)
                    this.setState({ roundCirclesArrayAll: positions, roundCirclesArray: [positions[this.state.expandRoundSliderTime]] })
                })
        })
    }
    handelExpandRoundSlider = (event) => {
        this.setState({
            expandRoundSliderTime: event.target.value,
            roundCirclesArray: [this.state.roundCirclesArrayAll[event.target.value]]
        });
    }

    createRoundCirclesArray = (positions, sliderTime) => {
        if (Object.keys(positions).length === 0) {
            return [];
        }
        if (sliderTime) {
            return [positions[sliderTime]];
        }
        return [positions[this.state.roundSliderValue]];
    }

    createUtilityCirclesArray = (positions) => {
        if (Object.keys(positions).length === 0) {
            return [];
        }

        //sort array by roundTime
        positions.sort(function (a, b) {
            return a[0].roundTime - b[0].roundTime;
        });


        return positions;
    }

    findRoundMaxSecondValue = (positions) => {
        //return the final Object.key of positions
        return Object.keys(positions).length - 1;
    }

    openNewTabRound = (event) => {
        var url = ''
        var teamName = event.target.getAttribute('data-teamname')
        var matchId = event.target.getAttribute('data-matchid')
        var round = event.target.getAttribute('data-round')
        var mapName = event.target.getAttribute('data-mapname')

        switch (process.env.NODE_ENV) {
            case 'production':
                url = process.env.REACT_APP_HOST_URL + "scouting/round/" + matchId + "/" + teamName + "/" + mapName + "/" + round;
                break;
            case 'development':
                url = process.env.REACT_APP_DEV_URL + "scouting/round/" + matchId + "/" + teamName + "/" + mapName + "/" + round;
            default:
        }

        window.open(url);
    }

    openNewTabUtility = (event) => {
        var url = '';
        var circleData = JSON.parse(event.target.getAttribute('data-circle'))
        switch (process.env.NODE_ENV) {
            case 'production':
                url = process.env.REACT_APP_HOST_URL + "scouting/utility/" + circleData.mapName + "/" + circleData.playerTeam + "/" + circleData.positionType + "/" + circleData.side + "/" + circleData.xPos_old + "/" + circleData.yPo_olds + "/" + circleData.zPos;
                break;
            case 'development':
                url = process.env.REACT_APP_DEV_URL + "scouting/utility/" + circleData.mapName + "/" + circleData.playerTeam + "/" + circleData.positionType + "/" + circleData.side + "/" + circleData.xPos_old + "/" + circleData.yPos_old + "/" + circleData.zPos;
            default:
        }
        window.open(url);
    }

    openNextPrevRound = (nextRound) => {
        var currentUrl = window.location.href;
        //Get the final number in the url and add 1 or subtract 1 if nextRound is true
        // var newRound = parseInt(currentUrl.substring(currentUrl.lastIndexOf('/') + 1)) + nextRound
        var newUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/') + 1) + nextRound
        window.location.href = newUrl

    }

    handleCloseModal() {
        //Mabye add history? To save an api call? Idk if worth or maybe as part of a whole host of local data
        this.setState({ showModal: false, roundCirclesArray: [], utilityCirclesArray: [], modalType: "", utilityCircleSelected: {} });
    }


    handleChangeSlider = (event) => {
        this.setState({
            timeSliderValue: event.target.value,
            circlesArray: this.createFilterCirclesArray(this.state.positions[event.target.value]),
        });
    }

    handleChangeRoundSlider = (event) => {
        this.setState({
            roundSliderValue: event.target.value,
            circlesArray: this.createRoundCirclesArray(this.state.positions),
        });
    }

    handleChangeSliderFinal = (event) => {

    }

    handleOnPlayPauseClick = () => {
        this.setState({ playRound: !this.state.playRound }, () => {
            if (this.state.playRound) {
                //add 1 to the slider value every .25 seconds
                this.roundInterval = setInterval(() => {
                    if (this.state.roundSliderValue < this.state.roundMaxSecond) {
                        this.setState({ roundSliderValue: Number(this.state.roundSliderValue) + 1, circlesArray: this.createRoundCirclesArray(this.state.positions, Number(this.state.roundSliderValue) + 1) });
                    } else {
                        //stop where its at
                        this.setState({ playRound: false });
                        clearInterval(this.roundInterval);
                    }
                }, 250);
            } else {
                clearInterval(this.roundInterval);
            }
        });
    }

    handleEconomySlider = (value) => {
        this.setState({
            minEconomySelected: value[0],
            maxEconomySelected: value[1],
        })
    };

    handleEconomySliderFinalize = (value) => {
        this.props.resetEconomyFilters()

        this.setState({
            minEconomySelected: value[0],
            maxEconomySelected: value[1],
        }).then(() => {
            this.createFilterCirclesArray(this.state.positions[this.state.timeSliderValue])
        });
    };



    // handleUtilityNamesChange(event) {
    //     this.setState({ showUtilityNames: event.target.checked }, () => {
    //     });
    // }

    // handleOpponentChange(event) {
    //     this.setState({ showOpponent: event.target.checked }, () => {
    //     });
    // }

    render() {
        const {
            circlesArray,
            round,
            matchId,
            mapName,
            selectedSvg,
            singlePlayer,
            hideBomb,
            hideSmokeGrenades,
            hideMolotovGrenades,
            hideFlashbangGrenades,
            specificSitutation,
            render,
            timeSliderValue,
            roundSliderValue,
            showUtilityNames,
            showOpponent,
            teamName,
            roundMaxSecond,
            circlesFilters,
            playRound,
            minEconomySelected,
            maxEconomySelected

        } = this.state;
        var mapImage = '';
        var mapImageSmall = '';
        var playerNames = []

        if (mapName !== '') {
            mapImage = require('./radar_maps/' + mapName + '_radar.png');
            mapImageSmall = require('./radar_maps/' + mapName + '_radar_small.jpg');
        }

        if (circlesArray.length > 0) {
            var gotNames = false;
            for (var i = 0; i < circlesArray.lenth; i++) {
                if (circlesArray[i].length >= 5) {
                    for (var j = 0; j < 5; j++) {
                        playerNames.push(circlesArray[i][j].playerName)
                    }
                    gotNames = true;
                }

                if (gotNames) {
                    break;
                }
            }
        }

        var singlePlayersMaps = []
        if (singlePlayer) {
            singlePlayersMaps.push('')
        }

        var timeSlider;
        var pageHeader;
        var playButton = <svg className="play-pause-button" viewBox="0 0 60 60" onClick={this.handleOnPlayPauseClick}>
            <polygon points="0,0 50,30 0,60" />
        </svg>;
        var pauseButton = <svg className="play-pause-button" viewBox="0 0 60 60" onClick={this.handleOnPlayPauseClick}>
            <polygon points="0,0 15,0 15,60 0,60" />
            <polygon points="25,0 40,0 40,60 25,60" />
        </svg>;

        if (this.props.path === 'round' && roundMaxSecond > 0) {
            var playerTeamTitle = teamName;
            var opponentTeamTitle = (circlesArray[0][0].playerTeam == teamName) ? circlesArray[0][0].opponentTeamName : circlesArray[0][0].playerTeam;
            pageHeader = <h1 className="utility-round-title">{playerTeamTitle + " vs " + opponentTeamTitle + " - Round " + circlesArray[0][0].roundNumber +
                " - " + circlesArray[0][0].mapName.charAt(3).toUpperCase() + circlesArray[0][0].mapName.slice(4)}</h1>

            timeSlider = <RoundSlider sliderValue={roundSliderValue} sliderName="roundSlider" onChangeSliderValue={this.handleChangeRoundSlider} finalSliderValue={this.handleChangeSliderFinal} stepSize={"1"} max={roundMaxSecond} min={1} />
        } else if (this.props.path !== 'utility') {
            timeSlider = <RoundSlider sliderValue={timeSliderValue} sliderName="timeSlider" onChangeSliderValue={this.handleChangeSlider} finalSliderValue={this.handleChangeSliderFinal} />
        }

        // var timeSlider = <RoundSlider sliderValue={timeSliderValue} sliderName="timeSlider" onChangeSliderValue={this.handleChangeSlider} finalSliderValue={this.handleChangeSliderFinal} />
        var showUtilityInput = <input type="checkbox" id="utility" name="utility" value="utility" onChange={this.props.handleUtilityNamesChange} checked={(this.props.showUtilityNames) ? "checked" : ""} />
        var opponentInput = <input type="checkbox" id="opponent" name="opponent" value="opponent" onChange={this.props.handleOpponentChange} checked={(this.props.showOpponent) ? "checked" : ""} />

        var roundNumbers = []
        if (circlesArray != undefined && circlesArray.length > 0) {
            for (var i = 1; i <= circlesArray[0][0]?.totalRounds; i++) {
                roundNumbers.push(i)
            }
        }
        var logo = require('./images/csgo-scouting-logo-250px.png');

        return (
            <React.Fragment>
                {/* //Move slider to here to see if that speeds a ton of stuff up. Why go back to map container. */}
                {this.props.path == 'round' || this.props.path == 'utility' ?
                    <div className="flex select-team-container">
                        <div className="nav-title flex">
                            <a href="/scouting" className="setting-logo">
                                <img src={logo} alt="logo" />
                            </a>
                        </div>
                        {/* <div className="select-team">Select Team:</div> */}

                        <div className="nav-item">
                            <a href={"/scouting/team/" + teamName}>Scouting Report</a>
                        </div>

                        <div className="nav-item" style={{ marginLeft: '0' }}>
                            <a href="/scouting/player/">Player Comparisons</a>
                        </div>
                        <div className="flex">
                            <Logout />
                        </div>
                    </div>
                    : null
                }
                {pageHeader}
                {(this.props.path === 'round') ? <div className="play-pause-container">{(!playRound) ? playButton : pauseButton} {timeSlider}</div> : timeSlider}

                {(this.props.path !== 'round' && this.props.path !== 'utility') ?
                    <div className="check-settings-container flex">
                        <div className="settings-input flex">
                            {showUtilityInput}
                            <label htmlFor="utility">Utility Player Name</label>
                        </div>
                        <div className="settings-input flex">
                            {opponentInput}
                            <label htmlFor="opponent">Show Opponent</label>
                        </div>
                        <MapSizeSlider sliderValue={this.props.sliderValue} handleMapSizeSlider={this.props.handleMapSizeSlider} />
                    </div>
                    :
                    <div className="check-settings-container flex">
                        <div className="settings-input flex">
                            {showUtilityInput}
                            <label htmlFor="utility">Utility Player Name</label>
                        </div>
                    </div>}
                {(this.props.path !== 'round' && this.props.path !== 'utility') ?
                    <div>
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.allRounds ? "filter-button-checked" : null)} value="All Rounds" data-state="allRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.pistolRounds ? "filter-button-checked" : null)} value="Pistol Rounds" data-state="pistolRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.afterPistolWinRounds ? "filter-button-checked" : null)} value="After Pistol Wins" data-state="afterPistolWinRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.afterPistolLossRounds ? "filter-button-checked" : null)} value="After Pistol Loss" data-state="afterPistolLossRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.ecoRounds ? "filter-button-checked" : null)} value="Ecos" data-state="ecoRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.forceBuyRounds ? "filter-button-checked" : null)} value="Forces" data-state="forceBuyRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.fullBuyRounds ? "filter-button-checked" : null)} value="Full Buys" data-state="fullBuyRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.firstFullBuyRounds ? "filter-button-checked" : null)} value="First Full Buy" data-state="firstFullBuyRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.awpRounds ? "filter-button-checked" : null)} value="Have AWP" data-state="awpRounds" onClick={this.props.handleFilterButtonChange} />
                        <input type="button" className={"filter-button " + (this.props.circlesFilters.fullBuyNoAwpRounds ? "filter-button-checked" : null)} value="Full Buy no AWP" data-state="fullBuyNoAwpRounds" onClick={this.props.handleFilterButtonChange} />
                    </div> : null}

                <div className="economy-slider-container flex">
                    <div className="economy-slider-min">${minEconomySelected.toLocaleString()}</div>
                    <RangeSlider
                        value={[minEconomySelected, maxEconomySelected]}
                        onInput={this.handleEconomySlider}
                        onThumbDragEnd={this.handleEconomySliderFinalize}
                        onRangeDragEnd={this.handleEconomySliderFinalize}
                        min={0}
                        max={50000}
                        step={500}
                    />
                    <div className="economy-slider-max">${maxEconomySelected.toLocaleString()}</div>
                </div>

                <div className={this.props.path == 'round' ? "map-container flex-vertical" : "map-container flex"}>
                    {/* <Modal
                        isOpen={showModal}
                        onRequestClose={this.handleCloseModal}
                        contentLabel="Example Modal"
                    >
                        <div className="flex">
                            <div className="modal-button" onClick={this.handleCloseModal}>Close</div>
                            {(modalType === "round" && roundCirclesArray.length > 0 ? <div data-matchid={roundCirclesArray[0][0].matchId} data-round={roundCirclesArray[0][0].roundNumber} data-teamname={roundCirclesArray[0][0].playerTeam} data-mapname={roundCirclesArray[0][0].mapName} className="modal-button" onClick={this.openNewTabRound}>Open in New Tab</div> : '')}
                            {(modalType === "utility" && roundCirclesArray.length > 0 ? <div data-circle={JSON.stringify(utilityCircleSelected)} className="modal-button" onClick={this.openNewTabUtility}>Open in New Tab</div> : '')}
                            {(modalType === "round") ? <RoundSlider sliderValue={expandRoundSliderTime} finalSliderValue={this.handelExpandRoundSlider.bind(this)} onChangeSliderValue={this.handelExpandRoundSlider.bind(this)} stepSize="1" max={(Object.keys(roundCirclesArrayAll).length > 0) ? Object.keys(roundCirclesArrayAll)[Object.keys(roundCirclesArrayAll).length - 1] : null} /> : ''}
                            <div className="modal-button-space"></div>
                            <div className="modal-button-space"></div>
                        </div>


                        <div className="modal-container flex">
                            {roundCirclesArray.length > 0 ? '' : <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}


                            {roundCirclesArray.map((circles, index) => {
                                var weaponsUsed = [];
                                var mapHtml = <div key={index} className="flex-one flex-vertical map-svg-container"><svg viewBox="0 0 1024 1024"
                                    onClick={() => this.expandSvg(index)}
                                    className={`map-svg flex-one ${selectedSvg === index ? 'map-modal' : ''}`}
                                >
                                    <image href={mapImage} alt="map" height="1024" width="1024" />

                                    <text className="svg-font-names" x={50} y={50} fill="white">Match: {circles[0].matchId} - {circles[0].mapName}</text>
                                    <text className="svg-font-names" x={50} y={70} fill="white">Round: {circles[0].roundNumber} ({circles[0].roundTime} seconds)</text>
                                    <text className="svg-font-names" x={50} y={90} fill="white">Side: {circles[0].side}</text>
                                    <text className="svg-font-names" x={50} y={110} fill="white">Buy: {circles[0].buy}</text>

                                    {circles.map((circle, index) => {
                                        //Could refactor this to be return statements rather than if statements
                                        var drawOnMap;

                                        if (circle.positionType === "Player") {
                                            (circle.primaryWeapon != "") ? weaponsUsed.push({ name: circle.playerName, weapon: circle.primaryWeapon, teamName: circle.playerTeam }) : weaponsUsed.push({ name: circle.playerName, weapon: circle.weapon, teamName: circle.playerTeam });
                                            if (circle.isAlive === "true") {
                                                var color = (circle.side === "CT" ? "#12B3DD" : "#FFED00")
                                                if (circle.eyeYaw != null) {
                                                    var yaw = Number(circle.eyeYaw);
                                                    if (yaw < 180) {
                                                        yaw = -yaw
                                                    }
                                                    if (yaw >= 180) {
                                                        yaw = 360 - yaw
                                                    }

                                                    var playerLookDirection = <image key={round + "-" + circle.playerName + "-" + "arrow"} x={Number(circle.xPos - 35)} y={Number(circle.yPos - 35)} width="70" height="70" href={arrowImage} style={{ transformOrigin: Number(circle.xPos) + "px " + Number(circle.yPos) + "px" }} transform={"rotate(" + yaw + ")"} />
                                                    drawOnMap = [<circle key={round + "-" + circle.playerName + "-" + index} cx={circle.xPos} cy={circle.yPos} r="11" stroke="white" strokeWidth="0" fill={color} fillOpacity="1" />, playerLookDirection]
                                                } else {
                                                    drawOnMap = <circle key={round + "-" + circle.playerName + "-" + index} cx={circle.xPos} cy={circle.yPos} r="11" stroke="white" strokeWidth="0" fill={color} fillOpacity="1" />
                                                }
                                            } else {
                                                drawOnMap = <image key={round + "-" + circle.playerName + "-" + index} x={Number(circle.xPos - 10)} y={Number(circle.yPos - 10)} width="20" height="20" href={deathImage} />
                                            }
                                        }

                                        if (circle.positionType === "Smoke" && hideSmokeGrenades === false) {
                                            drawOnMap = <image className="utility-image" key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 50)} y={Number(circle.yPos - 50)} width="100" height="100" href={smokeImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />
                                        }

                                        if (circle.positionType === "Molotov" && hideMolotovGrenades === false) {
                                            drawOnMap = <image className="utility-image" key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 30)} y={Number(circle.yPos - 30)} width="60" height="60" href={molotovImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />
                                        }

                                        if (circle.positionType === "Flashbang" && hideFlashbangGrenades === false) {
                                            drawOnMap = <image className="utility-image" key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 30)} y={Number(circle.yPos - 30)} width="60" height="60" href={flashbangImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />
                                        }

                                        if (circle.positionType === "Bomb" && hideBomb === false) {
                                            // drawOnMap = <circle className="c4-image" key={round + "-" + index} cx={xPosition} cy={yPosition} r="20" stroke="white" strokeWidth="3" fill="none" />
                                            // drawOnMap = <image className="c4-image" key={round + "-" + index} x={Number(xPosition - 15)} y={Number(yPosition - 15)} width="30" height="30" href={bombImage} />
                                            drawOnMap = [<circle className="c4-image" key={round + "-" + circle.positionType + "-" + index} cx={circle.xPos} cy={circle.yPos} r="15" stroke="white" strokeWidth="3" fill="none" />, <image className="c4-image" key={round + "-" + index} x={Number(circle.xPos - 10)} y={Number(circle.yPos - 10)} width="20" height="20" href={bombImage} />]

                                        }

                                        return drawOnMap
                                    })}

                                    {circles.map((circle, index) => {
                                        var drawOnMap = '';
                                        if ((circle.positionType !== "Smoke" && circle.positionType !== "Molotov" && circle.positionType !== "Flashbang" && circle.positionType !== "Bomb") || (this.state.showUtilityNames === true)) {
                                            var y_loc = Number(circle.yPos) + 20;
                                            var x_loc = Number(circle.xPos) - (circle.playerName.length / 2 * 8)
                                            drawOnMap = <text className="svg-font-names" x={x_loc} y={y_loc} fill="white">{circle.playerName}</text>
                                        }

                                        return drawOnMap
                                    })}

                                    {circles.map((circle, index) => {
                                        var drawOnMap = '';
                                        if (circle.primaryWeapon === "AWP") {
                                            drawOnMap = <image key={round + "-" + circle.primaryWeapon + "-" + index} x={circle.xPos - 25} y={Number(circle.yPos) - 35} width="50" height="30" href={awpImage} />
                                        }
                                        if (circle.hasBomb === "true" && hideBomb === false) {
                                            drawOnMap = <image className="c4-image" key={round + "- Bomb -" + index} x={circle.xPos + 5} y={Number(circle.yPos) - 10} width="20" height="20" href={bombImage} />
                                        }

                                        return drawOnMap
                                    })}
                                </svg>
                                    <div className="flex-vertical">
                                        <div className="weapon-used-container flex">
                                            {weaponsUsed.sort((a, b) => (
                                                a.teamName.localeCompare(b.teamName) || a.name.localeCompare(b.name)
                                            )).map((weapon, index) => {
                                                return <div key={index} className="weapon-used flex-one">{weapon.name}</div>
                                            })}
                                        </div>
                                        <div className="weapon-used-container flex">
                                            {weaponsUsed.sort((a, b) => (
                                                a.teamName.localeCompare(b.teamName) || a.name.localeCompare(b.name)
                                            )).map((weapon, index) => {
                                                return <div key={index} className="weapon-used flex-one">{weapon.weapon}</div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                                return mapHtml

                            })}</div>


                    </Modal> */}
                    <div></div>
                    {render ? <div className="flex flex-vertical"></div> : null}
                    {!singlePlayer ? circlesArray.map((circles, index) => {
                        var mapsPerRow = this.props.sliderValue ? this.props.sliderValue : "3";

                        if (circles[0].side === this.state.side || this.state.side === "Both") {
                            var weaponsUsed = [];

                            var mapHtml = <div key={index} className={"flex-one flex-vertical map-svg-container maps-per-row-" + mapsPerRow}>
                                {this.props.path === "utility" ?
                                    <div className="map-header-info">
                                        {/* Add slider in later, but have to change the way I even get data */}
                                        {/* <RoundSlider sliderValue={roundSliderValue} sliderName="roundSlider" onChangeSliderValue={this.handleChangeRoundSlider} finalSliderValue={this.handleChangeSliderFinal} stepSize={"1"} max={roundMaxSecond} min={1} /> */}
                                        {circles[0].playerTeam + " vs " + circles[0].opponentTeamName + " - Round: " + circles[0].roundNumber + " (" + circles[0].roundTime + " seconds)"}
                                    </div>
                                    :
                                    null
                                }
                                {(this.props.path == undefined) ?
                                    <div className="map-header-info">
                                        {(circles[0].roundInfo.team1Name === this.state.teamName)
                                            ? circles[0].roundInfo.team1Name + " vs " + circles[0].roundInfo.team2Name + " - Round: " + circles[0].roundNumber + " (" + circles[0].roundInfo.team1Buy + ")" + " " + circles[0].roundTime + " seconds"
                                            : circles[0].roundInfo.team2Name + " vs " + circles[0].roundInfo.team1Name + " - Round: " + circles[0].roundNumber + " (" + circles[0].roundInfo.team2Buy + ")" + " " + circles[0].roundTime + " seconds"}
                                    </div>
                                    :
                                    null
                                }


                                <svg viewBox="0 0 1024 1024"
                                    onClick={() => this.expandSvg(index)}
                                    className={`map-svg flex-one ${selectedSvg === index ? 'map-modal' : ''}`}
                                >
                                    <image href={mapImage} alt="map" height="1024" width="1024" />

                                    {circles.map((circle, index) => {
                                        var drawOnMap;
                                        var utilityClassName = (circle.side === "CT" ? "ct-utility" : "t-utility")
                                        if (circle.playerTeam !== teamName && this.props.showOpponent === false) {
                                            return
                                        }
                                        if (!this.props.showOpponent) {
                                            utilityClassName = "utility"
                                        }
                                        if (circle.positionType === "Player") {
                                            (circle.primaryWeapon != "") ? weaponsUsed.push({ name: circle.playerName, weapon: circle.primaryWeapon, teamName: circle.playerTeam }) : weaponsUsed.push({ name: circle.playerName, weapon: circle.weapon, teamName: circle.playerTeam });
                                            if (circle.isAlive === "true") {
                                                if (circle.eyeYaw != null) {
                                                    var yaw = Number(circle.eyeYaw);
                                                    if (yaw < 180) {
                                                        yaw = -yaw
                                                    }
                                                    if (yaw >= 180) {
                                                        yaw = 360 - yaw
                                                    }
                                                    var color = (circle.side === "CT" ? "#12B3DD" : "#FFED00")

                                                    var arrowHeight = 15;
                                                    var arrowWidth = 7.5;
                                                    var playerLookDirection = (
                                                        <polygon
                                                            points={`0,0 ${arrowWidth},${arrowWidth} 0,${arrowHeight}`}
                                                            fill="white"
                                                            transform={`translate(${circle.xPos + 10}, ${circle.yPos - arrowHeight / 2}) rotate(${yaw})`}
                                                            style={{ transformOrigin: `${-10}px ${arrowHeight / 2}px` }}
                                                        />
                                                    );
                                                    drawOnMap = [<circle key={round + "-" + circle.playerName + "-" + index} cx={Number(circle.xPos)} cy={Number(circle.yPos)} r="11" stroke="white" strokeWidth="0" fill={color} fillOpacity="1" />,
                                                        playerLookDirection,
                                                    <text className="svg-font-names" x={Number(circle.xPos) - (circle.playerName.length / 2 * 8)} y={Number(circle.yPos) + 20} fill="white">{circle.playerName.toLowerCase()}</text>]
                                                } else {
                                                    drawOnMap = [<circle key={round + "-" + circle.playerName + "-" + index} cx={circle.xPos} cy={circle.yPos} r="11" stroke="white" strokeWidth="0" fill={color} fillOpacity="1" />,
                                                    <text className="svg-font-names" x={Number(circle.xPos) - (circle.playerName.length / 2 * 8)} y={Number(circle.yPos) + 20} fill="white">{circle.playerName.toLowerCase()}</text>]
                                                }
                                            } else {
                                                drawOnMap = [<image key={round + "-" + circle.playerName + "-" + index} x={Number(circle.xPos - 10)} y={Number(circle.yPos - 10)} width="20" height="20" href={deathImage} />,
                                                <text className="svg-font-names" x={Number(circle.xPos) - (circle.playerName.length / 2 * 8)} y={Number(circle.yPos) + 20} fill="white">{circle.playerName.toLowerCase()}</text>]
                                            }
                                        }

                                        if (circle.positionType === "Smoke" && hideSmokeGrenades === false) {
                                            if (this.props.showUtilityNames === true) {
                                                drawOnMap = [<image className={"utility-image " + utilityClassName} key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 50)} y={Number(circle.yPos - 50)} width="100" height="100" href={smokeImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />,
                                                <text className="svg-font-names" x={Number(circle.xPos) - (circle.playerName.length / 2 * 8)} y={Number(circle.yPos) + 20} fill="white">{circle.playerName.toLowerCase()}</text>]
                                            } else {
                                                drawOnMap = <image className={"utility-image " + utilityClassName} key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 50)} y={Number(circle.yPos - 50)} width="100" height="100" href={smokeImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />
                                            }
                                        }

                                        if (circle.positionType === "Molotov" && hideMolotovGrenades === false) {
                                            if (this.props.showUtilityNames === true) {
                                                drawOnMap = [<image className={"utility-image " + utilityClassName} key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 30)} y={Number(circle.yPos - 30)} width="60" height="60" href={molotovImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />,
                                                <text className="svg-font-names" x={Number(circle.xPos) - (circle.playerName.length / 2 * 8)} y={Number(circle.yPos) + 20} fill="white">{circle.playerName.toLowerCase()}</text>]
                                            } else {
                                                drawOnMap = <image className={"utility-image " + utilityClassName} key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 30)} y={Number(circle.yPos - 30)} width="60" height="60" href={molotovImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />
                                            }
                                        }

                                        if (circle.positionType === "Flashbang" && hideFlashbangGrenades === false) {
                                            if (this.props.showUtilityNames === true) {
                                                drawOnMap = [<image className={"utility-image " + utilityClassName} key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 30)} y={Number(circle.yPos - 30)} width="60" height="60" href={flashbangImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />,
                                                <text className="svg-font-names" x={Number(circle.xPos) - (circle.playerName.length / 2 * 8)} y={Number(circle.yPos) + 20} fill="white">{circle.playerName.toLowerCase()}</text>]
                                            } else {
                                                drawOnMap = <image className={"utility-image " + utilityClassName} key={round + "-" + circle.positionType + "-" + index} x={Number(circle.xPos - 30)} y={Number(circle.yPos - 30)} width="60" height="60" href={flashbangImage} data-circle={JSON.stringify(circle)} onClick={this.openNewTabUtility} />
                                            }
                                        }

                                        if (circle.positionType === "Bomb" && hideBomb === false) {
                                            drawOnMap = [<circle className="c4-image" key={round + "-" + circle.positionType + "-" + index} cx={circle.xPos} cy={circle.yPos} r="15" stroke="white" strokeWidth="3" fill="none" />, <image className="c4-image" key={round + "-" + index} x={Number(circle.xPos - 10)} y={Number(circle.yPos - 10)} width="20" height="20" href={bombImage} />]

                                        }

                                        return drawOnMap
                                    })}

                                    {circles.map((circle, index) => {
                                        var drawOnMap = '';

                                        if (circle.primaryWeapon === "AWP") {
                                            drawOnMap = <image key={round + "-" + circle.primaryWeapon + "-" + index} x={circle.xPos - 25} y={Number(circle.yPos) - 35} width="50" height="30" href={awpImage} />
                                        }
                                        if (circle.hasBomb === "true" && hideBomb === false) {
                                            drawOnMap = <image className="c4-image" key={round + "- Bomb -" + index} x={circle.xPos + 5} y={Number(circle.yPos) - 10} width="20" height="20" href={bombImage} />
                                        }

                                        return drawOnMap
                                    })}
                                </svg>
                                {!specificSitutation ?
                                    <div className="flex-vertical">
                                        <div className="weapon-used-container flex">
                                            {weaponsUsed.sort((a, b) => (
                                                a.teamName.localeCompare(b.teamName) || a.name.localeCompare(b.name)
                                            )).map((weapon, index) => {
                                                (weapon.weapon === "Desert Eagle") ? weapon.weapon = "Deagle" : weapon.weapon = weapon.weapon;
                                                (weapon.weapon === "Dual Berettas") ? weapon.weapon = "Dualies" : weapon.weapon = weapon.weapon;

                                                return <div key={index} className="weapon-used flex-one flex-vertical">
                                                    <div>{weapon.name}</div>
                                                    <div>{weapon.weapon}</div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="round-button-container flex">
                                            <div data-matchid={circles[0].matchId} data-round={circles[0].roundNumber} data-teamname={circles[0].playerTeam} data-mapname={circles[0].mapName} className="map-expand-round" onClick={this.openNewTabRound}>View Full Round</div>
                                        </div>
                                    </div>
                                    : ''}

                                {this.props.path === "round" ?
                                    <div className="weapon-used-container flex">
                                        {weaponsUsed.sort((a, b) => (
                                            a.teamName.localeCompare(b.teamName) || a.name.localeCompare(b.name)
                                        )).map((weapon, index) => {
                                            (weapon.weapon === "Desert Eagle") ? weapon.weapon = "Deagle" : weapon.weapon = weapon.weapon;
                                            (weapon.weapon === "Dual Berettas") ? weapon.weapon = "Dualies" : weapon.weapon = weapon.weapon;

                                            return <div key={index} className="weapon-used flex-one flex-vertical">
                                                <div>{weapon.name}</div>
                                                <div>{weapon.weapon}</div>
                                            </div>
                                        })}
                                    </div>
                                    : ''}

                                {this.props.path === "utility" ?
                                    <div className="weapon-used-container flex">
                                        {weaponsUsed.sort((a, b) => (
                                            a.teamName.localeCompare(b.teamName) || a.name.localeCompare(b.name)
                                        )).map((weapon, index) => {
                                            (weapon.weapon === "Desert Eagle") ? weapon.weapon = "Deagle" : weapon.weapon = weapon.weapon;
                                            (weapon.weapon === "Dual Berettas") ? weapon.weapon = "Dualies" : weapon.weapon = weapon.weapon;

                                            return <div key={index} className="weapon-used flex-one flex-vertical">
                                                <div>{weapon.name}</div>
                                                <div>{weapon.weapon}</div>
                                            </div>
                                        })}
                                    </div>
                                    : ''}
                            </div>
                        }
                        return mapHtml
                    }) : playerNames.map((name, index) => {
                        var currentMatchId;
                        var currentRound;
                        var currentTeamName;
                        var mapsPerRow = this.props.sliderValue ? this.props.sliderValue : "3";

                        var mapHtml = <div key={'single' + index} className={"flex-one flex-vertical map-svg-container maps-per-row-" + mapsPerRow}><svg viewBox="0 0 1024 1024"
                            onClick={() => this.expandSvg('single' + index)}
                            className={`map-svg flex-one ${selectedSvg === 'single' + index ? 'map-modal' : ''}`}
                        >
                            <image href={mapImage} alt="map" height="1024" width="1024" />

                            <text className="svg-font-names-single" x={50} y={110} fill="white">{name}</text>

                            {circlesArray.map((circles, index) => {

                                return circles.map((circle, index2) => {
                                    var drawOnMap;

                                    currentMatchId = circle.matchId;
                                    currentRound = circle.roundNumber;
                                    currentTeamName = circle.playerTeam;

                                    if ((circle.positionType === "Player") && (circle.playerName === name)) {
                                        if (circle.isAlive === "true") {
                                            if (circle.eyeYaw != null) {
                                                var yaw = Number(circle.eyeYaw);
                                                if (yaw < 180) {
                                                    yaw = -yaw
                                                }
                                                if (yaw >= 180) {
                                                    yaw = 360 - yaw
                                                }

                                                var arrowHeight = 15;
                                                var arrowWidth = 7.5;
                                                var playerLookDirection = (
                                                    <polygon
                                                        points={`0,0 ${arrowWidth},${arrowWidth} 0,${arrowHeight}`}
                                                        fill="white"
                                                        transform={`translate(${circle.xPos + 10}, ${circle.yPos - arrowHeight / 2}) rotate(${yaw})`}
                                                        style={{ transformOrigin: `${-10}px ${arrowHeight / 2}px` }}
                                                    />
                                                );
                                                drawOnMap = [<circle key={round + "-" + circle.playerName + "-" + index} cx={circle.xPos} cy={circle.yPos} r="11" stroke="white" strokeWidth="0" fill="#12B3DD" fillOpacity="1" />, playerLookDirection]
                                            } else {
                                                drawOnMap = <circle key={round + "-" + index2} cx={circle.xPos} cy={circle.yPos} r="11" stroke="white" strokeWidth="0" fill="#12B3DD" fillOpacity="1" />
                                            }
                                        } else {
                                            drawOnMap = <image key={round + "-" + index2} x={Number(circle.xPos - 10)} y={Number(circle.yPos - 10)} width="20" height="20" href={deathImage} />
                                        }
                                    }

                                    return drawOnMap
                                })
                            })}
                        </svg>
                        </div>

                        if (this.state.buy === "Full Buy" && index === playerNames.length - 1) {
                            var awpMapHtml = <div className={"flex-one flex-vertical map-svg-container maps-per-row-" + mapsPerRow}><svg key={'singleawp' + index} viewBox="0 0 1024 1024"
                                onClick={() => this.expandSvg('singleawp' + index)}
                                className={`map-svg flex-one ${selectedSvg === 'singleawp' + index ? 'map-modal' : ''}`}
                            >
                                <image href={mapImage} alt="map" height="1024" width="1024" />

                                <text className="svg-font-names-single" x={50} y={110} fill="white">AWP</text>

                                {circlesArray.map((circles, index) => {
                                    return circles.map((circle, index2) => {
                                        var drawOnMap;

                                        currentMatchId = circle.matchId;
                                        currentRound = circle.roundNumber;
                                        currentTeamName = circle.playerTeam;

                                        if (circle.primaryWeapon === "AWP") {
                                            if (circle.isAlive === "true") {
                                                drawOnMap = <circle key={round + "-" + index2} cx={circle.xPos} cy={circle.yPos} r="11" stroke="white" strokeWidth="0" fill="#12B3DD" fillOpacity="1" />
                                            } else {
                                                drawOnMap = <image key={round + "-" + index2} x={Number(circle.xPos - 10)} y={Number(circle.yPos - 10)} width="20" height="20" href={deathImage} />
                                            }
                                        }

                                        return drawOnMap
                                    })

                                })}
                            </svg>
                            </div>

                            return [mapHtml, awpMapHtml]
                        }
                        return mapHtml
                    })}
                    {(this.props.path === 'round') ?
                        // { roundSelectionDiv }
                        <div className="next-prev-buttons-container flex">
                            {roundNumbers.map((round, index) => {
                                return <div key={round} onClick={() => this.openNextPrevRound(round)} className={(circlesArray[0][0].roundNumber == round) ? "next-prev-buttons flex selected-prev-next-button" : "next-prev-buttons flex"}>{round}</div>
                            })}
                        </div>
                        : ''}
                </div>
            </React.Fragment>
        );
    }
}