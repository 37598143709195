const adjustCoord = (xyCoord, zCoord, mapName, xCoordTrue) => {
    const mapInfo = {
        "de_ancient": {
            "xPos": -2953,
            "yPos": 2164,
            "scale": 5,
            "name": "de_ancient"
        },
        "de_anubis": {
            "xPos": -2796,
            "yPos": 3328,
            "scale": 5.22,
            "name": "de_anubis"
        },
        "de_cache": {
            "xPos": -2000,
            "yPos": 3250,
            "scale": 5.5,
            "name": "de_cache"
        },
        "de_cbble": {
            "xPos": -3840,
            "yPos": 3072,
            "scale": 6,
            "name": "de_cbble"
        },
        "de_dust2": {
            "xPos": -2476,
            "yPos": 3239,
            "scale": 4.4,
            "name": "de_dust2"
        },
        "de_inferno": {
            "xPos": -2087,
            "yPos": 3870,
            "scale": 4.9,
            "name": "de_inferno"
        },
        "de_mirage": {
            "xPos": -3230,
            "yPos": 1713,
            "scale": 5,
            "name": "de_mirage"
        },
        "de_nuke": {
            "xPos": -3453,
            "yPos": 2887,
            "scale": 7,
            "name": "de_nuke",
            "lowerBeakpoint": -495,
            "upperXAdjust": 0,
            "upperYAdjust": 100,
            "upperScaleAdjust": 1,
            "lowerXAdjust": -350,
            "lowerYAdjust": -200,
            "lowerScaleAdjust": 1,
        },
        "de_dust2": {
            "xPos": -4831,
            "yPos": 1781,
            "scale": 5.2,
            "name": "de_dust2"
        },
        "de_vertigo": {
            "xPos": -3168,
            "yPos": 1762,
            "scale": 4.0,
            "lowerBeakpoint": 11700,
            "name": "de_vertigo",
            "upperXAdjust": 0,
            "upperYAdjust": 0,
            "upperScaleAdjust": .75,
            "lowerXAdjust": 420,
            "lowerYAdjust": 300,
            "lowerScaleAdjust": 0.85
        }
    }

    if (xCoordTrue) {
        xyCoord = Math.round((xyCoord - mapInfo[mapName].xPos) / mapInfo[mapName].scale)
    } else {
        xyCoord = Math.round((mapInfo[mapName].yPos - xyCoord) / mapInfo[mapName].scale)
    }

    if (mapInfo[mapName].lowerBeakpoint !== undefined) {
        if (zCoord > mapInfo[mapName].lowerBeakpoint) {
            if (xCoordTrue) {
                xyCoord = xyCoord * mapInfo[mapName].upperScaleAdjust;
                xyCoord = xyCoord + mapInfo[mapName].upperXAdjust;
            } else {
                xyCoord = xyCoord * mapInfo[mapName].upperScaleAdjust;
                xyCoord = xyCoord + mapInfo[mapName].upperYAdjust;
            }
        } else {
            if (xCoordTrue) {
                xyCoord = xyCoord * mapInfo[mapName].lowerScaleAdjust;
                xyCoord = xyCoord + mapInfo[mapName].lowerXAdjust;
            } else {
                xyCoord = xyCoord * mapInfo[mapName].lowerScaleAdjust;
                xyCoord = xyCoord + mapInfo[mapName].lowerYAdjust;
            }
        }
    }
    return xyCoord;
}

export { adjustCoord };