import React from "react";
import overview from './images/Overview.png';
import main from './images/Main.png';
import utility from './images/utility.png';
import tside from './images/tside.png';
import teams from './images/teams.png';
import playerComparisons from './images/PlayerComparison.png';
import stats from './images/Stats.png';
import match from './images/Match.png';
var logo = require('./images/csgo-scouting-logo-250px.png');

export default function LandingPage() {

    return (
        <React.Fragment>
            <div className="flex-vertical landing-page-container">
                <div className="flex-vertical landing-main-container">
                    <div className="flex landing-nav-container">
                        <a href="/scouting" className="setting-logo-home landing-nav-item">
                            <img src={logo} alt="logo" />
                        </a>
                        <div className="flex landing-nav-item nav-login"><a href="/scouting">Log In</a></div>
                        <div className="flex landing-nav-item"><a href="/scouting">Contact</a></div>
                        <div className="flex landing-nav-item nav-signup"><a href="/scouting">Sign up</a></div>
                    </div>
                    <div className="flex landing-main-content">
                        <div className="flex-vertical landing-main-content-item">
                            <div className="flex landing-main-title">Instant Positional Data</div>
                            <div className="flex landing-main-description">The fastest way to scout professional teams by finding patterns across multiple demos</div>
                            <div className="flex landing-buttons"><a className="landing-demo-button" href="/demo">See Demo</a><a className="landing-signup-button" href="/scouting">Sign Up</a></div>
                        </div>
                    </div>
                </div>
                <div className="landing-main-img">
                    <img src={main} />
                </div>
                <div className="flex landing-top-break">
                    <div className="landing-top-break-text">From the creator of the HLTV Demo Downloader</div>
                </div>
                <div className="flex landing-feature-container">
                    <div className="flex-vertical landing-feature-text">
                        <div className="flex-vertical landing-feature-item">
                            <div className="flex landing-feature-title">Utility Sort</div>
                            <div className="flex landing-feature-description">See a piece of utility and want to know what it means? With one click see position data for every time they use it.</div>
                        </div>
                    </div>
                    <div className="flex landing-feature-image">
                        <div className="flex landing-feature-image-container"><img src={utility} /></div>
                    </div>
                </div>
                <div className="flex flex-reverse landing-feature-container">
                    <div className="flex-vertical landing-feature-text">
                        <div className="flex-vertical landing-feature-item">
                            <div className="flex landing-feature-title">Every Match</div>
                            <div className="flex landing-feature-description">Every publicly available demo from the last 6 months is in the system updating daily.</div>
                        </div>
                    </div>
                    <div className="flex landing-feature-image">
                        <div className="flex landing-feature-image-container"><img src={match} /></div>
                    </div>
                </div>
                <div className="flex flex landing-feature-container">
                    <div className="flex-vertical landing-feature-text">
                        <div className="flex-vertical landing-feature-item">
                            <div className="flex landing-feature-title">Every Team</div>
                            <div className="flex landing-feature-description">Every team with an HLTV rank is in the system.</div>
                        </div>
                    </div>
                    <div className="flex landing-feature-image">
                        <div className="flex landing-feature-image-container"><img src={teams} /></div>
                    </div>
                </div>
                <div className="flex flex-reverse landing-feature-container">
                    <div className="flex-vertical landing-feature-text">
                        <div className="flex-vertical landing-feature-item">
                            <div className="flex landing-feature-title">See player positions across demos</div>
                            <div className="flex landing-feature-description">See where the other team is at any point in every round they have played instantly.</div>
                        </div>
                    </div>
                    <div className="flex landing-feature-image">
                        <div className="flex landing-feature-image-container"><img src={overview} /></div>
                    </div>
                </div>
                <div className="flex flex landing-feature-container">
                    <div className="flex-vertical landing-feature-text">
                        <div className="flex-vertical landing-feature-item">
                            <div className="flex landing-feature-title">Player Comparison</div>
                            <div className="flex landing-feature-description">Compare two players to see similarly how they play the game.</div>
                        </div>
                    </div>
                    <div className="flex landing-feature-image">
                        <div className="flex landing-feature-image-container"><img src={playerComparisons} /></div>
                    </div>
                </div>
                <div className="flex flex-reverse landing-feature-container">
                    <div className="flex-vertical landing-feature-text">
                        <div className="flex-vertical landing-feature-item">
                            <div className="flex landing-feature-title">Advanced statistics dashboard (Work in Progress)</div>
                            <div className="flex landing-feature-description">See the beginning of what will become a fully fledged statistics dashboard with stats you can't get anywhere else.</div>
                        </div>
                    </div>
                    <div className="flex landing-feature-image">
                        <div className="flex landing-feature-image-container"><img src={stats} /></div>
                    </div>
                </div>
                <div className="flex landing-main-content">
                    <div className="flex-vertical landing-main-content-item">
                        <div className="flex landing-main-end-title">See how instant aggregated can change the way you scout teams</div>
                        <div className="flex landing-buttons"><a className="landing-end-button" href="/scouting">Get Started</a></div>
                    </div>
                </div>
                <hr className="landing-end-hr"></hr>
                <div className="flex landing-main-content">
                    <div className="flex landing-footer">
                        <div className="flex-one landing-footer-item"><a href="https://twitter.com/cloud9_win">Twitter</a></div>
                        <div className="flex-one landing-footer-item"><a href="mailto:cloud9win@gmail.com">Contact</a></div>
                        {/* <div className="flex-one landing-footer-item"><a href="/pricing">Pricing</a></div> */}
                        <div className="flex-one landing-footer-item"><a href="/privacy">Privacy Policy</a></div>
                        <div className="flex-one landing-footer-item"><a href="/terms">Terms and Conditions</a></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}