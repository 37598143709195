import logo from './logo.svg';
import React, { useState } from 'react';

import './App.css';
import './index.css';
import { SelectTeam } from "./SelectTeam";
import { SelectPlayer } from "./SelectPlayer";
import { useParams } from 'react-router-dom';
import { ScoutingReport } from './ScoutingReport';
import { callApi } from './CallApi';
import { callApiNoCache } from './callApiNoCache';
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Logout from './Logout';
import { PaymentLandingPage } from './PaymentLandingPage';
import CookieConsent, { Cookies } from "react-cookie-consent";

function Home(props) {
  var paramsArray = useParams();
  var path = (props.path ? props.path : '');
  var renderedComponent;
  var homeClassName = "home";
  var navItem = <a href="/scouting/player/">Player Comparisons</a>
  var showHeader = false;
  const navigate = useNavigate();

  var userInformation = localStorage.getItem('user');
  userInformation = JSON.parse(userInformation);

  var apiUrl = '';

  switch (process.env.NODE_ENV) {
    case 'production':
      apiUrl = process.env.REACT_APP_API_HOST_URL
      break;
    case 'development':
      apiUrl = process.env.REACT_APP_API_DEV_URL
    default:
  }

  const [rerender, setRerender] = useState(false);

  if (!userInformation || userInformation.length === 0) {
    if (localStorage.getItem('userEmail') === null) {
      logoutClicked();
    } else {
      getUserInformation();
    }
  } else {
    setPath();
  }

  async function getUserInformation() {
    var email = localStorage.getItem('userEmail')
    var userInfo = await callApi('users/getUser?email=' + email);
    localStorage.setItem('user', JSON.stringify(userInfo));
    userInformation = userInfo;
    if (userInfo.length !== 0) {
      setRerender(!rerender)
    }
  }

  async function setPath() {
    // if (userInformation[0].paid == true) {
    if (path !== "") {
      switch (path) {
        case "team":
          showHeader = true;
          var teamNameArray = {
            value: paramsArray.teamName,
            label: paramsArray.teamName
          }
          renderedComponent = <SelectTeam path={path} teamName={teamNameArray} />
          break;
        case "player":
          navItem = <a href="/scouting/">Team Comparisons</a>
          homeClassName = "home-player";
          var player1Array = {
            value: paramsArray.player1Name,
            label: paramsArray.player1Name,
            teamName: paramsArray.player1Team
          }

          if (paramsArray.player1Name === "undefined") { //aka if the 2nd dropdown as selected but not the first
            player1Array = {
              value: undefined,
              label: undefined,
              teamName: undefined
            }
          }

          var player2Array = {
            value: paramsArray.player2Name,
            label: paramsArray.player2Name,
            teamName: paramsArray.player2Team
          }

          renderedComponent = <SelectPlayer path={path} player1={player1Array} player2={player2Array} />
          break;
        default:
          renderedComponent = <ScoutingReport path={path} params={paramsArray} />
      }
    } else {
      showHeader = true;
      renderedComponent = <SelectTeam params={paramsArray} />
    }
    // } else {
    //   showHeader = true;
    //   renderedComponent = <PaymentLandingPage />
    //   homeClassName = 'home-payment'
    // }
  }

  async function logoutClicked() {
    await signOut();
    localStorage.removeItem("user");
    localStorage.removeItem("userEmail");
    navigate("/");
  }

  async function handleAccount() {
    var user = JSON.parse(localStorage.getItem('user'));
    var stripeCustomerId = user[0].stripeCustomerId //Here this should be the cookie that is set from when they log in and query back end

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ stripeCustomerId: stripeCustomerId, returnUrl: window.location.href })
    };

    // Send data to the backend via POST
    fetch(apiUrl + 'customer-portal', requestOptions)
      .then(response => response.json())
      .then((response) => {
        window.location.href = response.url;
      })
      .catch((error) => {
        console.error(error);
      })

  }

  return (
    <div className={homeClassName}>
      <header className="scouting-container flex-vertical">
        {/* <div className="scouting-header flex">All data is now from CS2!</div> */}
        {renderedComponent}
      </header>
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
    </div>
  );
}

export default Home;
