import React, { Component } from "react";
import Select from 'react-select';
import { callApi } from './CallApi';
import { PlayerPositionOverview } from "./PlayerPositionOverview";
import { RoundSlider } from './RoundSlider';
import { adjustCoord } from "./AdjustCoord";
import Logout from './Logout';

export class SelectPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teamSelected: null,
            teamPlaceholder: '',
            showPlayers: false,
            players: [],
            playerList: [],
            mapNames: ["de_ancient", "de_anubis", "de_inferno", "de_mirage", "de_nuke", "de_dust2", "de_vertigo"],
            fullBuyPositions: {},
            player1Selected: '',
            player2Selected: '',
            oldPlayer1Selected: '',
            oldPlayer2Selected: '',
            player1Positions: {},
            player2Positions: {},
            allPlayer1Positions: {},
            allPlayer2Positions: {},
            sliderTime: 15,
            maxResults: 3,
            allMapsRetreived: false
        }

    }

    componentDidMount() {
        callApi('player/list')
            .then(results => {
                results.sort(function (a, b) {
                    if (a.Name < b.Name) { return -1; }
                    if (a.Name > b.Name) { return 1; }
                    return 0;
                })
                if (this.props.path) {
                    if (this.props.path === "player") {
                        this.setState({
                            playerList: results,
                            player1Selected: (this.props.player1.value !== undefined ? this.props.player1 : 'Select Player'),
                            player2Selected: (this.props.player2.value !== undefined ? this.props.player2 : 'Select Player'),
                            player1Placeholder: 'Select Player',
                            player2Placeholder: 'Select Player'
                        }, () => {
                            if (this.state.player1Selected.value !== undefined || this.state.player2Selected.value !== undefined) {
                                this.displayPositions()
                            }
                        });
                    }
                } else {
                    this.setState({
                        playerList: results,
                        player1Selected: '',
                        player2Selected: '',
                        player1Placeholder: 'Select Player',
                        player2Placeholder: 'Select Player'
                    });
                }
            })
    }

    componentDidUpdate() {
    }

    updatePlayer() {
        this.setState({
            player1Selected: (this.state.player1Selected.value !== undefined ? this.state.player1Selected : 'Select Player'),
            player2Selected: (this.state.player2Selected.value !== undefined ? this.state.player2Selected : 'Select Player'),
            player1Positions: {},
            player2Positions: {},
            allPlayer1Positions: {},
            allPlayer2Positions: {},
            allOverviewPlayer1Circles: {},
            allOverviewPlayer2Circles: {},
        }, () => {
            this.displayPositions()
        });
    }

    displayPositions() {
        var mapsUpdated = 0;
        for (var i = 0; i < this.state.mapNames.length; i++) {
            var player1ApiURL = 'position/generalInfo?playerName=' + this.state.player1Selected.value + '&teamName=' + this.state.player1Selected.teamName + '&mapName=' + this.state.mapNames[i] + '&buy=' + "Full Buy";
            player1ApiURL += (this.state.maxResults) ? '&maxResults=' + this.state.maxResults : '';
            var player2ApiURL = 'position/generalInfo?playerName=' + this.state.player2Selected.value + '&teamName=' + this.state.player2Selected.teamName + '&mapName=' + this.state.mapNames[i] + '&buy=' + "Full Buy";
            player2ApiURL += (this.state.maxResults) ? '&maxResults=' + this.state.maxResults : '';

            var fetchPlayer1Data = (this.state.player1Selected !== 'Select Player' ? callApi(player1ApiURL) : []);
            var fetchPlayer2Data = (this.state.player2Selected !== 'Select Player' ? callApi(player2ApiURL) : []);

            Promise.all([fetchPlayer1Data, fetchPlayer2Data, this.state.mapNames[i]]).then((values) => {
                var currentMapName = values[2];
                var currentPlayer1Positions = values[0];
                var currentPlayer2Positions = values[1];
                var allPlayer1Positions = this.state.allPlayer1Positions;
                var allPlayer2Positions = this.state.allPlayer2Positions;

                if (!allPlayer1Positions[currentMapName]) {
                    allPlayer1Positions[currentMapName] = {};
                }
                if (!allPlayer2Positions[currentMapName]) {
                    allPlayer2Positions[currentMapName] = {};
                }

                for (var j = 0; j < currentPlayer1Positions.length; j++) {
                    var currentPosition = currentPlayer1Positions[j];
                    currentPosition.roundTime = Math.ceil(currentPosition.roundTime / 5) * 5;
                    var currentSecond = currentPosition.roundTime;
                    if (!allPlayer1Positions[currentMapName][currentSecond]) {
                        allPlayer1Positions[currentMapName][currentSecond] = []
                    }
                    currentPosition.xPos_old = currentPosition.xPos;
                    currentPosition.yPos_old = currentPosition.yPos;
                    currentPosition.xPos = adjustCoord(currentPosition.xPos, currentPosition.zPos, currentMapName, true)
                    currentPosition.yPos = adjustCoord(currentPosition.yPos, currentPosition.zPos, currentMapName, false)
                    allPlayer1Positions[currentMapName][currentSecond].push(currentPosition);
                }

                for (var j = 0; j < currentPlayer2Positions.length; j++) {
                    var currentPosition = currentPlayer2Positions[j];
                    currentPosition.roundTime = Math.ceil(currentPosition.roundTime / 5) * 5;
                    var currentSecond = currentPosition.roundTime;
                    if (!allPlayer2Positions[currentMapName][currentSecond]) {
                        allPlayer2Positions[currentMapName][currentSecond] = []
                    }
                    currentPosition.xPos_old = currentPosition.xPos;
                    currentPosition.yPos_old = currentPosition.yPos;
                    currentPosition.xPos = adjustCoord(currentPosition.xPos, currentPosition.zPos, currentMapName, true)
                    currentPosition.yPos = adjustCoord(currentPosition.yPos, currentPosition.zPos, currentMapName, false)
                    allPlayer2Positions[currentMapName][currentSecond].push(currentPosition);
                }


                this.setState({
                    player1Positions: allPlayer1Positions,
                    player2Positions: allPlayer2Positions,
                }, () => {
                    mapsUpdated++;
                    if (mapsUpdated === this.state.mapNames.length) {
                        this.createOverviewCirclesArray();
                    }
                });


            })
        }
    }

    updateRoundSvg() {
        var player1Object = (typeof this.state.player1Selected === 'object') ? [{ name: this.state.player1Selected.value }] : [];
        var player2Object = (typeof this.state.player2Selected === 'object') ? [{ name: this.state.player2Selected.value }] : [];

        var player1MapHTML = <PlayerPositionOverview positions={this.state.player1Positions} mapNames={this.state.mapNames} wholeTeamMaps={false} allCirclesObject={this.state.allOverviewPlayer1Circles} sliderTime={this.state.sliderTime} playerNames={player1Object} oldPlayerNames={[]} />
        var player2MapHTML = <PlayerPositionOverview positions={this.state.player2Positions} mapNames={this.state.mapNames} wholeTeamMaps={false} allCirclesObject={this.state.allOverviewPlayer2Circles} sliderTime={this.state.sliderTime} playerNames={player2Object} oldPlayerNames={[]} />

        this.setState({
            player1MapHTML: player1MapHTML,
            player2MapHTML: player2MapHTML,
            allMapsRetreived: true
        });
    }

    createOverviewCirclesArray() {
        var positionsPlayer1 = this.state.allPlayer1Positions;
        var positionsPlayer2 = this.state.allPlayer2Positions;
        var allOverviewPlayer1Circles = {};
        var allOverviewPlayer2Circles = {};

        for (var i = 0; i < Object.keys(positionsPlayer1).length; i++) {
            var currentMapName = Object.keys(positionsPlayer1)[i];
            for (var k = 0; k < Object.keys(positionsPlayer1[currentMapName]).length; k++) {
                var currentPosition = [];
                var circlesObject = {};

                var currentSecond = Object.keys(positionsPlayer1[currentMapName])[k];
                if (!allOverviewPlayer1Circles[currentSecond]) {
                    allOverviewPlayer1Circles[currentSecond] = {};
                }
                if (!allOverviewPlayer1Circles[currentSecond][currentMapName]) {
                    this.state.mapNames.map((mapName) => {
                        allOverviewPlayer1Circles[currentSecond][mapName] = {};
                    })

                }

                if (Object.values(positionsPlayer1)[i] && Object.values(positionsPlayer1)[i][currentSecond]) {
                    currentPosition = Object.values(positionsPlayer1)[i][currentSecond];
                } else {
                    currentPosition = []
                }

                for (var j = 0; j < currentPosition.length; j++) {
                    if (currentPosition[j].side === "TERRORIST") {
                        currentPosition[j].side = 'T';
                    }
                    if (!circlesObject[currentPosition[j].playerName]) {
                        circlesObject[currentPosition[j].playerName] = [];
                    }
                    circlesObject[currentPosition[j].playerName].push(currentPosition[j])
                }
                allOverviewPlayer1Circles[currentSecond][currentMapName] = JSON.parse(JSON.stringify(circlesObject));
            }
        }

        for (var i = 0; i < Object.keys(positionsPlayer2).length; i++) {
            var currentMapName = Object.keys(positionsPlayer2)[i];
            for (var k = 0; k < Object.keys(positionsPlayer2[currentMapName]).length; k++) {
                var currentPosition = [];
                var circlesObject = {};

                var currentSecond = Object.keys(positionsPlayer2[currentMapName])[k];
                if (!allOverviewPlayer2Circles[currentSecond]) {
                    allOverviewPlayer2Circles[currentSecond] = {};
                }
                if (!allOverviewPlayer2Circles[currentSecond][currentMapName]) {
                    this.state.mapNames.map((mapName) => {
                        allOverviewPlayer2Circles[currentSecond][mapName] = {};
                    })

                }

                if (Object.values(positionsPlayer2)[i] && Object.values(positionsPlayer2)[i][currentSecond]) {
                    currentPosition = Object.values(positionsPlayer2)[i][currentSecond];
                } else {
                    currentPosition = []
                }

                for (var j = 0; j < currentPosition.length; j++) {
                    if (currentPosition[j].side === "TERRORIST") {
                        currentPosition[j].side = 'T';
                    }
                    if (!circlesObject[currentPosition[j].playerName]) {
                        circlesObject[currentPosition[j].playerName] = [];
                    }
                    circlesObject[currentPosition[j].playerName].push(currentPosition[j])
                }
                allOverviewPlayer2Circles[currentSecond][currentMapName] = JSON.parse(JSON.stringify(circlesObject));
            }
        }
        this.setState({
            allOverviewPlayer1Circles: allOverviewPlayer1Circles,
            allOverviewPlayer2Circles: allOverviewPlayer2Circles
        }, () => {
            this.updateRoundSvg();
        });
    }


    handlePlayer1Change = (player1Selected) => {
        if (this.state.oldPlayer1Selected !== player1Selected) {
            this.setState({ player1Selected, oldPlayer1Selected: player1Selected, allMapsRetreived: false }, () => {
                var nextURL;
                switch (process.env.NODE_ENV) {
                    case 'production':
                        if (this.state.player2Selected.value !== undefined) {
                            nextURL = process.env.REACT_APP_HOST_URL + 'scouting/player/' + player1Selected.teamName + '/' + player1Selected.value + '/' + this.state.player2Selected.teamName + '/' + this.state.player2Selected.value;
                        } else {
                            nextURL = process.env.REACT_APP_HOST_URL + 'scouting/player/' + player1Selected.teamName + '/' + player1Selected.value;
                        }
                        break;
                    case 'development':
                        if (this.state.player2Selected.value !== undefined) {
                            nextURL = process.env.REACT_APP_DEV_URL + 'scouting/player/' + player1Selected.teamName + '/' + player1Selected.value + '/' + this.state.player2Selected.teamName + '/' + this.state.player2Selected.value;
                        } else {
                            nextURL = process.env.REACT_APP_DEV_URL + 'scouting/player/' + player1Selected.teamName + '/' + player1Selected.value;
                        }
                    default:
                }
                window.history.pushState(null, null, nextURL);
                this.updatePlayer();
            })
        }
    }

    handlePlayer2Change = (player2Selected) => {
        if (this.state.oldPlayer2Selected !== player2Selected) {
            this.setState({ player2Selected, oldPlayer2Selected: player2Selected, allMapsRetreived: false }, () => {
                var nextURL;
                switch (process.env.NODE_ENV) {
                    case 'production':
                        nextURL = process.env.REACT_APP_HOST_URL + 'scouting/player/' + this.state.player1Selected.teamName + '/' + this.state.player1Selected.value + '/' + player2Selected.teamName + '/' + player2Selected.value;
                        break;
                    case 'development':
                        nextURL = process.env.REACT_APP_DEV_URL + 'scouting/player/' + this.state.player1Selected.teamName + '/' + this.state.player1Selected.value + '/' + player2Selected.teamName + '/' + player2Selected.value;
                    default:
                }
                window.history.pushState(null, null, nextURL);
                this.updatePlayer();
            })
        }
    }

    handleFinalRoundTimeSlider(event) {
        if (event.target.value !== this.state.sliderTime) {
            this.setState({
                sliderTime: event.target.value,
            }, () => {
                this.updateRoundSvg();
            });
        }
    }

    render() {

        const {
            player1Selected,
            player2Selected,
            playerList,
            sliderTime,
            allMapsRetreived,
            player1MapHTML,
            player2MapHTML
        } = this.state;

        var playerSelect = [];
        var logo = require('./images/csgo-scouting-logo-250px.png');

        if (playerList.length > 0) {
            for (var i = 0; i < playerList.length; i++) {
                playerSelect.push({ value: playerList[i].Name, label: playerList[i].Name + " (" + playerList[i].teamName + ")", teamName: playerList[i].teamName })
            }
        }

        return (
            <React.Fragment>
                <div className="flex-vertical select-player-container">
                    <div className="flex select-player-header">
                        <a href="/scouting" className="select-player-logo">
                            <img src={logo} alt="logo" />
                        </a>
                        <div className="player-nav-item">
                            <a href="/scouting/">Team Comparisons</a>
                        </div>
                        <div className="flex">
                            <Logout />
                        </div>
                    </div>
                    <RoundSlider sliderValue={sliderTime} finalSliderValue={this.handleFinalRoundTimeSlider.bind(this)} onChangeSliderValue={this.handleFinalRoundTimeSlider.bind(this)} sliderName="playerOverviewSlider" />

                    <div className="flex player-comps">
                        <div className="flex-vertical player-comp-container">
                            <Select
                                value={player1Selected}
                                onChange={this.handlePlayer1Change}
                                options={playerSelect}
                                className='react-select-player-container'
                                classNamePrefix='react-select-player'
                                placeholder="Select Player"
                            />
                            {player1MapHTML}

                        </div>
                        <div className="flex-vertical player-comp-container">
                            <Select
                                value={player2Selected}
                                onChange={this.handlePlayer2Change}
                                options={playerSelect}
                                className='react-select-player-container'
                                classNamePrefix='react-select-player'
                                placeholder="Select Player"
                            />
                            {player2MapHTML}

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}